<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start">
        <NavbarCertificados :seccion="$t('message.pages')"/>
        <div class="col d-flex flex-column justify-content-center align-items-center align-items-lg-start px-0 classSpecial">
            <Pages v-if="page_id == 1"/>
            <NewPage v-if="page_id == 2"/>
            <EditPage v-if="page_id == 3"/>
            <Faqs v-if="page_id == 4"/>
            <NewFaq v-if="page_id == 5"/>
            <EditFaq v-if="page_id == 6"/>
        </div>
        <FooterCertificados :activeCertificados="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../../components/Footer/FooterCertificados.vue";
import Pages from "./Pages.vue"
import NewPage from "./NewPage.vue"
import EditPage from "./EditPage.vue"
import Faqs from "./FAQ/Faqs.vue"
import NewFaq from "./FAQ/NewFaq.vue"
import EditFaq from "./FAQ/EditFaq.vue"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../../stores/user"
import { onBeforeMount, ref, provide, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Swal from 'sweetalert2'
import axios from 'axios';

const route = useRoute()
const router = useRouter()

const users = useUsersStore();
const { access, headers } = storeToRefs(users);
const { validate, url } = users;

const algo = onBeforeMount( () => {
    access.value = [1]
    //console.log(access.value) 
    // validate();
})

const url_prod_classes =  '/api/admin/products/classes'

const url_type =  '/api/admin/products/classes'
const url_profession =  '/api/public/professions'
const url_specialization =  '/api/public/specializations'
const url_country =  '/api/public/countries'
const url_state =  '/api/public/states'
const url_city =  '/api/public/cities'
const url_professional =  '/api/public/professionals'

const urlFaqs = '/api/admin/pages'

const page_id = ref(1)
provide('page_id', page_id)

const user_id = ref(0)
provide('user_id', user_id)

const pages = ref({ data: '' })
provide('pages', pages)

const new_page = ref({
    title: '',
    description: '',
    description_title: '',
    language: 'ES',
    type: '',
    class: '',
    params: [{type: '', value: ''}]
})
provide('new_page', new_page)

const edit_page = ref({})
provide('edit_page', edit_page)

const resetPages = () => {
    new_page.value = {
        title: '',
        description_title: '',
        description: '',
        language: 'ES',
        type: '',
        class: '',
        params: [{type: '', value: ''}]
    }

    edit_page.value = {}

    page_id.value = 1
}
provide('resetPages', resetPages)

const new_faq = ref({
    question: '',
    response: '',
    language: 'ES'
})
provide('new_faq', new_faq)

const edit_faq = ref({})
provide('edit_faq', edit_faq)

const resetFaqs = () => {
    new_faq.value = {
        question: '',
        response: '',
        language: 'ES'
    }

    edit_faq.value = {}

    page_id.value = 4
}
provide('resetFaqs', resetFaqs)

const prod_classes = ref()
const types = ref()
const professions = ref()
const specializations = ref()
const countries = ref()
const states = ref()
const cities = ref()
const professionals = ref()

const selected_keywords = ref([])
provide('selected_keywords', selected_keywords)

provide('prod_classes', prod_classes)
provide('types', types)
provide('professions', professions)
provide('specializations', specializations)
provide('countries', countries)
provide('states', states)
provide('cities', cities)
provide('professionals', professionals)

const spec_error = ref(false)
const state_error = ref(false)
const city_error = ref(false)
provide('spec_error', spec_error)
provide('state_error', state_error)
provide('city_error', city_error)

const fetchClasses = async () => {
    try {
        const response = await axios.get(`${url}${url_type}`, { headers: headers.value })
        prod_classes.value = response.data.data.map(item => {
            return {
                label: item.translation.name,
                value: item.name,
                slug: item.translation.slug,
                id: item.id
            }
        })
        console.log(prod_classes.value)
    } catch (error) {
        console.log(error)
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}

const fetchTypes = async (class_id) => {
    try {
        const response = await axios.get(`${url}${url_type}/${class_id}/types`, { headers: headers.value })
        types.value = response.data.data.map(item => {
            return {
                label: item.translation.name,
                value: item.translation.slug,
                id: item.id
            }
        })
    } catch (error) {
        console.log(error)
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}
provide('fetchTypes', fetchTypes)

const fetchProfessions = async () => {
    try {
        const response = await axios.get(`${url}${url_profession}`, { headers: headers.value })
        professions.value = response.data.data.map(item => {
            return {
                label: item.translation.name,
                value: item.translation.slug,
                id: item.id
            }
        })
        console.log(professions.value)
    } catch (error) {
        console.log(error)
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}
provide('fetchProfessions', fetchProfessions)

const fetchSpecializations = async (profession_ids) => {
    if (!profession_ids || !profession_ids.length) {
        spec_error.value = true
        return
    }

    try {
        const response = await axios.get(`${url}${url_specialization}`, {
            headers: headers.value,
            params: {
                professionId: profession_ids
            }
        })

        // Obtener las especializaciones del response
        const allItems = response.data.data

        specializations.value = profession_ids.map(id => {
        const profession = professions.value.find(p => p.id === id)

        const options = allItems
            .filter(item => item.profession?.id === id) // 👈 importante: ¿este campo viene en la respuesta?
            .map(item => ({
                label: item.translation.name,
                value: item.translation.slug,
                id: item.id
            }))

        return {
            label: profession?.label || 'Profesión',
            options: options
        }
        })
    } catch (error) {
        console.log(error)
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response?.data?.message || 'Error al cargar especializaciones',
            showConfirmButton: false,
            timer: 3000
        });
    }
}
provide('fetchSpecializations', fetchSpecializations)

const fetchCountries = async () => {
    try {
        const response = await axios.get(`${url}${url_country}`, { headers: headers.value })
        countries.value = response.data.data.map(item => {
            return {
                label: item.translation.name,
                value: item.translation.slug,
                id: item.id
            }
        })
    } catch (error) {
        console.log(error)
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}
provide('fetchCountries', fetchCountries)

const fetchStates = async (country_ids) => {
    if (!country_ids || !country_ids.length) {
        state_error.value = true
        return
    }

    try {
        const response = await axios.get(`${url}${url_state}`, {
            headers: headers.value,
            params: {
                countryId: country_ids
            }
        })

        const allItems = response.data.data

        states.value = country_ids.map(id => {
        const country = countries.value.find(c => c.id === id)

        const options = allItems
            .filter(item => item.country?.id === id) // ✅ corregido aquí
            .map(item => ({
                label: item.translation.name,
                value: item.translation.slug,
                id: item.id
            }))

        return {
            label: country?.label || 'País',
            options
        }
        })
    } catch (error) {
        console.log(error)
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response?.data?.message || 'Error al cargar estados',
            showConfirmButton: false,
            timer: 3000
        });
    }
}
provide('fetchStates', fetchStates)

const fetchCities = async (state_ids) => {
    if (!state_ids || !state_ids.length) {
        city_error.value = true
        return
    }

    try {
        const response = await axios.get(`${url}${url_city}`, {
            headers: headers.value,
            params: {
                stateId: state_ids
            }
        })

        const allItems = response.data.data

        // Aplanamos todos los estados
        const flatStates = states.value.flatMap(group => group.options)

        cities.value = state_ids.map(id => {
            const state = flatStates.find(s => s.id === id)

            const options = allItems
                .filter(item => item.state?.id === id)
                .map(item => ({
                label: item.translation.name,
                value: item.translation.slug,
                id: item.id
                }))

            return {
                label: state?.label || 'Estado',
                options
            }
        })
    } catch (error) {
        console.log(error)
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response?.data?.message || 'Error al cargar ciudades',
            showConfirmButton: false,
            timer: 3000
        });
    }
}
provide('fetchCities', fetchCities)

const fetchProfessionals = async (professional_name) => {
    try {
        const response = await axios.get(`${url}${url_professional}`, { headers: {...headers.value, 'paginate': 'false'} })
        console.log(response)
        professionals.value = response.data.data.total ? response.data.data.data.map(item => {
            return {
                label: item.name,
                value: item.slug,
                id: item.id
            }
        }) : response.data.data.map(item => {
            return {
                label: item.name,
                value: item.slug,
                id: item.id
            }
        })
        console.log(professionals.value)
    } catch (error) {
        console.log(error)
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}
provide('fetchProfessionals', fetchProfessionals)

const faqs = ref()
provide('faqs', faqs)

const fetchFaqs = async() => {
    try {
        const response = await axios.get(url+urlFaqs+"/"+edit_page.value.id+"/faqs", {headers: headers.value})
        console.log(response)
        faqs.value = response.data.data
    } catch (error) {
        console.log(error)
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}
provide('fetchFaqs', fetchFaqs)

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
    useHeadTags({ slug: route.fullPath.slice(1) })
    fetchClasses()
})

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 92vh;

}
.contenedor{
    @media (min-width: 992px) {
        max-width: 95vw;
        margin-top: 100px;
    }
}
.classSpecial{
    @media (max-width: 991px) {
        padding-bottom: 30vw;
    }
    @media (min-width: 992px) {
        width: 70vw !important;
    }
}


</style>