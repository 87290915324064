<template>
    <div class="container-fluid">
        <NavbarBuscador/>
        <HeaderTitulo class="d-none d-lg-flex" :tituloHeader="'Home / Cursos'"/>
        <div class="row justify-content-center position-relative">
            <div class="col-11 d-flex flex-column justify-content-center align-items-center px-0 px-lg-auto mt-lg-3 position-relative">
                <TitulosSecciones :titulo="information_filter ? information_filter.title : $t('message.coursesPhotography')" :haySubtitulo="false" class="py-0 px-0" style="white-space: nowrap;"/>
                <Filtros class="my-0" page="curses"/>
                <div class="row justify-content-center align-items-center my-lg-4 row__width" :class="search_results_list.length == 0 && !is_searching ? '' : 'table-space'">
                    <CursosFotografiaSnippet v-for="(product, id) in search_results_list"
                        :product="product" :key="product"
                        v-if="search_results_list.length >= 1"
                    />
                    
                    
                    <div class="col d-flex flex-column justify-content-start align-items-center" v-for="n in 12" :key="n" v-if="is_searching">
                        <div class="skeleton-card slide">
                            <div class="skeleton-header">
                                <div class="skeleton-image"></div>
                            </div>
                            <div class="skeleton-body">
                                <div class="skeleton-title skeleton-loading"></div>
                                <div class="skeleton-subtitle skeleton-loading"></div>
                                <div class="skeleton-meta">
                                    <div class="skeleton-icon skeleton-loading"></div>
                                </div>
                                <div class="skeleton-footer" style="margin-bottom: 16px;">
                                    <div class="skeleton-price skeleton-loading"></div>
                                    <div class="skeleton-price skeleton-loading"></div>
                                    <div class="skeleton-price skeleton-loading"></div>
                                    <div class="skeleton-price skeleton-loading"></div>
                                </div>
                                <div class="skeleton-footer">
                                    <div class="skeleton-price skeleton-loading"></div>
                                    <div class="skeleton-button skeleton-loading"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center" v-else-if="search_results_list.length == 0 && !is_searching">
                        <div class="col-11 col-lg-10 d-flex flex-column justify-content-center align-items-center px-0 ">
                            <MasterclassTitulo class="d-none d-lg-flex my-5" :ocultar="true"/>
                            <TituloSinResultados class="mb-5 pb-5" :titulo="$t('message.withoutResults')" :subtitulo="$t('message.sorryWithoutResults')" :desaparecerSubtitulo="true"/>
                        </div>
                    </div>
                </div>
                <!-- Paginación -->
                <template v-if="search_results_list.length >= 1">
                    <nav v-if="products_list" class="py-3">
                        <ul class="pagination mb-0">
                        <!-- Previous link -->
                            <li :class="{'disabled': products_list.offset <= 0}" class="page-item buttons">
                                <a class="page-link" href="#" @click.prevent="getSearchResults(products_list.offset - products_list.limit)" :disabled="products_list.offset <= 0">Anterior</a>
                            </li>

                        <!-- Display page links -->
                            <li v-for="page in Math.ceil(products_list.estimatedTotalHits / products_list.limit)" :key="page" :class="{'active': (products_list.offset / products_list.limit) + 1 == page}" class="page-item">
                                <a class="page-link" href="#" @click.prevent="getSearchResults((page - 1) * products_list.limit)">{{ page }}</a>
                            </li>

                        <!-- Next link -->
                            <li :class="{'disabled': products_list.offset + products_list.limit >= products_list.estimatedTotalHits}" class="page-item buttons">
                                <a class="page-link" href="#" @click.prevent="getSearchResults(products_list.offset + products_list.limit)" :disabled="products_list.offset + products_list.limit >= products_list.estimatedTotalHits">Siguiente</a>
                            </li>
                        </ul>
                    </nav>
                </template>
                <DescripcionPaginaFiltros v-if="information_filter"/>
            </div>
            <!-- <div class="col-4 d-none d-lg-flex justify-content-center align-items-start ps-0 d-none"></div>
            <div class="col d-none d-lg-flex justify-content-center align-items-start ps-0 mt-lg-5 position d-none">
                <MapaFiltros/>
            </div>
            <div class="filtros-absolute d-none" @click="cerrarTodo">
                
            </div>
            <div class="filtros row d-flex justify-content-center align-items-start d-none">
                <div class="col-11 d-flex justify-content-center align-items-center px-0">
                    <FiltrosV2 :funcionMostrarFiltros="funcionMostrarFiltros" :funcionMapa="funcionMapa2"/>
                </div>
                <div class="col-3 d-flex justify-content-center align-items-start ps-0">
                    <BodyFiltros/>
                </div>
                <div class="col-8 d-flex flex-column justify-content-center align-items-center px-0 position-relative">
                    <TituloYOpcionesResultadosCursos :titulo="$t('message.coursesPhotography')" idNextV2="nextSliderCursosboton" idBackV2="prevSliderCursosboton"/>
                    <SliderMobileSeccionesHomeV3 class=""/>
                    <TituloYOpcionesResultadosCursos class="mt-lg-4" :titulo="$t('message.coursesDiscount')" :lista="2" :categoria="2" idNextV2="nextSliderCursosV2boton" idBackV2="prevSliderCursosV2boton"/>
                    <SliderMobileSeccionesResultadosCursosV3 class=""/>
                </div>
            </div> -->
        </div>
        <div class="espacio2"></div>
        <FooterHome />
        <Footer :activeHome="true" class="d-lg-none"/>
        <div class="espacio2"></div>
        <Loader :loading="loader"/>
    </div>
    <Colaboradores v-for="(product, id) in search_results_list" :rCollabs="product.colaborators" :id="product.id"/>
    <Patrocinadores v-for="(product, id) in search_results_list" :rSponsors="product.sponsors" :id="product.id"/>
</template>

<script setup>
import Filtros from "../../components/Filtros/Filtros.vue";
import FiltrosV2 from "../../components/Filtros/FiltrosV2.vue";
import Footer from "../../components/Footer/Footer.vue";
import TitulosSecciones from "../../components/Titulo/TitulosSecciones.vue";
import FooterHome from "../../components/Footer/FooterHome.vue";
import NavbarBuscador from "../../components/Navs/NavbarBuscador.vue";
import BotonesSlidersHome from "../../components/Botones/SlidersHome.vue";
import TituloYOpcionesResultadosCursos from "../../components/Titulo/TituloYOpciones.vue";
import HeaderTitulo from "../../components/Titulo/HeaderTitulo.vue";
import BodyFiltros from "../../components/Filtros/Body.vue";
import MapaFiltros from "../../components/Filtros/Mapa.vue";
import SliderMobileSeccionesHomeV3 from "../../components/Sliders/SeccionesHomeV3.vue";
import CursosFotografiaSnippet from "../../components/Sliders/CursosFotografia.vue";
import SinResultados from "../Otros/SinResultados.vue";
import TituloSinResultados from "../../components/Titulo/TituloSinResultados.vue";
import MasterclassTitulo from '../../components/Titulo/MasterclassTitulo.vue';
import Colaboradores from "../../components/Modales/Colaboradores.vue";
import { useUsersStore } from "../../stores/user"
import { storeToRefs } from "pinia";
import axios from "axios";
import { onMounted, provide, ref, watch } from "vue";
import { useRoute, useRouter } from 'vue-router';
import Patrocinadores from "@/components/Modales/Patrocinadores.vue";
import PreguntasFrecuentesFiltros from "@/components/Cursos/PreguntasFrecuentesFiltros.vue";
import DescripcionPaginaFiltros from "@/components/Filtros/DescripcionPaginaFiltros.vue";
import TitulosSeccionesPaginaFiltros from "@/components/Titulo/TitulosSeccionesPaginaFiltros.vue";
import Loader from "@/components/Filtros/Loader.vue";

const route = useRoute();
const router = useRouter();

const lang = ref('ES')
const coin = ref('MXN')

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users

const meili_url = process.env.VUE_APP_MEILISEARCH_URL
const meili_key = process.env.VUE_APP_MEILISEARCH_MASTER_KEY
const urlApi = 'multi-search'

const products_list = ref()
const information_filter = ref('')
const loader = ref(true)

provide('information_filter', information_filter)

// Buscador
console.log(route.query)
const search = ref({
    queries: [{
        indexUid: 'product_products',
        q: '',
        filter: (() => {
            const baseFilter = `(class.translations.${lang.value}.name = "Curso")`;

            const queryMap = {
                type: `type.translations.${lang.value}.slug`,
                country: `country.translations.${lang.value}.slug`,
                state: `state.translations.${lang.value}.slug`,
                city: `city.translations.${lang.value}.slug`,
                profession: `professions.translations.${lang.value}.slug`,
                specialization: `specializations.translations.${lang.value}.slug`,
                professional: `owner.slug`
            };

            const parseQueryValues = (value) => {
                if (!value) return [];
                return decodeURIComponent(value).split(',');
            };

            const dynamicFilters = Object.entries(queryMap).map(([queryKey, filterPath]) => {
                const rawValue = route.query[queryKey];
                if (rawValue) {
                const slugs = parseQueryValues(rawValue);
                const group = slugs.map(slug => `${filterPath} = ${slug}`).join(' OR ');
                return `(${group})`;
                }
                return null;
            }).filter(Boolean);

            if (dynamicFilters.length > 0) {
                return `${baseFilter} AND ${dynamicFilters.join(' AND ')}`;
            } else {
                return baseFilter;
            }
        })(),
        limit: 12,
        offset: 0,
        facets: ['*']
    }]
});
console.log(search.value.queries[0].filter)

const order = [
                `type.translations.${lang.value}.name`,
                `professions.translations.${lang.value}.name`,
                `specializations.translations.${lang.value}.name`,
                `owner.name`,
            ]

const search_type = ref('courses')
const search_query = ref('')
const search_facets = ref({})
const search_ponents = ref({})
const search_prices = ref({})
const prices_range = ref({})
const selected_range = ref({})
const filter_prices = ref(true)
const search_box = ref(false)
const search_results_list = ref([])
const is_searching = ref(false)
const active_items = ref({})
const visible_items = ref({})
const facets_list = ref({})

let first_load = true

provide('search_type', search_type)
provide('search_query', search_query)
provide('search_facets', search_facets)
provide('search_ponents', search_ponents)
provide('search_prices', search_prices)
provide('prices_range', prices_range)
provide('selected_range', selected_range)
provide('filter_prices', filter_prices)
provide('search_box', search_box)
provide('search_results_list', search_results_list)
provide('is_searching', is_searching)
provide('active_items', active_items)
provide('visible_items', visible_items)

let insertedNodes = []
let product_type = ref({
    Curso: 'COURSE',
    Descargable: 'DOWNLOADABLE',
    Paquetes: 'PACK'
})

const useHeadTags = async (params = null) => {
    const url = process.env.VUE_APP_BASE_URL
    let settings = {
        slug: params,
        class: 'COURSE'
    }

    try {
        let response = await axios.post(`${url}/api/public/seo/filters`, { slug: settings.slug, class: settings.class });
        console.log(response)
        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
            const nodeName = node.nodeName.toLowerCase()

            switch (nodeName) {
                case 'title':
                document.title = node.textContent ?? ''
                break

                case 'meta': {
                const metaName = node.getAttribute('name')
                if (metaName) {
                    const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                    if (existingMeta) {
                    document.head.removeChild(existingMeta)
                    }
                }
                const meta = document.createElement('meta')
                Array.from(node.attributes).forEach(attr => {
                    meta.setAttribute(attr.name, attr.value)
                })
                document.head.appendChild(meta)
                insertedNodes.push(meta)
                break
                }

                case 'script': {
                const script = document.createElement('script')
                Array.from(node.attributes).forEach(attr => {
                    script.setAttribute(attr.name, attr.value)
                })
                script.textContent = node.textContent
                document.head.appendChild(script)
                insertedNodes.push(script)
                break
                }

                case 'link': {
                const link = document.createElement('link')
                Array.from(node.attributes).forEach(attr => {
                    link.setAttribute(attr.name, attr.value)
                })
                document.head.appendChild(link)
                insertedNodes.push(link)
                break
                }

                default:
                console.warn(`Etiqueta <${nodeName}> no manejada.`)
            }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}
provide('useHeadTags', useHeadTags)

const showSearchBox = () => {
    search_box.value = true
}

const hideSearchBox = () => {
    search_box.value = false
}

const keepVisible = (event) => {
    event.preventDefault()
}

const parseQueryValues = (value) => {
  if (!value) return [];
  return decodeURIComponent(value).split(',');
};

const formatPrices = async () => {
    const prices = Object.keys(search_prices.value['prices.MXN.finalPrice']).sort((a,b) => Number(a) - Number(b))
    console.log(prices)
    const lowest = prices[0]
    const highest = prices[prices.length - 1]
    prices_range.value = {
        lowest,
        highest
    }
    selected_range.value = {
        min: prices_range.value?.lowest || 0,
        max: prices_range.value?.highest || 0,
    }
    filter_prices.value = false
}
provide('formatPrices', formatPrices)

const setPriceOnFilter = () => {
    const { min, max } = selected_range.value
    let filters = []
    filters.push(`class.translations.${lang.value}.name = "Curso"`)
    if (Object.keys(selected_range.value).length > 0) {
        filters.push(`prices.MXN.finalPrice >= ${min}`)
        filters.push(`prices.MXN.finalPrice <= ${max}`)
    }
    for (const [key, value] of Object.entries(active_items.value)) {
        if (value.length > 0) {
            filters.push(`(${value.map(x => `${key} = "${x}"`).join(' OR ')})`)
        }
    }
    search.value.queries[0].filter = filters.join(' AND ')
    console.log(search.value.queries[0].filter)
    getSearchResults(0)
}
provide('setPriceOnFilter', setPriceOnFilter)

const getSearchFacets =  async (offset) => {
    const facets = {
        queries:[{
            indexUid: 'product_products',
            q: '',
            filter: `(class.translations.${lang.value}.name = "Curso")`,
            limit: 0,
            offset: 0,
            facets: ['*']
        }]
    }
    try {
        await axios.post(meili_url+urlApi, facets, {headers: {'Authorization': `Bearer ${meili_key}`}}).then((result) => {
            products_list.value = result.data.results.find(x => x.indexUid == 'product_products')
            search_facets.value = products_list.value.facetDistribution
            facets_list.value = products_list.value.facetDistribution
            search_prices.value = search_facets.value['prices.MXN.finalPrice'] ? { 'prices.MXN.finalPrice': search_facets.value['prices.MXN.finalPrice'] } : {}
            search_ponents.value = search_facets.value['owner.name'] ? { 'owner.name': search_facets.value['owner.name'] } : {}
            search_facets.value = Object.keys(search_facets.value)
                .sort((a, b) => order.indexOf(a) - order.indexOf(b))
                .reduce((obj, key) => {
                    if (key !== 'prices.MXN.finalPrice' && key !== 'owner.name') {
                        obj[key] = search_facets.value[key];
                    }
                    return obj;
                }, {})
            Object.keys(search_facets.value).forEach(category => {
                if (!visible_items.value[category]) {
                    visible_items.value[category] = 5;
                }
            })
            Object.keys(search_ponents.value).forEach(category => {
                if (!visible_items.value[category]) {
                    visible_items.value[category] = 5;
                }
            })
            Object.keys(search_prices.value).forEach(category => {
                if (!visible_items.value[category]) {
                    visible_items.value[category] = 5;
                }
            })

            const queryMap = {
                'profession': 'professions.translations.ES.name',
                'specialization': 'specializations.translations.ES.name',
                'type': 'type.translations.ES.name',
                'country': 'country.translations.ES.name',
                'state': 'state.translations.ES.name',
                'city': 'city.translations.ES.name',
                'professional': 'owner.name'
            };

            const parseQueryValues = (value) => {
                if (!value) return [];
                return decodeURIComponent(value).split(',');
            };

            const newActiveItems = {};

            for (const [queryKey, categoryKey] of Object.entries(queryMap)) {
                const raw = route.query[queryKey];
                if (raw) {
                    const slugs = parseQueryValues(raw);
                    const names = slugs.map(slug => calculateSlug(slug)).filter(Boolean);
                    if (names.length) {
                    newActiveItems[categoryKey] = names;
                    }
                }
            }

            active_items.value = newActiveItems;
        })
    }
    catch (error) {
        console.log(error)
    }
}

const getSearchResults =  async (offset) => {
    search.value.queries[0].q = search_query.value
    search.value.queries[0].offset = offset
    search_results_list.value = []
    if (!is_searching.value) {
        is_searching.value = true
        try {
            await axios.post(meili_url+urlApi, search.value, {headers: {'Authorization': `Bearer ${meili_key}`}}).then((result) => {
                products_list.value = result.data.results.find(x => x.indexUid == 'product_products')
                search_results_list.value = products_list.value.hits
            })
        }
        catch (error) {
            console.log(error)
        }
        is_searching.value = false
    }
    window.scrollTo(0, 0);
}

provide('getSearchResults', getSearchResults)

watch(search_query, (newValue) => {
    if (newValue === '') {
        hideSearchBox()
    } else {
        showSearchBox()
    }
})

watch(
  () => JSON.stringify(active_items.value),
  async (newValue) => {
    const { min, max } = selected_range.value;

    const filters = [];

    // Filtro base obligatorio
    filters.push(`(class.translations.${lang.value}.name = "Curso")`);

    // Rango de precios, si existe
    if (min != null && max != null) {
      filters.push(`(prices.MXN.finalPrice >= ${min} AND prices.MXN.finalPrice <= ${max})`);
    }

    // Filtros activos por categoría
    for (const [key, value] of Object.entries(active_items.value)) {
      if (value.length > 0) {
        const orGroup = value.map(x => `${key} = "${x}"`).join(' OR ');
        filters.push(`(${orGroup})`);
      }
    }

    // Unimos todos los grupos con AND
    search.value.queries[0].filter = filters.join(' AND ');

    getSearchResults(0);
  }
);

watch(
    () => JSON.stringify(search_query.value),
    (newValue) => {
        if (search_query.value == '') {
            getSearchResults(0)
        }
    }
)

// Fin Buscador

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(async () => {
    useHeadTags(route.fullPath.replace(/^\/+/, ''))
    getInfo(route.fullPath.replace(/^\/+/, ''));
    await getSearchFacets(0)
    await getSearchResults(0)
});

const calculateSlug = (slug) => {
  const normalize = (str) =>
    str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

  const normalizedSlug = normalize(slug.replace(/-/g, ' '));

  for (const [key, values] of Object.entries(facets_list.value)) {
    if (key.endsWith('.name')) {
      for (const facetName of Object.keys(values)) {
        if (normalize(facetName) === normalizedSlug) {
          return facetName; // devuelve el nombre original
        }
      }
    }
  }

  return null;
};

const getInfo = async (params) => {
    if (route) {
        const body = {
            type: 'FILTER',
            class: 'COURSE',
            slug: params
        };

        try {
            await axios.post(url + '/api/public/pages/info', body).then((result) => {
                console.log(result);
                information_filter.value = result.data.data
                loader.value = false
            });
        } catch (error) {
            console.log(error);
            loader.value = false
        }
    }
};
provide('getInfo', getInfo)

</script>

<style lang="scss" scoped>
body{
    max-width: 100% !important;
}
.position{
    position: absolute;
    right: 0;
    max-width: 34%;
    height: 100%;
    top: 0;
}
.filtros-absolute{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.35);
    width: 100vw;
    min-height:  102.5%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    z-index: 5;
    
}
.filtros{
    position: absolute;
    background-color: #FFFFFF;
    max-width: 95vw;
    min-height:  102.5%;
    z-index: 10;
    left: 0;
    top: 0;
    
}

.table-space {
  display: grid;
  grid-template-columns: repeat(4, 1fr); // 4 elementos por fila en pantallas grandes
  gap: 30px; // Espacio entre elementos

  @media (max-width: 1440px) { 
    grid-template-columns: repeat(3, 1fr); // 3 elementos por fila en pantallas 'lg'
  }

  @media (max-width: 1024px) { 
    grid-template-columns: repeat(2, 1fr); // 2 elementos por fila en pantallas 'md-lg'
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr); // 2 elementos por fila en pantallas 'md'
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; // 1 elemento por fila en pantallas móviles
  }

  .slide {
    width: 100%;
    margin: 0;
    // Otros estilos de los elementos
  }
}

.pagination{
    .page-item{
        border: 0;
        border-radius: 25px;
        margin: 0 2px;
        .page-link{
            border-radius: 25px;
            border: 0;
            font-weight: 500;
            color: var(--colorSecondary);
            font-family: 'Poppins';
            a{
                &:hover{
                    background-color: var(--colorPrimary);
                    color: var(--colorSecondary);
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .buttons{
        border-radius: 15px;
        .page-link{
            border-radius: 15px;
        }
    }
    
    .active{
        background-color: var(--colorPrimary);
        .page-link{
            color: #fff !important;
        }
        a{
            color: #fff !important;
        }
    }

    .disabled{
        display: none;
    }
}

.skeleton-card {
    min-width: 100%;
    min-height: 465px;
    max-height: 70vh;
    padding: 16px;
    border-radius: 8px;
    background-color: white;
}

.skeleton-header {
    height: 240px;
    background-color: #e0e0e0;
    border-radius: 8px;
    margin-bottom: 16px;
}

.skeleton-image {
    width: 100%;
    height: 100%;
    background-color: #ccc;
    border-radius: 8px;
    animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-body {
    padding: 8px 0;
}

.skeleton-title, .skeleton-subtitle, .skeleton-icon, .skeleton-price, .skeleton-button {
    animation: skeleton-loading 1.5s infinite ease-in-out;
}

.skeleton-title {
    width: 70%;
    height: 20px;
    background-color: #e0e0e0;
    margin-bottom: 10px;
    border-radius: 4px;
}

.skeleton-subtitle {
    width: 50%;
    height: 20px;
    background-color: #e0e0e0;
    margin-bottom: 10px;
    border-radius: 4px;
}

.skeleton-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.skeleton-icon {
    width: 30px;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 50%;
}

.skeleton-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.skeleton-price {
    width: 50px;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.skeleton-button {
    width: 80px;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 8px;
}

@keyframes skeleton-loading {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}
.box{
    background-color: #fff;
}
</style>