<template>
    <div class="col-12 d-flex justify-content-center align-items-center opiniones mb-4 mb-lg-0" v-for="(opinion, id) in opinions.data">
        <div class="row justify-content-center row__width">
            <div class="col-3 col-lg d-flex justify-content-center align-items-center align-items-lg-start px-0 perfil-foto">
            <img :src="opinion.avatar" alt="" class="" v-if="opinion.avatar">
            <img src="../../img/home-icons/cursos-icons/user.svg" alt="" class="border" v-else>
            </div>
            <div class="col d-flex justify-content-start align-items-center flex-cambio">
                <div class="row justify-content-center row__width">
                    <div class="col-12 d-flex justify-content-start align-items-center py-1 px-lg-0" v-if="opinion.reviewer">
                        <p class="name">{{opinion.reviewer.first_name}} {{opinion.reviewer.last_name}}</p> <p class="date d-none">{{ formatDate(opinion.date) }}</p>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1 starts px-lg-0">
                        <div class="d-flex justify-content-center align-items-center">
                            <img v-for="star in fullStars(opinion.score)" :key="star" src="../../img/home-icons/cursos-icons/rateV2.svg" alt="Estrella llena">
                            <img v-for="grayStar in grayStars(opinion.score)" :key="grayStar" src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="Estrella gris">
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center d-lg-none">
                        <p class="date px-0 py-2">{{ formatDate(opinion.date) }}</p>
                    </div>
                </div>
                <!-- <div class="row justify-content-center row__width d-none d-lg-flex">
                    <div class="col-12 d-flex justify-content-start align-items-center botones px-0">
                        <h6>¿Te gusto este comentario?</h6>
                        <button><img src="../../img/home-icons/cursos-icons/like.svg" alt=""></button>
                        <button><img src="../../img/home-icons/cursos-icons/dislike.svg" alt=""></button>
                    </div>
                </div> -->
                
            </div>
            <div class="col-12 d-flex justify-content-start align-items-center mt-3 py-1 px-0 parraf">
                <p>
                    {{opinion.comment}}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, provide, inject } from "vue";
const opinions = inject('opinions')

// Función para generar estrellas llenas
const fullStars = (score) => {
    return Array(score).fill(0); // Retorna un array con el número de estrellas llenas
};

// Función para generar estrellas grises
const grayStars = (score) => {
    const totalStars = 5;
    return Array(totalStars - score).fill(0); // Retorna un array con el número de estrellas grises
};

const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`; // Retorna la fecha formateada
};
</script>

<style lang="scss" scoped>
.opiniones{ 
    .name{
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
    }
    .date{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
        padding-left: 10px;
    }
    .starts{
        img{
            padding-right: 3px;
        }
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    .perfil-foto{
        max-width: max-content;
        img{
            height: 68px;
            border-radius: 50%;
            max-width: 68px;
        }
    }
    h6{
        font-weight: 400;
        font-size: 12px;
        color: #B8C1DB;
    }
    .botones{
        button{
            border: 0;
            background-color: transparent;
            outline: 0;
            &:focus{
                outline: 0;
            }
        }
    }
    .before_respuesta{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            background-color: #E4E4E4;
            border-radius: 50px;
            width: 2px;
            height: 110%;
            left: -5%;
        }
    }
    h5{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
    }
    .contenedor{
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        input{
            border: 2px solid #E9E3E3;
            background-color: #FCFCFC;
            border-radius: 10px;
            height: 50px;
            width: 100%;
            color: #697891;
            font-weight: 400;
            font-size: 15px;
            padding-left: 15px;
            &:hover, &:focus{
                outline: 0;
                border-color: var(--colorPrimary);
            }
        }
        img{
            position: absolute;
            margin-right: 20px;
        }

    }
    @media (min-width: 992px) {
        margin: 20px 0px;
        .perfil-foto{
           img{
                height: 70px;
                max-width: 78px;
           }
        }
        .flex-cambio{
            flex-direction: column;
        }
        .before{
            &::before{
                position: relative;
            }
        }
    }
}

.parraf{
    p{
        white-space: break-spaces;
        text-overflow: ellipsis;
        overflow: hidden;
        
    }
    // padding-left: 70px;
    // @media (min-width: 1024px) {
    //     padding-left: 80px;
    // }
}
.border{
    border: solid 1px #FCFCFC;
}
</style>