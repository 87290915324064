<template>
    <div :to="{name: 'Curso', params: {slug: slug}}" class="col-12 col-lg d-flex flex-column justify-content-start align-items-center slide px-0" >
    <router-link  :to="{name: 'Curso', params: {slug: slug}}" class="row justify-content-center row__width caption">
        <div class="col-11 d-flex justify-content-center align-items-center px-0">
            <span class="filtro"><img :src="image" alt="" class="ejemplo"></span>
            <div class="row justify-content-center row__width position">
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <div class="row justify-content-center row__width row__slide">
                        <div class="col-12 d-flex justify-content-end align-items-start arriba" v-if="products_quantity > 0">
                            <a href="">{{products_quantity}} {{ $t('message.productsV2') }}</a>
                        </div>
                        <div class="col-12 d-flex justify-content-center align-items-center abajo">
                            <div class="row justify-content-center row__width">
                                <div class="col d-flex justify-content-start align-items-center">
                                    <p class="modality">{{ modality }}</p>
                                </div>
                                <div class="col col-lg-5 d-flex justify-content-end align-items-center" v-if="reviews">
                                    <p class="average"><img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="">{{ reviews.average }}</p><p>{{ reviews.count ? `(${reviews.count})` : $t('message.new') }}</p>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
    <router-link  :to="{name: 'Curso', params: {slug: slug}}" class="row justify-content-center descripcion row__width px-2">
        <div class="col-12 d-flex justify-content-center justify-content-lg-start align-items-center">
            <h3>{{name}}</h3>
        </div>
    </router-link>
    <router-link  :to="{name: 'Curso', params: {slug: slug}}" class="row justify-content-center listado row__width">
        <div class="scroll__desk">
            <div class="col-12 d-flex justify-content-center align-items-center products" v-for="(product, id) in products_list">
                <div class="row justify-content-center align-items-start row__width border-div py-2">
                    <div class="col-4 col-sm-5 d-flex justify-content-center align-items-center img">
                        <span class="filtro" v-if="product.image"><img :src="product.image" alt=""></span>
                        <!-- <button><img src="../../img/home-icons/ver-mas.svg" alt=""></button> -->
                    </div>
                    <div class="col d-flex justify-content-center align-items-center px-0">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center pb-2 pe-0">
                                <h3>{{ product.translations[lang].name }}</h3>
                            </div>
                            <div class="col-12 d-flex justify-content-start align-items-center pe-0">
                                <div class="position-relative d-flex justify-content-center align-items-end">
                                    <img :src="product.owner.avatar" alt="" class="avatar" v-if="product.owner?.avatar">
                                    <!-- <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="verificacion"> -->
                                </div>
                                <div class="d-flex flex-column justify-content-center align-items-start ps-2">
                                    <p class="creator">{{$t('message.creator')}}</p>
                                    <p class=" name">{{ product.owner?.name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
    <div class="row justify-content-start row__width px-2">
        <div class="col-12 d-flex justify-content-start align-items-center colaboradores">
            <button type="button" class="btn_none d-flex justify-content-center align-items-center" v-if=" collabs && collabs.length >= 1" @click="openModal(id)">
                <img :src="collabs[0].avatar" alt="avatar-collab" class="img_ejemplo">
                <div class="colaboran"><p class="collabs">+{{ collabs.length }}</p></div>
                <p class="collaborate p_mobile">{{$t('message.collaborate')}}</p>
            </button>
            <button type="button" class="btn_none d-flex justify-content-start align-items-center w-100" v-if=" sponsors && sponsors.length >= 1" @click="openModal2(id)">
                <img :src="sponsors[0].avatar" alt="avatar-collab" class="img_ejemplo">
                <div class="colaboran"><p class="collabs">+{{ sponsors.length }}</p></div>
                <p class="collaborate p_mobile">Patrocinan</p>
            </button>
        </div>
    </div>
    <router-link  :to="{name: 'Curso', params: {slug: slug}}" class="row justify-content-start comprar row__width px-2">
        <div class="col d-flex flex-column justify-content-center align-items-start padding pe-0 my-1 my-lg-4" v-if="prices">
            <p class="final_price">MXN ${{ prices.MXN[0]?.finalPrice }}</p>
            <p class="total_price" v-if="prices.MXN[0]?.discount">MXN ${{ prices.MXN[0]?.totalPrice }}</p>
        </div>
        <div class="col d-flex justify-content-center align-items-center px-0">
            <router-link :to="{name: 'Curso', params: {slug: slug}}" class="btn__primario btn-width">
                {{$t('message.buy')}}
            </router-link>
        </div>
    </router-link>
</div>

</template>


<script setup>
import ListadoCursosSlide from '../Sliders/ListadoCursosSlide.vue'
import BTNPrimario from "../Botones/PrimarioRutas.vue";
import { useGeneralStore } from "../../stores/general"
import { useConfigStore } from "../../stores/config"
import { useUsersStore } from "../../stores/user" 
import { storeToRefs } from "pinia";
import { ref, watch, nextTick, inject, onMounted } from 'vue';
import { useI18n } from "vue-i18n";
import axios from 'axios';

const general = useGeneralStore();
const {filtrosMapa, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
const {} = general;

const config = useConfigStore();
const { coinSelected } = storeToRefs(config);

const users = useUsersStore()
const {  } = storeToRefs(users)
const { url } = users

const {locale} = useI18n({ useScope: 'global' })
const lang = ref('ES')
const X = ref('')


//Recibe los parametros de busqueda del componente padre
 const props = defineProps({
    product: '',
})

//Setea las variables con los resultados para su uso
let name = props.product.names?.translations[lang.value]?.name
let slug = props.product.slugs?.translations[lang.value]?.slug || props.product.dinamic_slug
let owner = props.product.owner?.name
let avatar = props.product.owner?.avatar
let image = props.product.image
let modality = props.product.type?.translations[lang.value]?.name
let details = props.product.details
let prices = props.product.prices
let verified = props.product.verified
let createdAt = props.product.created_at
let collabs = props.product.colaborators
let reviews = props.product.rating
let marketplaces = props.product.marketplaces[0]
let membership = props.product.membership
let students = props.product.students
let products_quantity = props.product.package_items
let products_list = props.product.package_products
let sponsors = props.product.sponsors
let id = props.product.id

const openModal = (id) => {
    console.log(id)
    let element = document.getElementById('collabModal'+id)
    console.log(element)
    console.log(element.classList)
    element.classList.remove("d-none")
}

const openModal2 = (id) => {
    console.log(id)
    let element = document.getElementById('sponsorModal'+id)
    console.log(element)
    console.log(element.classList)
    element.classList.remove("d-none")
}

</script>

<style lang="scss" scoped>
.slide{
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    // height: 550px;
    max-height: 550px;
    .caption{
        position: relative;
        margin: 10px 0px;
        .filtro{
            min-width: 100%;
            position: relative;
            img{
                height: 160px;
                width: 100%;
                border-radius: 15px;
            }
            &::before{
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 50%;
                background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
                filter: opacity(1);
                border-radius: 15px;
                z-index: 1;
            }
        }
        .ejemplo{
            max-height: 24vh;
            width: 100%;
        }
        .position{
            position: absolute;
            top: 0;
            min-height: 100%;
            z-index: 2;
            h3{
                font-weight: 700;
                font-size: 16px;
                color: var(--bgPrincipal);
            }
            .modality{
                font-weight: 500;
                font-size: 15px;
                color: var(--bgPrincipal);
            }
            .average{
                font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: #FEB230;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: max-content;
                position: relative;
                top: 2px;
                img{
                    height: 20px;
                    width: 20px;
                    top: -2px;
                    position: relative;
                    padding-left: 5px
                }
            }
            p{  
                font-family: 'Proxima Nova';
                font-weight: 500;
                font-size: 17px;
                color: var(--bgPrincipal);
                padding-left: 5px;
            }
            a{
                font-weight: 500;
                font-size: 16px;
                color: var(--bgPrincipal);
                text-decoration: none;
                background-color: var(--colorSecondary);
                border-radius: 28px;
                opacity: 0.73;
                padding: 7px 22px;
            }
            .row__slide{
                position: relative;
                min-height: 100% !important;
                min-height: 250px;
                .arriba{
                    position: absolute;
                    top: 10%;
                }
                .abajo{
                    position: absolute;
                    bottom: 11%;
                }
            }
            
        }
    }
    .listado{
        height: 200px;
        max-height: 200px;
        overflow-y: scroll;
        &::-webkit-scrollbar-thumb{
            background-color: #fff;
        }
        &::-webkit-scrollbar{
            background-color: #fff;
            width: 2px;
        }
        @media (min-width: 992px) {
            overflow:auto ;
            max-height: none;
            padding: 0 10px;
            .scroll__desk{
                max-height: 250px;
                overflow-y: scroll;
                &::-webkit-scrollbar-thumb{
                    background-color: #DBDBDB;
                    border-radius: 50px;
                }
                &::-webkit-scrollbar{
                    background-color: rgba(219, 219, 219, 0.25);
                    width: 8px;
                    border-radius: 50px;
                }
            }
        }
    }
    .descripcion{
        padding: 10px 0px;
        h3{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-align: left;
            min-height: 53px;
        }
    }
    .comprar{
        margin-bottom: 5px;
        margin-top: 15px;
        .final_price{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            @media (max-width: 992px) {
                font-size: 16px;
            }
        }
        .total_price{
            font-size: 14px;
            font-weight: 400;
            color: #B8C1DB;
            text-decoration: line-through;
            text-align: left;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }
        .padding{
            padding-left: 10px;
        }
    }
    .colaboradores{
        position: relative;
        p{
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
        }
        .img_ejemplo{
            object-fit: cover;
            border-radius: 50%;
            min-width: 25px;
            min-height: 25px;
            max-width: 25px;
            max-height: 25px; 
            margin-right: 5px;
            position: relative;
            left: 0;
            
        }
        .colaboran{
            background-color: #697891;
            box-shadow: 0px 3px 6px #00000029;
            border: 2px solid #FFFFFF;
            border-radius: 50%;
            min-width: 25px;
            min-height: 25px;
            max-width: 25px;
            max-height: 25px;                
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            right: 15px;
            z-index: 1;
            p{
                font-size: 10px
            }
        }
        .collabs{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .colaboran + .collaborate{
            position: relative;
            right: 10px;
        }
        @media (min-width: 1200px) {
            .img_ejemplo, .colaboran{
                min-width: 35px;
                min-height: 35px;
                max-width: 35px;
                max-height: 35px; 
                // position: absolute;
                // left: 2%;
            }
            .img-ejemplo{
                left: 15px;
            }
            // .colaboran + h3{
            //     position: relative;
            //     right: 0;
            // }
            .colaboran {
                p{
                    font-size: 14px;
                }
            }
        }
    }

    @media (min-width: 992px) {
        max-width: 96%;
    }
}
.products{
    h3{
        font-weight: 700;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .creator{
        color: #B8C1DB;
        font-weight: 500;
        font-size: 15px;
    }
    .name{
        color: var(--colorSecondary);
        font-weight: 500;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    p{
        color: var(--bgPrincipal);
        font-weight: 500;
        font-size: 15px;
    }
    h5{
        font-weight: 700;
        font-size: 20px;
        color: var(--colorSecondary);
        padding-left: 5px;
        @media (max-width: 992px) {
            font-size: 16px;
        }
    }
    h6{
        font-size: 14px;
        font-weight: 400;
        color: #B8C1DB;
        text-decoration: line-through;
    }
    .border-div{
        border-bottom:  1px solid rgba(184, 193, 219, 0.3);
        border-top: 1px solid rgba(184, 193, 219, 0.3);
        @media (max-width: 375px){
            .flex-cambio{
                flex-direction: column;
                align-items: flex-start !important;
                h5{
                    padding-left: 0;
                }
            }
        }
        @media (min-width: 376px){
            .flex-cambio{
                align-items: center !important;
            }
        }
        
    }
    .verificacion{
        position: absolute;
        left: 25px;
    }

    .img{
        position: relative;
        padding: 0;
        .filtro{
            width: 100%;
            img{
                height: 118px;
                width: 100%;
                border-radius: 15px;
                object-fit: cover;
                /* @media (max-width: 992px) {
                    height: 60px;
                } */
            }
            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 118px;
                background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
                filter: opacity(1);
                border-radius: 15px;
                z-index: 1;
            }
            /* @media (max-width: 992px) {
                &::before{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 60px;
                    background: transparent linear-gradient(180deg, #252E4800 0%, #081026 100%) 0% 0% no-repeat padding-box;
                    filter: opacity(1);
                    border-radius: 15px;
                    z-index: 1;
                }
            } */
        }
        button{
            border: 0;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 52px;
            background-color: #fff;
            outline: 0;
            height: 36px;
            width: 36px;
            position: absolute;
            left: -15px;
            z-index: 2;
            img{
                max-height: 15px;
            }
        }
    }
    .avatar{
        height: 35px;
        width: 35px;
        border-radius: 50%;
        object-fit: cover;
        position: relative;
    }
}
</style>