<template>
    <div class="container-fluid">
        <Navbar class="d-none d-lg-flex" v-if="windowWidth >= 992"/>
        <NavbarBuscador class="d-lg-none"/>
        <div class="row justify-content-center">
            <div class="col-11 margin-top-arreglo">
                <Volver_Inicio/>
            </div>
            <div class="col-11 col-md-5 d-flex flex-column justify-content-center align-items-center max__width__login__Desk">
                <RolInicioRegistro/>
                <TituloYSubtituloRegistro :titulo="$t('message.titleRegister')" />
                <FormRegistroMail/>
                <div class="row justify-content-center row__width mt-2">
                    <!-- <ORegistrarseCon/> -->
                </div>
                <div class="row justify-content-center row__width">
                    <!-- <div class="col-12 d-flex flex-column justify-content-center align-items-center px-0">
                        <BTNFacebook :textoBTNFacebook="$t('message.loginFacebook')"/>
                        <BTNGoogle :textoBTNGoogle="$t('message.loginGoogle')"/>
                        <BTNTelefono :textoBTNTelefono="$t('message.continuePhone')"/>
                    </div> -->
                    <IniciarEnlace :cuenta="$t('message.iHaveAccount')" :enlace="$t('message.login')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import NavbarBuscador from '../../components/Navs/NavbarBuscador.vue';
import ORegistrarseCon from '../../components/Titulo/ORegistrarseCon.vue';
import BTNFacebook from '../../components/Botones/Facebook.vue';
import BTNGoogle from '../../components/Botones/Google.vue';
import BTNTelefono from '../../components/Botones/Telefono.vue';
import Volver_Inicio from '../../components/Volver/Volver_Inicio.vue';
import RolInicioRegistro from '../../components/Roles/RolInicioRegistro.vue';
import IniciarEnlace from '../../components/Enlaces/IniciarEnlace.vue';
import TituloYSubtituloRegistro from "../../components/Titulo/TituloYSubtituloRegistro.vue";
import FormRegistroMail from "../../components/Forms/FormRegistroMail.vue";
import Navbar from '../../components/Navs/Navbar.vue';

import { provide, ref, computed, onUnmounted, onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';

const route = useRoute()
const router = useRouter();

const windowWidth = ref(window.innerWidth)

const handleResize = () => {
  windowWidth.value = window.innerWidth
}

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
  window.addEventListener('resize', handleResize)
  useHeadTags({ slug: route.fullPath.slice(1) })
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style lang="scss" scoped>
.usuario-sesion{
    span{
        height: 86px;
        min-width: 86px;
        overflow: hidden;
        display: flex;
        justify-content: center;     
        align-items: flex-end;
        background-color: #F2F2F2;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);  
        border-radius: 50%;
        img{
            width: 90%;
            height: 90%;
        }
    }
    h3{
        color: #697891;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
    }
    .margin-top{
        margin: 10% 0 0% 0;
    }
    .recordar{
        padding-top: 10px;
        a{
            color: var(--colorPrimary);
            font-size: 14px;
            font-weight: 400;
        }
        input{
            display: none;
        }
        label{
            height: 15px;
            width: 15px;
            border: 1px solid #697891;
            border-radius: 5px;
            position: relative;
            p{
                font-size: 14px;
                font-weight: 400;
                color: #697891;
                padding-left: 25px;
            }
        }
        input:checked + label::before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #1679FC;
        }
        
    }
    
}

</style>