<template>
    <div class="about-section" v-if="userAbout">
        <div class="row">
            <div class="col-12 section-header">
                <h2>{{ $t('message.about') }} {{ userName }}</h2>
            </div>
            <div class="col-12 section-content">
                <p v-html="userAboutCorrected" v-if="description_is_html" class="about-text" :class="{ 'truncated': !isExpanded }"></p>
                <p v-else class="about-text" :class="{ 'truncated': !isExpanded }">{{ props.userAbout }}</p>
                
                <div class="d-flex justify-content-start">
                    <button class="read-more-btn" v-if="isTruncatable" @click="toggleText">
                        {{ isExpanded ? $t('message.readLess') : $t('message.readMore') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted,} from 'vue';
import Portafolio from "./Secciones/Portafolio.vue";
import Cursos from "./Secciones/Cursos.vue";
import ServiciosV2 from "./Secciones/ServiciosV2.vue";

const props = defineProps({
    sobre: '',
    detalle: '',
    miembro: '',
    userName: '',
    userAbout: '',
    courses_uploaded: '',
    images_uploaded: '',
    courses: {
        type: Object
    },
    avatar: '',
    gallery:{
        type: Object
    }
})

const isExpanded = ref(false);
const maxLength = 300;
const isTruncatable = computed(() => {
    return props.userAbout && props.userAbout.length > maxLength;
});
    
const userAboutCorrected = computed(() => {
    if (!props.userAbout) return '';
    return isExpanded.value ? props.userAbout : truncateText(props.userAbout, maxLength);
});
    
function truncateText(text, maxLength) {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
}
    
function toggleText() {
    isExpanded.value = !isExpanded.value;
}

const description_is_html = computed(() => {
    if (!props.userAbout) return false;
    const html_tags = /<\/?[a-z][\s\S]*>/i;
    return html_tags.test(props.userAbout);
});

</script>

<style lang="scss" scoped>
:root {
    --color-dark: #1F2937;
    --color-light-green: #F0FDF4;
    --color-green: #22C55E;
    --color-blue: #1779FC;
    --color-white: #FFFFFF;
    --color-gray: #374151;
    --color-indigo: #4F46E5;
    --color-light-gray: #E5E7EB;
}

.about-section {
    background-color: var(--color-white);
    border-radius: 0.75rem;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
    
    .section-header {
        margin-bottom: 1rem;
        
        h2 {
            font-size: 1.25rem;
            font-weight: 600;
            color: var(--color-dark);
            margin: 0;
            
            @media (min-width: 768px) {
                font-size: 1.35rem;
            }
        }
    }
    
    .section-content {
        .about-text {
            font-size: 0.95rem;
            line-height: 1.6;
            color: var(--color-gray);
            margin-bottom: 1rem;
            white-space: pre-line;
            
            &.truncated {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
        
        .read-more-btn {
            background-color: transparent;
            color: var(--color-indigo);
            border: none;
            font-size: 0.875rem;
            font-weight: 500;
            padding: 0;
            cursor: pointer;
            transition: all 0.2s ease;
            
            &:hover {
                color: var(--color-blue);
                text-decoration: underline;
            }
        }
    }
}
</style>