<template>
    <div class="col-12 d-flex justify-content-start align-items-center px-0">
        <h2 :class="tituloGrande == true ? 'titulo__grande' : '' || tituloMediano == true ? 'titulo__mediano' : '' ">{{tituloCurso}}</h2>
    </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, onUnmounted, watch, inject } from 'vue';
const props = defineProps({
    tituloCurso: '',
    tituloGrande: {
        type: Boolean,
        default : false
    },
    tituloMediano: {
        type: Boolean,
        default : false
    }
});
let product = inject('product')
</script>

<style lang="scss" scoped>
h2{
    font-weight: 700;
    font-size: 22px;
    color: var(--colorSecondary);
    margin: 15px 0px;
    text-align: left;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.2;
    text-align: left;
}

@media (min-width: 992px) {
    .titulo__grande{
        font-size: 30px;
    }
    .titulo__mediano{
        color: #374557;
        padding: 15px 0px;
    }
}

.colaboradores{
    position: relative;
    min-width: 120px;
    .colaboran{
        background-color: #697891;
        box-shadow: 0px 3px 6px #00000029;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        min-width: 42px;
        min-height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 15%;
    }
    .colaboran_h3{
        margin-left: 10px;
    }
    .azul{
        background-color: var(--colorSecondary);
    }
}
</style>