<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start">
        <NavbarCertificados :seccion="$t('message.SEO')"/>
        <div class="col d-flex flex-column justify-content-center align-items-center align-items-lg-start px-0 classSpecial">
            <Pages v-if="page_id == 1"/>
            <Tags v-if="page_id == 2"/>
            <NewTag v-if="page_id == 3"/>
            <EditTag v-if="page_id == 4"/>
        </div>
        <FooterCertificados :activeCertificados="true" class="d-lg-none"/>
        <div class="espacio2"></div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../../components/Footer/FooterCertificados.vue";
import Pages from "./Pages.vue";
import Tags from "./Tags.vue"
import NewTag from "./NewTag.vue"
import EditTag from "./EditTag.vue"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../../stores/user"
import { onBeforeMount, ref, provide, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Swal from 'sweetalert2'
import axios from 'axios';

const route = useRoute()
const router = useRouter()

const users = useUsersStore();
const { access, headers } = storeToRefs(users);
const { validate, url } = users;

const algo = onBeforeMount( () => {
    access.value = [1]
    //console.log(access.value) 
    // validate();
})

const url_type =  '/api/admin/products/classes'

const urlTags = '/api/admin/seo/tags'

const page_id = ref(1)
provide('page_id', page_id)

const user_id = ref(0)
provide('user_id', user_id)

const pages = ref({ data: '' })
provide('pages', pages)

const edit_page = ref({})
provide('edit_page', edit_page)

const new_tag = ref({
    type: '',
    class: '',
    slug: '',
    key: '',
    value: ''
})
provide('new_tag', new_tag)

const edit_tag = ref({})
provide('edit_tag', edit_tag)

const resetTags = () => {
    new_tag.value = {
        type: '',
        class: '',
        slug: '',
        key: '',
        value: ''
    }

    edit_tag.value = {}

    page_id.value = 2
}
provide('resetTags', resetTags)

const slug = ref('PAGES')
provide('slug', slug)

const prod_classes = ref()
const types = ref()
const products = ref()
const professionals = ref()

const selected_keywords = ref([])
provide('selected_keywords', selected_keywords)

provide('prod_classes', prod_classes)
provide('types', types)
provide('products', products)
provide('professionals', professionals)

const fetchClasses = async () => {
    try {
        const response = await axios.get(`${url}${url_type}`, { headers: headers.value })
        prod_classes.value = response.data.data.map(item => {
            return {
                label: item.translation.name,
                value: item.name,
                slug: item.translation.slug,
                id: item.id
            }
        })
        console.log(prod_classes.value)
    } catch (error) {
        console.log(error)
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}
provide('fetchClasses', fetchClasses)

const fetchTypes = async (class_id) => {
    try {
        const response = await axios.get(`${url}${url_type}/${class_id}/types`, { headers: headers.value })
        types.value = response.data.data.map(item => {
            return {
                label: item.translation.name,
                value: item.translation.slug,
                id: item.id
            }
        })
    } catch (error) {
        console.log(error)
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}
provide('fetchTypes', fetchTypes)

const tags = ref()
provide('tags', tags)

const fetchTags = async(page_slug) => {
    try {
        const response = await axios.post(url+urlTags+"/slug", { slug: page_slug }, {headers: headers.value})
        console.log(response)
        tags.value = response.data.data
    } catch (error) {
        console.log(error)
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}
provide('fetchTags', fetchTags)

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
    useHeadTags({ slug: route.fullPath.slice(1) })
    fetchClasses()
})

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #fff;
    min-height: 92vh;

}
.contenedor{
    @media (min-width: 992px) {
        max-width: 95vw;
        margin-top: 100px;
    }
}
.classSpecial{
    @media (max-width: 991px) {
        padding-bottom: 30vw;
    }
    @media (min-width: 992px) {
        width: 70vw !important;
    }
}


</style>