<template>
    <div class="col-12 px-0 mb-4">
        <h3>Agregar estudiantes</h3>
    </div>
    <!-- Buscador -->
    <div class="col-12 col-md d-flex justify-content-center align-items-center contenedor px-0">
        <input type="text" 
            :placeholder="$t('message.placeholderSearchUsers')" 
            @input="handleSearch(search_query)" 
            @blur="hideSearchBox" 
            v-model="search_query">
        <button class="button" type="button" @click="handleSearch(search_query)">
            <img src="../../../img/home-icons/lupa.svg" alt="">
        </button>
    </div>
    <div class="col-12 col-md-4 d-flex justify-content-center align-items-center px-0">
        <div class="row row row__width justify-content-start">
            <div class="col-12">
                <button type="button" class="btn__primario py-3" @click="pageStudents = 1">
                    Volver al listado
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center contenido px-0">
        <div class="row row__width" v-if="is_searching || !users_data">
            <div class="col-12 d-flex justify-content-start align-items-center pt-4">
                <div class="skeleton-avatar"></div>
                <div class="skeleton-text"></div>
            </div>
            <div class="col-12 d-flex justify-content-start align-items-center pt-4">
                <div class="skeleton-avatar"></div>
                <div class="skeleton-text"></div>
            </div>
            <div class="col-12 d-flex justify-content-start align-items-center pt-4">
                <div class="skeleton-avatar"></div>
                <div class="skeleton-text"></div>
            </div>
        </div>
        <div class="row justify-content-center row__width scroll my-3" v-if="users_data && !is_searching">
            <template v-for="(user, index) in users_data.data">
                <div class="col-12 d-flex justify-content-center align-items-center box px-0">
                    <div class="row justify-content-center row__width">
                        <div class="col-10 d-flex justify-content-start align-items-center">
                            <div class="d-flex justify-content-center align-items-center img">
                                <img :src="user.avatar" alt="avatar">
                                <div class="crown" v-if="user.plan && user.plan.id == 2">👑</div>
                            </div>
                            <div class="d-flex flex-column justify-content-center align-items-start excepcion ms-3 titles">
                                <h5>{{ $t('message.name') }}</h5>
                                <h6 v-if="user.personal_data">{{ user.personal_data.first_name}}</h6>
                                <h6 v-else-if="user.name">{{ user.name}}</h6>
                                <h6>{{  user.email }}</h6>
                            </div>
                        </div>
                        <div class="col-2 d-flex justify-content-end justify-content-lg-center align-items-center eliminar ps-0">
                            <button type="button" class="btn__primary" @click="addUser(user.id)">
                                <img src="../../../img/subir-productos-icons/cursos-icons/correct.svg" alt="edit">
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <!-- Paginación -->
        <template v-if="users_data">
            <nav v-if="users_data.links" class="py-2">
                <ul class="pagination">
                    <li v-for="(link, index) in users_data.links" :key="link.label" :class="{'active': link.active}" class="page-item">
                        <a v-if="link.url" class="page-link" href="#" v-html="link.label" @click.prevent="fetchUsers(link.url)"></a>
                        <span v-else class="page-link" v-html="link.label"></span>
                    </li>
                </ul>
            </nav>
        </template>
    </div>
</template>

<script setup>
import { onMounted, provide, ref, watch, onUnmounted, inject } from 'vue';
import axios from "axios"
import { useI18n } from "vue-i18n"
import { useRouter } from 'vue-router';
import { storeToRefs } from "pinia";
import { useUsersStore } from "../../../stores/user";
import { useLanguageStore } from "../../../stores/langs"
import Swal from 'sweetalert2'

const router = useRouter()

const {locale} = useI18n({ useScope: 'global' })
const langs = useLanguageStore()
const {} = langs

const users = useUsersStore()
const {datosUsuario, professionalData, headers} = storeToRefs(users)
const {url, validarMail, usuarioData, userProfessionalData} = users

const users_data = ref()
const urlReadUsers = '/api/admin/users'
const product = inject('product')
const pageStudents = inject('pageStudents')

// Buscador
const original_users_data = ref([]);
const search_query = ref('');
const search_box = ref(false);
const is_searching = ref(false);
const search_results_list = ref([]);
const professions_list = ref([]);
const has_results = ref(false);

watch(search_query, (newValue) => {
    if (newValue === '') {
        is_searching.value = true
    } else {
        is_searching.value = false
    }
})

const handleSearch = async (query) => {
    console.log(search_query.value)
    if (!search_query.value) {
        // Si el campo de búsqueda está vacío, restaurar la lista original
        
        fetchUsers()
        is_searching.value = false
    } else {
        await getSearchResults(search_query.value);
    }
};

const getSearchResults = async (query) => {
    if (!is_searching.value) {
        is_searching.value = true;
        // console.log(query)
        try {
            const response = await axios.get(`${url}/api/admin/users/search?value=${query}`, { headers: headers.value });
            console.log(response.data)
            users_data.value.data = response.data.data;  
            if(response.data.links){
                users_data.value.links = response.data.links
            }else{
                users_data.value.links = null
            }
            search_results_list.value = response.data.data.slice(0, 3); 
        } catch (error) {
            console.error(error);
        } finally {
            is_searching.value = false;
        }
    }
};

const addUser = async (user_id) => {
    try {
        const body = { userId: user_id };
        await axios.post(`${url}/api/admin/products/${product.value.id}/students`, body ,{ headers: headers.value });
        Swal.fire({
            icon: 'success',
            title: 'Usuario agregado con éxito',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        });
    } catch (error) {
        console.error("Error al agregar usuario:", error);
    }
};

const fetchUsers = async (page) => {
    is_searching.value = true
    if(!page){
        page = 1
        const response = await axios.get(url+urlReadUsers+`?page=${page}`, { headers: headers.value })
        users_data.value = response.data.data
    }else if(page){
        const response = await axios.get(page, { headers: headers.value })
        users_data.value = response.data.data
    }
    is_searching.value = false
};

fetchUsers();
</script>

<style lang="scss" scoped>

h3{
    color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding-left: 10px;
        margin-bottom: 20px;
}
.arrow-toggle{
    rotate: 180deg;
    transition: all ease 0.3s;
}

.contenedor{
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 40px;
    min-height: 0px;
    height: 60px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    
    @media (min-width: 992px) {
        border-radius: 40px;
    }
    @media (max-width: 991px) {
        margin: 10px 0;
    }
    input{
        border: 0;
        color: #B8C1DB;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
        padding-left: 15px;
        &::placeholder{
            color: #B8C1DB;
        }
        &:focus{
            outline: 0;
        }
        
        @media (min-width: 992px) {
            color: #697891;
            font-size: 16px;
            width: 93%;
        }
    }
    .button{
        position: absolute;
        right: 7px;
        border: 0;
        min-height: 45px;
        width: 45px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        background-color: var(--colorPrimary);
        justify-content: center;
        align-items: center;
        img{
            padding: 0;
            max-height: 22px;
        }
        @media (min-width: 992px) {
            right: 10px;
        }
    }
}

.box{
    border: 0;
    border-radius: 10px;
    //box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    background-color: #F9F9F9;
    margin-bottom: 15px;
    min-height: 90px;
    h5{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        padding-bottom: 10px;
        white-space: break-spaces;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        position: relative;
    }
    .img{
        position: relative;
        min-height: 58px;
        min-width: 58px;
        max-height: 58px;
        max-width: 58px;
        img{
            min-height: 58px;
            min-width: 58px;
            max-height: 58px;
            max-width: 58px;
            border-radius: 50%;
            object-fit: cover;
        }
        .crown{
            position: absolute;
            top: -6px;
            right: 0;
            transform: rotate(17deg);
        }
    }
    .titles{
        max-width: 100%;
        overflow: hidden;
        h6{
            color: #B8C1DB;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
    }
    .body{
        h6{
            color:#B8BCC8;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            padding: 5px 0px;
            white-space: break-spaces; 
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
        }
        span{
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            padding: 5px 10px;
            min-width: 110px;
        }
        .inactive{
            background-color: #F5F5F5;
            color: #707070;
        }
        .active{
            background-color: #38E6A7;
            color: #FFFFFF;
        }
        .pause{
            background-color: #FEB230;
            color: #FFFFFF;
        }
        .email-hover{
            // &:hover{
            //     position: absolute;
            //     bottom: 20px;
            //     border: 1px solid #E5E5E5;
            // }
        }
        
    }
    button{
        background-color: transparent;
        border: 2px solid #E4E4E4;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    button.arrow::after{
        content: '';
        position: absolute;
        background-image: url('../../../img/subir-productos-icons/arrow-abajo-azul.svg');
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center;
        background-color: transparent;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: block;
        @media (min-width: 992px) {
            display: none;
        }
    }
    
    @media (max-width: 992px) {
        h5{
            padding-bottom: 0;
        }
    }
    .seccion{
        .col-lg{
            margin-bottom: 15px;
        }
        @media (min-width: 992px) {
            .col-lg{
                max-width: 14vw;
            }
            .excepcion{
                max-width: 10vw;
                span{
                    max-width: 100%;
                    justify-content: start;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .excepcion{
                max-width: 8vw;
                span{
                    max-width: 100%;
                    justify-content: start;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .hide-plan{
                display: none !important;
            }
        }
        @media (min-width: 1100px) {
            .excepcion{
                max-width: 12vw;
            }
        }
        @media (min-width: 1200px) {
            .col-lg{
                max-width: 10vw;
            }
            .excepcion{
                max-width: 11vw;
                span{
                    max-width: 100%;
                    justify-content: start;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .hide-plan{
                display: flex !important;
            }
        }
    }

    
}
.padding{
    padding: 0 11%;
}
    


.scroll {
    overflow-y: scroll;
}

.contenido {
    max-height: 60dvh;
}

.search-select{
    max-width: 200px;
}

.skeleton-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0e0e0;
    margin-right: 10px;
}

.skeleton-text {
    width: 100%;
    height: 50px;
    background-color: #e0e0e0;
    border-radius: 4px;
}

.skeleton-avatar,
.skeleton-text {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f0f0f0;
    }
    100% {
        background-color: #e0e0e0;
    }
}

.search__box{
    position: absolute;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 30px;
    background-color: var(--bgPrincipal);
    height: auto;
    width: 90vw;
    max-width: 460px;
    z-index: 99;
    padding-top: 20px;
    padding-left: 30px;
    margin-top: 320px;
    justify-content: center;
    align-items: flex-start;
    transition: all ease 1s;
    top
    img{
        border-radius: 50%;
        height: 40px;
        width: 40px;
        object-fit: cover;
    }
    a{
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    .see-more{
        color: var(--colorPrimary);
    }
    h4{
        color: var(--colorSecondary);
        font-size: 24px;
        font-weight: 700;
        padding-left: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (min-width: 992px) {
            font-weight: 500;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    h5{
        font-weight: 500;
        font-size: 16px;
        color: #111316;
        padding-left: 10px;
        text-align: left;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    h6{
        font-weight: 500;
        font-size: 14px;
        color: #697891;
        padding-left: 5px;
        text-align: left;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
}
.pagination{
    .page-item{
        border: 0;
        border-radius: 25px;
        margin: 0 2px;
        .page-link{
            border-radius: 25px;
            border: 0;
            font-weight: 500;
            color: var(--colorSecondary);
            font-family: 'Poppins';
            a{
                &:hover{
                    background-color: var(--colorPrimary);
                    color: var(--colorSecondary);
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .buttons{
        border-radius: 15px;
        .page-link{
            border-radius: 15px;
        }
    }
    
    .active{
        background-color: var(--colorPrimary);
        .page-link{
            color: #fff !important;
        }
        a{
            color: #fff !important;
        }
    }

}
</style>