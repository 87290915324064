<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center profesor" v-if="professional">
        <!-- <div class="row justify-content-center row__width pt-3">
            <div class="col d-flex justify-content-center align-items-center px-0 position-relative">
                <img :src="professional.data.banner" alt="" class="banner" v-if="professional">
            </div>
        </div> -->
        <div class="row justify-content-center row__width mt-3" v-if="professional.data">
            <div class="col-4 d-flex justify-content-center align-items-center px-2">
                <img :src="professional.user.avatar" alt="" class="perfil" v-if="professional">
            </div>
            <div class="col-8 d-flex justify-content-center align-items-center px-3">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-between align-items-center py-1">
                        <p class="name">{{professional.data.full_name}}</p><img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="d-none">
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <img src="../../img/home-icons/cursos-icons/ubi-negro.svg" alt="" class="pe-2"><p class="city">De {{professional.data.city}}</p>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1" v-if="product.professions.length > 0">
                        <img src="../../img/home-icons/cursos-icons/camara-gris.svg" alt="" class="pe-2"><p class="professions">{{product.professions[0].name}}</p>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1 average">
                        <template v-if="product.reviews.average">
                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="image-fix" v-for="rate in Math.floor(product.reviews.average)" :key="`rate-${rate}`">
                            <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="" class="image-fix" v-for="rateGris in 5 - Math.floor(product.reviews.average)" :key="`rate-gris-${rateGris}`">
                        </template>
                        <template v-else>
                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="image-fix">
                        </template>
                        <p class="average">{{product.reviews.average}}</p>
                        <p class="count">{{ product.reviews.count ? `(${product.reviews.count})` : $t('message.new') }}</p>
                    </div>
                    <!-- <div class="col-12 d-flex justify-content-center align-items-center" id="filtro4">
                        <p class="max-width" v-html="userAboutCorrected" v-if="description_is_html"></p>
                        <p v-else>{{ professional?.user?.personal_data?.about }}</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useGeneralStore } from "../../stores/general"    
    import { storeToRefs } from "pinia";
    import { inject, onMounted, ref, watch } from 'vue';
    import axios from 'axios';

    let product = inject('product')
    let professional = inject('professional')

    const description_is_html = ref(false);

    const userAboutCorrected = ref('');
    
    const fixLinks = (description) => {
        const div = document.createElement('div');
        div.innerHTML = description;

        const links = div.querySelectorAll('a');

        links.forEach(link => {
            const href = link.getAttribute('href');
            if (href && !href.startsWith('http://') && !href.startsWith('https://')) {
            link.setAttribute('href', 'https://' + href);
            }
        });

        return div.innerHTML;
    }

    const seeDescription = () => {
        userAboutCorrected.value = fixLinks(professional.value.user?.personal_data?.about)
        if (fixLinks(professional.value.user?.personal_data?.about)) {
            description_is_html.value = true
        } else {
            description_is_html.value = false
        }
    }

    onMounted(() => {
        watch(professional, () => {
            seeDescription()
        })
    })

</script>


<style lang="scss" scoped>
.mySwiper{
    width: 100%;
    max-width: 100%;
    img{
        max-width: 90%;
        height: 100%;
        border-radius: 10px;
        width: 90%;
        box-shadow: 0px 3px 6px #00000029;
        max-height: 170px;
    }
}
 .profesor{ 
    padding: 20px 0px;
    .name{
        font-weight: 700;
        font-size: 18px;
        color: var(--colorSecondary);
        text-wrap: wrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        text-align: left
    }
    .city{
        font-weight: 400;
        font-size: 14px;
        color: var(--colorSecondary);
        padding: 0;
        text-wrap: wrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        text-align: left
    }
    .professions{
        font-weight: 400;
        font-size: 14px;
        color: #B8C1DB;
        padding: 0;
        text-wrap: wrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        text-align: left
    }
    p.average{
        font-family: 'Proxima Nova';
        font-weight: 500;
        font-size: 17px;
        color: #FEB230;
        padding: 0px 5px;
    }
    .count{
        font-family: 'Proxima Nova';
        font-weight: 500;
        font-size: 17px;
        color: #697891;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
    }
    .banner{
        max-width: 100%;
        object-fit: cover;
        border-radius: 15px;
        height: 170px;
    }
    .perfil{
        object-fit: cover;
        height: 102px;
        width: 102px;
        border-radius: 50%;
    }
}

.average{
    img{
        height: 15px;
    }
}
</style>