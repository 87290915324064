<template>
    <div class="container-fluid usuario-sesion">
        <Navbar class="d-lg-none"/>
        <div class="row justify-content-center">
            <div class="col-11 col-lg-10 d-flex flex-column justify-content-center align-items-center px-0 ">
                <MasterclassTitulo class="d-none d-lg-flex my-5" :ocultar="true"/>
                <TituloSinResultados :titulo="$t('message.withoutResults')" :subtitulo="$t('message.sorryWithoutResults')" :desaparecerSubtitulo="true"/>
                <Buscador class="z-index-home" :center="true"/>
                <Servicios class="z-index-home" :center="true" :sinResultados="false"/>
                <VolverPagina :titulo="$t('message.goMainPage')"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import Navbar from '../../components/Navs/Navbar.vue';
import Buscador from "../../components/Buscador/Buscador.vue";
import Servicios from "../../components/Servicios/Servicios.vue";
import VolverPagina from "../../components/Volver/VolverPagina.vue";
import TituloSinResultados from "../../components/Titulo/TituloSinResultados.vue";
import MasterclassTitulo from '../../components/Titulo/MasterclassTitulo.vue'
import { useRoute, useRouter } from 'vue-router';
import { onMounted } from 'vue';
import axios from 'axios';

const route = useRoute();
const router = useRouter()

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
    useHeadTags({ slug: route.fullPath.slice(1) })
})

</script>

<style lang="scss" scoped>
.max__width__login__Desk{
    @media (min-width: 992px) {
        max-width: 30%;
    }
    @media (min-width: 1200px) {
        max-width: 28%;
    }
}
</style>