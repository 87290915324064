<template>
    <div class="container-fluid">
        <NavbarPlus/>
        <div class="row justify-content-center margin-top">
            <div class="col-11 col-lg-10 d-flex flex-column justify-content-center align-items-center px-0">
                <TituloYDescripcionPlanes/>
                <PlanesPlusComponente/>
                <DetallesPlanes/>
                <!-- <PuntosExplicativos/> -->
                <!-- <CategoriasListadoPlanes :titulo="$t('message.titleCategoriesListPlans')"/> -->
                <PreguntasFrecuentesPlanes :titulo="$t('message.FAQ')"/>
                <ContactarPlanes/>
            </div>
        </div>
        <div class="espacio"></div>
        <FooterPlus :activeCarrito="true" class="d-lg-none"/>
        <FooterHomeV2 class="d-lg-none"/>
        <div class="espacio"></div>
    </div>
</template>

<script setup>
import { ref, inject, provide, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import FooterHomeV2 from "../../components/Footer/FooterHomeV2.vue";
import NavbarPlus from "../../components/Navs/NavbarPlus.vue";
import FooterPlus from "../../components/Footer/FooterPlus.vue";
import PuntosExplicativos from "../../components/Asesorias/PuntosExplicativos.vue";
import PreguntasFrecuentesPlanes from "../../components/CarritoPlus/PreguntasFrecuentesPlanes.vue";
import ContactarPlanes from "../../components/CarritoPlus/ContactarPlanes.vue";
import PlanesPlusComponente from "../../components/CarritoPlus/PlanesPlusComponente.vue";
import TituloYDescripcionPlanes from "../../components/Titulo/TituloYDescripcionPlanes.vue";
import DetallesPlanes from "../../components/CarritoPlus/DetallesPlanes.vue";
import CategoriasListadoPlanes from "../../components/Categorias/CategoriasListadoPlanes.vue";
import axios from "axios";


const route = useRoute();
const router = useRouter();

const exchangeRates = {
  USD: 1,      // Dólar como base
  MNX: 1,   // Euro
}
// Moneda seleccionada por el usuario
const selectedCurrency = ref("USD");

// Función para convertir el precio en la moneda seleccionada
const convertPrice = (price) => {
  return price * (exchangeRates[selectedCurrency.value] || 1);
};

// Formatear el precio
const formatCurrency = (amount, currency) => {
  if (!currency) {
    console.warn("Currency code is missing, defaulting to 'USD'.");
    currency = "USD"; // Valor predeterminado en caso de error
  }

  return new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: currency,
  }).format(amount);
};

provide('exchangeRates', exchangeRates)
provide('convertPrice', convertPrice)
provide('formatCurrency', formatCurrency)
provide('selectedCurrency', selectedCurrency)

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
    useHeadTags({ slug: route.fullPath.slice(1) })
})

</script>

<style lang="scss" scoped>

p{
    font-weight: 500;
    font-size: 16px;
    color: #697891;
    padding: 10px 0px;
    margin-bottom: 30px;
}
.espacio{
    padding: 6rem 0 0 0;
}
.max-widthLG{
    @media (min-width: 992px) {
        max-width: 32vw;
    }
}

@media (min-width: 992px) {
    .margin-top{
        margin-top: 105px;
    }
}

</style>