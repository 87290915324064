<template>
    <div class="col-12 d-flex justify-content-start align-items-center py-2 px-0">
        <div class="row justify-content-center align-items-center row__width">
            <!-- Falta info en profesional para llenar esto dinamico -->
            <div class="col-12 d-flex justify-content-start align-items-center vendido pe-0 d-none">
                <img src="../../img/home-icons/cursos-icons/cursos-mas-vendidos-icon.svg" alt="">
                <a href="">{{ $t('message.bestSellingCourse') }}</a>
                <p class="rate">| 8,545 {{ $t('message.sales') }} |</p>
                <div class="row justify-content-center ms-2 d-none d-lg-flex">
                    <div class="col d-flex justify-content-start align-items-center py-2 rate px-lg-0">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate-sin-calificar.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate-sin-calificar.svg" alt="">
                        <img src="../../img/home-icons/cursos-icons/rate-sin-calificar.svg" alt="">
                        <p class=".h6">(12k {{ $t('message.reviews') }})</p>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-start align-items-center py-2 rate px-lg-0 d-lg-none" v-if="professional && professional.reviews && professional.reviews.average > 0">
                <img v-for="star in professional.reviews.average" :key="star" src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                <img v-for="star in 5 - professional.reviews.average" :key="star" src="../../img/home-icons/cursos-icons/rate-sin-calificar.svg" alt="">
                <p class=".h6" v-if="professional.reviews.total > 0">({{ professional.reviews.total }} {{ $t('message.reviews') }})</p>
            </div>
            <div class="col-12 d-flex justify-content-start align-items-center py-2 rate px-lg-0 d-lg-none" v-else>
                <img src="../../img/home-icons/cursos-icons/rate.svg" alt="">
                <p class=".h6">{{ $t('message.new') }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';

let professional = inject('professional')

console.log(professional.value, "PROFESIONAL VALUE")
</script>

<style lang="scss" scoped>
.vendido{
    img{
        padding-right: 10px;
    }
}
.rate{
    img{
        padding-right: 5px;
        height: 20px;
    }
}
a{
    font-weight: 600;
    font-size: 15px;
    color: var(--colorPrimary);
}
.rate{
    font-weight: 400;
    font-size: 15px;
    color: #B8C1DB;
    padding: 0px 5px;
}
.h6{
    font-weight: 500;
    font-size: 15px;
    color: #B8C1DB;
    padding: 0px 5px;
}
</style>