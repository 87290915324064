<template>
    <div class="row justify-content-center row__width">
        <div class="col-12 d-flex justify-content-start align-items-center panel">
            <div class="d-flex flex-column justify-content-center align-items-start contenedor_panel">
                <p class="premium">{{$t('message.premiumAccess')}}</p>
                <p class="joinMc">{{$t('message.joinMCV2')}}</p>
                <router-link to="/planes-plus">
                    {{$t('message.bePremium')}}
                </router-link>
            </div>
            <img src="../../img/home-icons/panel-img/1.svg" alt="">
        </div>
    </div>
</template>

<script setup>
	import { useGeneralStore } from "../../stores/general"
    import { storeToRefs } from "pinia";

    const general = useGeneralStore();
    const {textos} = storeToRefs(general);
    const {} = general;
</script>

<style lang="scss" scoped>
.panel{
    background-color: #F5F5F5;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    .premium{
        font-size: 22px;
        font-weight: 700;
        color: var(--colorSecondary);
        text-align: left;
    }
    .joinMc{
        font-size: 13px;
        font-weight: 400;
        color: #697891;
        padding: 10px 0px;
        line-height: 22px;
        text-align: left;
    }
    a{
        border: 2px solid var(--colorPrimary);
        color: var(--colorPrimary);
        font-size: 14px;
        font-weight: 600;
        min-height: 50px;
        position: relative;
        text-decoration: none;
        border-radius: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        &::before{
            content: '';
            background-image: url('../../img/home-icons/premium-before.svg');
            background-repeat: no-repeat;
            position: absolute;
            width: 32px;
            height: 25px;
            display: block;
            left: -10%;
            top: -16%;
        }
    }
    img{
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 20px;
    }
    .contenedor_panel{
        max-width: 70%;
    }
    @media (min-width: 992px) {
        height: 200px;
        padding: 30px;
        h2{
            font-size: 35px;
        }
        h3{
            font-size: 15px;
            padding-bottom: 5%;
        }
        a{
            width: 60%;
            font-size: 16px;
            margin-left: 5%;
            &::before{
                left: -15%;
                top: -35%;
                width: 30%;
                height: 100%;
                background-size: 100% 100%;
            }
        }
        img{
            min-width: 20%;
        }
    }
    @media (min-width: 1775px) {
        img{
            min-width: 18%;
        }
    }
}
.row__width{
    @media (min-width: 992px) {
        padding: 5% 0 2% 0;
        margin-top: 6vh;
    }
}
</style>