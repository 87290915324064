<template>
    <div class="row unete" :class="{'d-none': !show}">
        <div class="col-12 d-flex justify-content-center justify-content-lg-between align-items-center position-relative">
            <p>{{$t('message.premiumAccessV2')}}</p>
            <!--- https://www.expophotomasterclass.com/membresia --->
            <router-link to="/planes-plus" class="btn__primarioV2">
                {{$t('message.joinNow')}}
            </router-link>
            <button class="close-btn" @click="show = !show"><img src="../../img/modales-icon/cerrar.svg" alt=""></button>
        </div>
    </div>
    <div class="row show-btn" :class="{'d-none': show}">
        <div class="col-auto d-flex justify-content-center align-items-center ms-auto">
            <button class="btn__primarioV2 px-3" @click="show = !show">
                {{$t('message.bePremium')}}
            </button>
        </div>
    </div>
</template>

<script setup>
import BTNPrimario from "../Botones/Primario.vue";
import { useGeneralStore } from "../../stores/general"
import { storeToRefs } from "pinia";
import { useRouter } from 'vue-router';
import { ref } from 'vue';

const router = useRouter()

const show = ref(true)

const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;
</script>

<style lang="scss" scoped>
.unete {
    width: 100%;
    background-color: #F5F5F5;
    box-shadow: 0px 3px 6px #00000029;
    min-height: 50px;
    position: fixed;
    bottom: 95px;
    padding: 10px;
    z-index: 99;
    p {
        font-weight: 700;
        font-size: 16px;
        color: var(--colorSecondary);
        min-width: 60%;
        padding-right: 15px;
    }
    .close-btn {
        background-color: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
        height: 25px;
        min-width: 25px;
        border-radius: 50%;
        cursor: pointer;
        padding: 0;
        margin-left: 15px;
        z-index: 3;
        position: absolute;
        top: -5px;
        right: 95%;
        transition: all 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            height: 8px
        }
    }
    .btn__primario{
        font-size: 14px;
        width: 30vw;
    }
    @media (min-width: 992px) {
        width: 39vw;
        max-width: 600px;
        right: 0;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        transition: all 0.2s;
        .close-button{
            top: -25px;
            right: 100%;
        }
        h5{
            padding-left: 25px;
        }
        .btn__primario{
            font-size: 16px;
            width: 12vw;
        }
    }
}
.show-btn {
    position: fixed;
    bottom: 95px;
    right: 0;
    background-color: transparent;
    border-color: #fff;
    padding: 10px;
    z-index: 99;
    .btn {
        background-color: var(--colorSecondary);
        color: #fff;
        border-radius: 10px;
        border: 2px solid transparent;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
            border: 2px solid var(--colorSecondary);
            background-color: #fff;
            color: var(--colorSecondary);
        }
    }
}
</style>

