<template>
  <div class="row filter_page row__width">
    <div class="col-12 d-flex flex-column justify-content-center align-items-start " v-if="information_filter.description">
        <h2>
          <template v-if="information_filter.description_title">{{ information_filter.description_title }}</template>
          <template v-else>Descubre más</template>
        </h2>
        <div v-if="information_filter.description" class="html2" v-html="information_filter.description"></div>
    </div>
    <div class="col-12 d-flex flex-column justify-content-center align-items-start mt-5" v-if="information_filter.faqs">
      <h2>Preguntas frecuentes</h2>
    </div>
    <PreguntasFrecuentesFiltros/>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import PreguntasFrecuentesFiltros from '../Cursos/PreguntasFrecuentesFiltros.vue';
let information_filter = inject('information_filter')
</script>

<style scoped>
.filter_page{
  margin: 40px;
  h2{
    color: var(--colorSecondary);
    padding-bottom: 30px;
    text-align: left;
  }
  
}
</style>
