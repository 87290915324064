<template>
    <a :title="props.titulo" v-if="props.route.includes('http')" :href="props.route" target="_blank" class="col-4 col-lg d-flex flex-column justify-content-center align-items-center max-width px-0">
        <input type="radio" :id="props.id" name="servicios" :checked="props.check">
        <span :for="props.id">
            <img :src="props.src" :alt="props.alt">
        </span>
        <p>{{titulo}}</p>
    </a>
    <a :title="props.titulo" v-else @click="router.push({name: props.route})" class="col-4 col-lg d-flex flex-column justify-content-center align-items-center max-width px-0">
        <input type="radio" :id="props.id" name="servicios" :checked="props.check">
        <span :for="props.id">
            <img :src="props.src" :alt="props.alt">
        </span>
        <p>{{titulo}}</p>
    </a>
</template>

<script setup>
    import { useRouter } from "vue-router";

    const router = useRouter()

    const props = defineProps({
        id: '',
        src: '',
        titulo: '',
        route: '',
        check: Boolean,
        alt:''
    })
</script>

<style lang="scss" scoped>
a{
    text-align: center;
    text-decoration: none;
    margin: 5px 0px;
    cursor: pointer;
}
input[type=radio]{
    display: none;
}
input[type=radio] + span{
    height: 105px;
    width: 105px;
    overflow: hidden;
    display: flex;
    justify-content: center;     
    align-items: center;
    background-color: #F2F2F2;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);  
    border-radius: 50%; 
    position: relative;
    cursor: pointer;
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    @media (max-width: 992px) {
        width: 90px;
        height: 90px;
    }
    @media (min-width: 1920px) {
        width: 120px;
        height: 120px;
    }
}
input[type=radio]:checked + span{
    background-color: rgba(242, 242, 242, 0.5);
}
input[type=radio]:checked + span::before{
    content: '';
    position: absolute;
    border-radius: 50%;
    border: 3px solid var(--colorPrimary);
    height: 100%;
    width: 100%;
}
input[type=radio]:checked ~ h3{
    color: var(--colorSecondary);
    font-weight: 700;
    @media (min-width: 992px) {
        font-size: 18px;
        color: var(--colorSecondary);
    }
}
p{
    color: #697891;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    @media (min-width: 1200px) {
        font-size: 18px;
        color: var(--colorSecondary);
        margin-top: 5vh;
    }
}
@media (min-width: 992px) {
    .max-width{
        max-width: 12%;
    }
}
</style>