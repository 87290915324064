<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center valoraciones">
        <div class="row justify-content-center row__width">
            <div class="col-12 col-lg-3 d-flex flex-column justify-content-center align-items-center">
                <p class="total_score"><img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="" class="big_start">{{ totalScore }}</p>
                <div class="d-flex justify-content-center align-items-center pb-3">
                    <!-- Mostrar estrellas en función de los datos obtenidos -->
                    <!-- <template v-if="rates">
                        <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="" v-for="(rate, id) in rates" :key="id">
                    </template>
                    <template v-else-if="!rates">
                        <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="" v-for="(rate, id) in noRate" :key="id">
                    </template> -->
                </div>
                <p class="title">Rating del curso</p>
            </div>
            <div class="col-12 col-lg-9 d-flex flex-column justify-content-center align-items-center px-0 ps-lg-2 pe-lg-0">
                <div class="row justify-content-between align-items-center row__width" v-for="(progreso, id) in progresos" :key="id">
                    <div class="col col-11 d-flex justify-content-start align-items-center px-0">
                        <img src="../../img/home-icons/cursos-icons/rateV2.svg" alt="" class="img pe-2">
                        <p class="pe-3">{{  5 - id }}</p>
                        <div class="progress w-100">
                            <div class="progress-bar" role="progressbar" :style="`width:` + progreso.progreso + `%`" style="height: 100%; border-radius: 50px;" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                    <div class="col-1 d-flex justify-content-end align-items-center px-0">
                        <p>{{progreso.titulo}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue'
import axios from 'axios'
import { useUsersStore } from "../../stores/user";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from 'vue-router';

const users = useUsersStore();
const {cambioHeader} = storeToRefs(users);
const { url } = users

const router = useRouter();
const route = useRoute()

const progresos = inject('progresos')
const professional = inject('professional')
const rates = inject('rates')
const noRate = inject('noRate')
const totalScore = inject('totalScore')


</script>


<style lang="scss" scoped>
.valoraciones{
    .total_score{
        font-weight: 700;
        font-size: 50px;
        color: var(--colorSecondary);
        padding: 5px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .title{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
    }
    .img{
        padding: 0 2px;
        height: 15px;
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: #B8C1DB;
    }
    .progress{
        height: 10px;
    }
    @media (min-width: 992px) {
        margin: 20px 0 50px 0;
        h2{
            color: #374557;
        }
    }
}
.big_start{
    height: 40px;
    padding-right: 10px !important;
}
</style>