<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center div_blanco py-2 mb-4 mb-lg-0">
        <div class="collapse-content  mt-2">
            <div class="row justify-content-center row__width mx-auto px-2 px-lg-0">
                <div class="col-12 col-lg-11 d-flex justify-content-center align-items-center px-0">
                    <div class="row justify-content-center row__width">
                       <template v-if="product.payments">
                         <!--- PAGO checks--->
                         <div class="col-12 d-flex justify-content-start align-items-center px-0" v-for="(price, index) in product.payments" :key="index">
                            <div v-if="price.payment_type == 'one_time'" class="w-100 mb-2">
                                <input type="radio" :id="'index'+price.id" :value="price" v-model="product.selectedOption" :disabled="product.acquired" 
                                name="payment_radio" class="d-none">
                                <label :for="'index'+price.id" @click="selectOption(price)" v-if="price.price >= 1">
                                    <div class="span"></div>
                                    <div class="padding row justify-content-center">
                                        <div class="col-12 d-flex justify-content-between align-items-center" v-if="price.discount == 0">
                                            <p class="price">{{ price.coin.abbr }} ${{ price.showPrice }}</p>
                                        </div>
                                        <div class="col-12 d-flex justify-content-between align-items-center" v-else>
                                            <p class="price">{{ price.coin.abbr }} ${{ price.realPrice }}</p>
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-center" v-if="product.selectedOption == price && price.discount > 0">
                                            <p class="me-2 full-price-discount">{{ price.coin.abbr }} ${{ price.showPrice }}</p>
                                            <p class="discount">{{ price.discount }}% DTO.</p>
                                        </div>
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <p>{{ price.title }}</p>
                                        </div>
                                    </div>
                                </label>
                                <label :for="'index'+price.id" @click="selectOption(price)" v-else>
                                    <div class="span"></div>
                                    <div class="padding row justify-content-center">
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <p class="price">GRATIS</p>
                                        </div>
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <p>{{ price.title }}</p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div v-if="price.payment_type == 'partial'" class="w-100 mb-2">
                                <input type="radio" :id="'index'+price.id" :value="price" v-model="product.selectedOption" :disabled="product.acquired" name="payment_radio" class="d-none">
                                <label :for="'index'+price.id" @click="selectOption(price)">
                                    <div class="span"></div>
                                    <div class="padding row justify-content-center">
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <p class="price">{{ price.coin.abbr }} ${{ price.price }}</p>
                                        </div>
                                        <div class="col-12 d-flex justify-content-between align-items-center">
                                            <p>{{ price.title }}</p>
                                        </div>
                                        <div class="col-12 d-flex justify-content-start align-items-center" v-if="product.selectedOption == price">
                                            <p class="me-1">{{ $t('message.then') }}</p>
                                            <p class="discount me-1" style="margin-top: 1px;">{{ price.payments - 1 }} {{ price.payments == 2 ? $t('message.payment') : $t('message.payments') }}</p>
                                            <p class="me-1">{{ $t('message.of') }}</p>
                                            <p class="discount" style="margin-top: 1px;">{{ price.coin.abbr }} ${{ price.price_per_payment }}</p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div v-else-if="price.payment_type == 'suscripción'" class="w-100 mb-2">
                                <div class="col-12 d-flex justify-content-start align-items-center px-0">
                                    <input type="radio" :id="'button'+price.id" name="payment_radio">
                                    <label :for="'button'+price.id">
                                        <div class="span"></div>
                                        <div class="padding row justify-content-center">
                                            <div class="col-12 d-flex justify-content-center align-items-center position-relative px-0 pb-2">
                                                <div class="row justify-content-between row__width">
                                                    <div class="col d-flex justify-content-start align-items-center">
                                                        <p class="price">{{ coinSelected }} ${{priceMember}}</p>
                                                    </div>
                                                    <div class="col-5 d-flex justify-content-center align-items-center px-0 d-lg-none">
                                                        <span>50% Dto.</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 d-flex justify-content-between align-items-center pe-0">
                                                <p class="descuento">Con tu membresía, obtén acceso a este descuento y otros beneficios exclusivos.</p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <!----->
                       </template>
                        <div class="col-12 d-flex justify-content-center align-items-center cupos d-none"> <!-- d-none d-lg-flex -->
                            <div class="row justify-content-center row__width">
                                <div class="col-6 d-flex justify-content-start align-items-center px-0">
                                    <p v-if="seats != null">{{ seats }} {{ $t('message.availableSeats') }} </p>
                                </div>
                                <div class="col-6 d-flex justify-content-end align-items-center px-0 fotos" v-if="(!participants)">
                                    <img src="../../img/home-icons/testimonios-1.svg" alt="" class="">
                                    <img src="../../img/home-icons/testimonios-1.svg" alt="" class="">
                                    <img src="../../img/home-icons/testimonios-1.svg" alt="" class="">
                                    <img src="../../img/home-icons/testimonios-1.svg" alt="" class="">
                                    <div class="div">+9</div>
                                </div>
                            </div>
                        </div>
                        <div class="px-0 mt-2" v-if="!product.acquired && product.payments">
                            <!--- GRATIS Boton --->
                            <div class="col-12 d-flex justify-content-center align-items-center px-0" v-if="product.selectedOption && product.selectedOption.price == 0">
                                <button type="button" @click="getFreeCourse()" class="btn__primarioV2" :class="{'disabled' : !product.selectedOption}">Adquirir</button>
                            </div>
                            <!--- PAGO one_time --->
                            <div class="px-0 d-flex flex-column justify-content-center align-items-center pt-3 pt-lg-0" v-else-if="product.selectedOption && product.selectedOption.payment_type == 'one_time'">
                                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                    <div class="row justify-content-center row__width pb-2">
                                        <div class="col-12 d-flex justify-content-start justify-content-lg-center align-items-center px-0">
                                            <button type="button" class="mostrar_mas justify-content-center align-items-center" 
                                            @click="addtoCart(product)" :class="{'disabled' : !product.selectedOption}">
                                                {{ $t('message.addCart') }}
                                            </button>
                                        </div>
                                        <div class="col d-flex justify-content-center align-items-center button pe-0 d-none">
                                            <button class="btn_none fav">
                                                <img src="../../img/home-icons/cursos-icons/fav.svg" alt="">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-12  d-flex  justify-content-end justify-content-lg-center align-items-center px-0">
                                    <router-link :to="{ name: 'Carrito', params: { step: idUser ? 4 : 2 + '' } }" class="btn__primarioV2" @click="addtoCart(product)" :class="{'disabled' : !product.selectedOption}">Comprar ahora</router-link>
                                </div>
                            </div>
                            <!--- PAGO partial --->
                            <div class="px-0" v-else-if="product.selectedOption && product.selectedOption.payment_type == 'partial'">
                                <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                    <router-link :to="{ name: 'Carrito', params: { step: idUser ? 4 : 2 + '' } }" class="btn__primarioV2" @click="addtoCart(product)" :class="{'disabled' : !product.selectedOption}">Comprar ahora</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="px-0" v-else>
                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                <button type="button" @click="getFreeCourse()" class="btn__primarioV2 disabled">Ya adquirido</button>
                            </div>
                        </div>
                        <!--  -->
                        <div class="col-12 col-lg-11 d-flex justify-content-start align-items-center descripcion py-4 px-0">
                            <p class="information-course">Beneficios:</p>
                        </div>
                        <div class="col-12 col-lg-11 d-flex flex-column justify-content-center align-items-start px-0">
                            <div class="row__width">
                                <div id="filtro6" class="row justify-content-center">
                                    <div class="col-12 d-flex justify-content-start align-items-start seccion">
                                        <img src="../../img/home-icons/cursos-icons/clases-black.svg" alt="">
                                        <p v-if="product.type" >Modalidad: {{ product.type.name }}</p>
                                    </div>
                                    <div class="col-12 d-flex justify-content-start align-items-start seccion" v-if="product.totalTimeVideo">
                                        <img src="../../img/home-icons/cursos-icons/time.svg" alt="">
                                        <p v-if="product.totalTimeVideo[1] > 30">Duración: {{ product.totalTimeVideo[0]+1 }} horas</p>
                                        <p v-else-if="product.totalTimeVideo[0] > 1">Duración: {{ product.totalTimeVideo[0] }} horas</p>
                                        <p v-else>Duración: {{ product.totalTimeVideo[0] }} hora</p>
                                    </div>
                                    <div class="col-12 d-flex justify-content-start align-items-start seccion" v-if="product.certificates.id">
                                        <img src="../../img/home-icons/cursos-icons/crown.svg" alt="">
                                        <p>Con certificado al terminar</p>
                                    </div>
                                    <div class="col-12 d-flex justify-content-start align-items-start seccion">
                                        <img src="../../img/home-icons/cursos-icons/calendar-negro.svg" alt="">
                                        <p>Accesso ilimitado e inmediato</p>
                                    </div>
                                    <div class="col-12 d-flex justify-content-start align-items-start seccion" v-if="product.details.show_portfolio">
                                        <img src="../../img/home-icons/cursos-icons/galeria.svg" alt="">
                                        <p>Revisión de portafolio</p>
                                    </div>
                                </div> 
                                <div id="desaparecer6" > <!--- class="d-none" -->
                                    <div class="col-12 d-flex justify-content-start align-items-start seccion">
                                        <img src="../../img/home-icons/cursos-icons/ojo.svg" alt="">
                                        <p>Seguimiento de tu trabajo</p>
                                    </div>
                                    <div class="col-12 d-flex justify-content-start align-items-start seccion" v-if="product.totalResources || product.downloadables">
                                        <img src="../../img/home-icons/cursos-icons/paper-black.svg" alt="">
                                        <p v-if="product.downloadables && product.downloadables.quantity">{{ product.downloadables.quantity }} recursos descargables</p>
                                        <p v-if="product.totalResources">{{ product.totalResources }} recursos descargables</p>
                                    </div>
                                </div>
                            </div>
                            <!-- <BTNVerMas :funcionTextoVerMas="mostrarTexto6" :img="iconoImg"/> -->
                        </div>
                        <div class="col-11 col-lg-11 d-flex justify-content-center align-items-center py-2 px-0 d-none" v-if="offer != 0">
                            <p class="offerEnds">{{$t('message.offerEndsIn')}}</p>
                        </div>
                        <div class="col-11 col-lg-11 d-flex justify-content-center align-items-center tiempo px-0 d-none" v-if="offer != 0">
                            <div class="row justify-content-center row__width">
                                <div class="col-1 d-flex justify-content-center align-items-start">
                                    <img src="../../img/home-icons/cursos-icons/llama.svg" alt="">
                                </div>
                                <div class="col-7 d-flex flex-column justify-content-center align-items-center">
                                    <p>{{$t('message.discountTime')}}</p>
                                    <div class="d-flex justify-content-center align-items-center pt-1">
                                        <p class="time">{{$t('message.days')}}</p>
                                        <p class="time">{{$t('message.hours')}}</p>
                                        <p class="time">{{$t('message.minutes')}}</p>
                                        <p class="time">{{$t('message.seconds')}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import BTNPrimarioV2 from "../Botones/PrimarioV2.vue";
import BTNVerMas from "../Botones/VerMas.vue";
import { ref, inject, computed, onMounted, onUnmounted, onBeforeMounted } from 'vue';
import { storeToRefs } from "pinia"
import { useCartStore } from "../../stores/cart"
import { useUsersStore } from "../../stores/user";
import { useConfigStore } from "../../stores/config"

const carts = useCartStore();
const {  } = storeToRefs(carts);
const { addtoCart } = carts

const users = useUsersStore();
const { idUser } = storeToRefs(users);

const config = useConfigStore();
const { coinSelected } = storeToRefs(config);

const props = defineProps({
    price: '',
    priceMember: '',
    modality: '',
    seats: '',
    participants: '',
    offer: ''
})

let product = inject('product')
let getFreeCourse = inject('getFreeCourse')

const iconoImg = ref(true)

const collapsed = ref(true);
const isLargeScreen = ref(window.innerWidth >= 992);

const checkScreenSize = () => {
  isLargeScreen.value = window.innerWidth >= 992;
  if (isLargeScreen.value) {
    collapsed.value = true;
  }
};

const mostrarTexto6 = () => {
    document.getElementById("desaparecer6").classList.toggle("d-none");
    document.getElementById("filtro6").classList.toggle("before");
    if(iconoImg.value == false){
        iconoImg.value =true
    }else{
        iconoImg.value =false
    }
}

const selectOption = (price) => {
    if(price){
        if(!product.value.acquired){
            product.value.selectedOption = price.id
        }
    }
}

onMounted(() => {
    product.value.selectedOption = product.value.payments[0]
    window.addEventListener('resize', checkScreenSize);
    checkScreenSize(); // Inicializar valor correcto al montar
})

onUnmounted(() => {
    window.removeEventListener('resize', checkScreenSize);
});

selectOption(product.value.payments[0])

</script>


<style lang="scss" scoped>
.collapse-content {
    width: 100%;
  max-height: 1000px; /* Valor ajustable */
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.expand-collapse-enter-active,
.expand-collapse-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.expand-collapse-enter,
.expand-collapse-leave-to {
  max-height: 0;
}

.button-collapse {
  padding: 10px;
  border: 0;

  img {
    height: 15px;
    transition: transform 0.3s ease;
  }

  /* Clase para rotar el ícono 180 grados */
  .rotated {
    transform: rotate(180deg);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}


.div_blanco{
    box-shadow: 0px 3px 15px #00000029;
    border-radius: 20px;
    min-height: 20px;
    padding: 10px;
    background-color: #fff;
    // @media (max-width: 992px) {
    //     position: fixed;
    //     bottom: 85px;
    //     border-radius: 20px 20px 0 0;
    // }
    input{
        display: none;
    }
    label{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 10px;
        cursor: pointer;
        .span{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 2px solid #E4E4E4;
            min-width: 28px;
            min-height: 28px;
            left: 0;
            &::after{
                content: '';
                position: absolute;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                width: 14px;
                height: 14px;
                display: none;
            }
        }
    }
    input:checked + label .span{
        border-color: var(--colorPrimary);
        &::after{
            display: block;
        }
    }
    input:checked + label{
        border: 2px solid var(--colorPrimary);
        border-radius: 20px;
        box-shadow: 0px 3px 6px #00000029;
    }
    .price{
        font-weight: 700;
        font-size: 22px;
        color: var(--colorSecondary);
        padding: 0;
    }
    .descuento, p{
        color: #697891;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
    }
    .upper{
        text-transform: uppercase;
    }
    .padding{
        padding-left: 3%;
    }
    span{
        background-color: var(--colorSecondary);
        border-radius: 26px;
        height: 30px;
        color: var(--bgPrincipal);
        font-weight: 600;
        font-size: 16px;
        min-width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 5%;
        top: 0;
    }
    .button{
        max-width: 70px;
    }
    .btn_none{
        border: 0;
        background-color: transparent;
        outline: 0;
        padding: 0;
        &:focus{
            outline: 0;
        }
    }
    .fav{
        background-color: var(--bgPrincipal);
        border: 2px solid var(--colorSecondary);
        border-radius: 26px;
        outline: 0;
        height: 53px;
        width: 53px;
    }
    .ver_mas{
        font-weight: 700;
        font-size: 16px;
        color: var(--colorPrimary);
        border: 0;
        background-color: transparent;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        img{
            padding-left: 10px;
        }
    }
    .seccion{
        padding-bottom: 10px;
        p{
            font-weight: 400;
            font-size: 16px;
            color: #697891;
        }
        img{
            padding-right: 10px;
        }
    }
    .descripcion{
        .information-course{
            font-weight: 600;
            font-size: 20px;
            color: #374557;
        }
    }
    .offerEnds{
        font-weight: 500;
        font-size: 16px;
        color: #697891;
        padding: 10px 0px;
    }
    .tiempo{
        background-color: #F5F5F5;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 35px;
        padding: 15px;
        margin-bottom: 20px;
        p{
            font-weight: 500;
            font-size: 20px;
            color: var(--colorSecondary);
            padding: 0;
        }
        .time{
            font-family: 'Proxima Nova';
            font-weight: 400;
            font-size: 15px;
            color: var(--colorSecondary);
            padding-left: 0;
            padding-right: 8px;
        }
    }
    .cupos{
        padding: 20px 0px;
        p{
            font-weight: 700;
            font-size: 16px;
            color: var(--colorPrimary);
            text-align: left;
        }
        img{
            height: 32px;
            border-radius: 50%;
        }
        .fotos{
            max-width: 80%;
            overflow: hidden;
        }
        .div{
            border: 2px solid var(--bgPrincipal);
            background-color: var(--colorSecondary);
            border: 2px solid var(--bgPrincipal);
            border-radius: 50px;
            min-width: 32px;
            min-height: 32px;
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    
    @media (min-width: 992px) {
        padding: 30px;
        label{
            box-shadow: 0px 3px 6px #00000029;
            border: 2px solid #B8C1DB5E;
            border-radius: 20px;
            align-items: center !important;
        }
        span{
            display: none;
        }
        .offerEnds{
            font-weight: 400;
            font-size: 14px;
            color: #B8C1DB;
        }
        .descripcion{
            .information-course{
                font-weight: 600;
                font-size: 20px;
                color: #374557;
                text-align: left;
            }
        }
    }
    .mostrar_mas{
        height: 50px;
        background-color: var(--bgPrincipal);
        border: 2px solid var(--colorSecondary);
        border-radius: 26px;
        width: 100%;
        font-weight: 700;
        font-size: 16px;
        color: var(--colorSecondary);
        img{
            padding: 0px 5px;
        }
    }
}
.mostrar_mas{
    position: relative;
    height: 50px;
    background-color: var(--bgPrincipal);
    border: 2px solid var(--colorSecondary);
    border-radius: 26px;
    width: 100%;
    color: var(--colorSecondary);
    font-weight: 700;
    font-size: 16px;
}
.btn__primarioV2{
    background-color: var(--colorPrimary);
    color: var(--bgPrincipal);
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border-radius: 27px;
    width: 100%;
    text-decoration: none;
    min-height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    &:hover{
        background-color: var(--bgPrincipal);
        color: var(--colorPrimary);
        border: 2px solid var(--hoverBTNPrimario);
        outline: 0;
    }
    &:focus{
        background-color: var(--bgPrincipal);
        color: var(--colorPrimary);
        border: 2px solid var(--hoverBTNPrimario);
        outline: 0;
    }
    &:active{
        outline: 0;
    }
}
.disabled{
    pointer-events: none;
    cursor: default;
    opacity: 0.7;
}
.full-price-discount{
    color: var(--colorRed) !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    text-decoration: line-through !important;
}
.discount{
    color: var(--grisPrimario) !important;
    font-weight: 700 !important;
    font-size: 13px !important;
}
.button-collapse{
    padding: 0;
    border: 0;
    img{
        height: 15px;
    }
    margin-top: 4px;
}

.button{
    border: 0;
    background-color: transparent;
    outline: 0;
    border-radius: 50%;
    border: 2px solid var(--colorSecondary);
    height: 50px;
    width: 50px;
    margin-right: 7px;
}
</style>