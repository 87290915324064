<template>
    <div class="container-fluid d-flex justify-content-center justify-content-lg-between align-items-start">
        <div class="col d-flex flex-column justify-content-center align-items-center align-items-lg-start px-0 classSpecial" >
            <form class="row justify-content-center row__width contenedor">
                <div class="col-12 d-flex flex-column justify-content-center justify-content-lg-start align-items-center px-0 ">
                    <TituloAdminAjustes :title="$t('message.SEOTags')"/>
                    <div class="row justify-content-end row__width px-0 border-top">
                        <div class="col d-flex flex-column justify-content-start align-items-center px-0 contenido">
                            <div class="row justify-content-start align-items-center row__width px-0">
                                <div class="col-12 col-md-6 ps-2 d-flex justify-content-center align-items-start px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="slug"
                                        :placeholder="$t('message.type')"
                                        color="info"
                                        :options="slug_options"
                                        name="country"
                                        @update:model-value="slug == 'PAGE' ? fetchPages() : slug == 'PROFESSIONAL' ? fetchProfessionals() : type_class = ''; pages = []; search_query = ''"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end row__width px-0 mt-2" v-if="slug === 'PAGE'">
                        <div class="col d-flex flex-column justify-content-start align-items-center px-0 contenido">
                            <div class="row justify-content-end row__width px-0 mt-2">
                                <div class="col-12 col-lg d-flex justify-content-center align-items-start search-input px-0">
                                    <input type="text" :placeholder="$t('message.search')" v-model="search_query" @keyup.enter="searchPage(search_query)">
                                    <button type="button" @click="searchPage(search_query)">
                                        <img src="../../../../img/home-icons/lupa.svg" alt="">
                                    </button>
                                </div>
                            </div>
                            <div class="row justify-content-start align-items-center row__width px-0 mt-2">
                                <div class="col-12 col-md-6 ps-2 d-flex justify-content-center align-items-start px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="type"
                                        :placeholder="$t('message.type')"
                                        color="info"
                                        :options="type_options"
                                        name="country"
                                        @update:model-value="fetchPages()"
                                        search
                                        search-placeholder="Buscar en la lista"
                                    />
                                </div>
                                <div class="col-12 col-md-6 ps-2 d-flex justify-content-center align-items-start px-0 mt-2 mt-md-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="type_class"
                                        :placeholder="$t('message.class')"
                                        color="info"
                                        :options="class_options"
                                        name="country"
                                        @update:model-value="fetchPages()"
                                        search
                                        search-placeholder="Buscar en la lista" v-if="type === 'FILTER' || type === 'PRODUCT'"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end row__width px-0 mt-2" v-if="slug === 'PRODUCT'">
                        <div class="col d-flex flex-column justify-content-start align-items-center px-0 contenido">
                            <div class="row justify-content-end row__width px-0 mt-2">
                                <div class="col-12 col-lg d-flex justify-content-center align-items-start search-input px-0">
                                    <input type="text" :placeholder="$t('message.search')" v-model="search_query" @keyup.enter="searchProduct(search_query)">
                                    <button type="button" @click="searchProduct(search_query)">
                                        <img src="../../../../img/home-icons/lupa.svg" alt="">
                                    </button>
                                </div>
                            </div>
                            <div class="row justify-content-start align-items-center row__width px-0 mt-2">
                                <div class="col-12 col-md-6 ps-2 d-flex justify-content-center align-items-start px-0">
                                    <MazSelect
                                        class="row__width"
                                        v-model="type_class"
                                        :placeholder="$t('message.class')"
                                        color="info"
                                        :options="prod_classes"
                                        name="country"
                                        @update:model-value="fetchProductsClass(prod_classes.find(param => param.value === type_class).id)"
                                        search
                                        search-placeholder="Buscar en la lista" v-if="type === 'FILTER' || type === 'PRODUCT'"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-end row__width px-0 mt-2" v-if="slug === 'PROFESSIONAL'">
                        <div class="col d-flex flex-column justify-content-start align-items-center px-0 contenido">
                            <div class="row justify-content-end row__width px-0 mt-2">
                                <div class="col-12 col-lg d-flex justify-content-center align-items-start search-input px-0">
                                    <input type="text" :placeholder="$t('message.search')" v-model="search_query" @keyup.enter="searchProfessional(search_query)">
                                    <button type="button" @click="searchProfessional(search_query)">
                                        <img src="../../../../img/home-icons/lupa.svg" alt="">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center row__width px-0 mt-2" v-if="slug === 'PAGE'">
                        <div class="col-12 ps-2 d-flex justify-content-center align-items-start px-0">
                            <ul class="col-12 list-group" v-if="!isLoader">
                                <li class="list-group-item d-flex justify-content-start align-items-center text-start px-2" v-for="page in pages?.data" :key="page.id">
                                    <span class="col me-3">{{ page.title }}</span>
                                    <div class="col-2 justify-content-end align-items-center d-flex">
                                        <button type="button" class="btn btn-success" @click="edit_page = page; fetchTags(page.slug); page_id = 2">
                                            {{$t('message.edit')}}
                                        </button>
                                    </div>
                                </li>
                                <p v-if="!pages">{{$t('message.noData')}}</p>
                            </ul>
                            <div class="d-flex justify-content-center align-items-center" v-else>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center row__width px-0 mt-2" v-if="slug === 'PRODUCT'">
                        <div class="col-12 ps-2 d-flex justify-content-center align-items-start px-0">
                            <ul class="col-12 list-group" v-if="!isLoader">
                                <li class="list-group-item d-flex justify-content-start align-items-center text-start px-2" v-for="page in pages?.data" :key="page.id">
                                    <span class="col me-3">{{ page.name }}</span>
                                    <div class="col-2 justify-content-end align-items-center d-flex">
                                        <button type="button" class="btn btn-success" @click="edit_page = page; edit_page.class = type_class; edit_page.type = slug; fetchTags(page.slug); page_id = 2">
                                            {{$t('message.edit')}}
                                        </button>
                                    </div>
                                </li>
                                <p v-if="!pages">{{$t('message.noData')}}</p>
                            </ul>
                            <div class="d-flex justify-content-center align-items-center" v-else>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center row__width px-0 mt-2" v-if="slug === 'PROFESSIONAL'">
                        <div class="col-12 ps-2 d-flex justify-content-center align-items-start px-0">
                            <ul class="col-12 list-group" v-if="!isLoader">
                                <li class="list-group-item d-flex justify-content-start align-items-center text-start px-2" v-for="page in pages?.data" :key="page.id">
                                    <span class="col me-3">{{ page.name }}</span>
                                    <div class="col-2 justify-content-end align-items-center d-flex">
                                        <button type="button" class="btn btn-success" @click="edit_page = page; edit_page.type = slug; fetchTags(page.slug); page_id = 2">
                                            {{$t('message.edit')}}
                                        </button>
                                    </div>
                                </li>
                                <p v-if="!pages">{{$t('message.noData')}}</p>
                            </ul>
                            <div class="d-flex justify-content-center align-items-center" v-else>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center row__width px-0 mt-2">
                        <nav v-if="pages?.links" class="py-2">
                            <ul class="pagination d-flex justify-content-center">
                                <li v-for="(link, index) in pages?.links" :key="link.label" :class="{'active': link.active}" class="page-item">
                                    <a v-if="link.url" class="page-link" href="#" v-html="link.label" @click.prevent="slug === 'PAGE' ? fetchPages(link.url) : slug === 'PRODUCT' ? fetchProducts(link.url) : fetchProfessionals(link.url)"></a>
                                    <span v-else class="page-link" v-html="link.label"></span>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import NavbarCertificados from "../../../../components/Navs/NavbarCertificados.vue";
import FooterCertificados from "../../../../components/Footer/FooterCertificados.vue";
import NavLateralPanelAdmin from "../../../../components/Navs/NavLateralPanelAdmin.vue";
import AgregarConfiguraciones from "../../../../components/Panel/Admin/Ajustes/AgregarConfiguraciones.vue";
import TituloAdminAjustes from "../../../../components/Titulo/TituloAdminAjustes.vue";
import PreguntasFrecuentes from "../../../../components/Panel/Admin/Ajustes/PreguntasFrecuentes.vue";
import Productos from "../../../../components/Panel/Admin/Ajustes/Productos.vue";
import Modalidades from "../../../../components/Panel/Admin/Ajustes/Modalidades.vue";
import ConfiguracionAcademica from "../../../../components/Panel/Admin/Ajustes/ConfiguracionAcademica.vue";
import Beneficios from "../../../../components/Panel/Admin/Ajustes/Beneficios.vue";
import Valores from "../../../../components/Panel/Admin/Ajustes/Valores.vue";
import Categorias from "../../../../components/Panel/Admin/Ajustes/Categorias.vue";
import AgregarConfiguracionesProductos from "../../../../components/Panel/Admin/Ajustes/AgregarConfiguracionesProductos.vue";
import { useI18n } from "vue-i18n"
import { storeToRefs } from "pinia"
import { useUsersStore } from "../../../../stores/user"
import { useLanguageStore } from "../../../../stores/langs"
import { onBeforeMount, ref, provide, watch, onMounted, nextTick, inject  } from "vue"
import axios from 'axios';
import Swal from 'sweetalert2'
import Loader from "@/components/Filtros/Loader.vue";
import MazSelect from 'maz-ui/components/MazSelect'
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();

const {locale} = useI18n({ useScope: 'global' })

const users = useUsersStore();
const {access} = storeToRefs(users);
const {url} = users;
const {validate} = users;

const langs = useLanguageStore();
const {listLangs} = storeToRefs(langs);

const token = JSON.parse(localStorage.getItem('token'))
const headers = ref({'Authorization': `Bearer ${token}`})

const algo = onBeforeMount( () => {
    access.value = [1]
    //console.log(access.value)
    validate();
})

const isLoader = ref(false)

const page_id = inject('page_id')

const edit_page = inject('edit_page')

const fetchTags = inject('fetchTags')

const urlPages = '/api/admin/pages/byTypeClass'
const urlProducts = '/api/admin/products'
const urlProfessionals = '/api/public/professionals'
const urlSearchPages = '/api/admin/pages/search'
const urlSearchProducts = '/api/admin/products/search'
const urlSearchProfessionals = '/api/public/professionals/search'

const slug = ref()

const type = ref('FILTER')

const type_class = ref('COURSE')

const language = ref('ES')

const search_query = ref('')

const slug_options = ref([
    {
        label: "Páginas",
        value: "PAGE"
    },
    {
        label: "Productos",
        value: "PRODUCT"
    },
    {
        label: "Profesionales",
        value: "PROFESSIONAL"
    }
])

const type_options = ref([
    {
        label: "Filter",
        value: "FILTER"
    }
])

const class_options = ref([
    {
        label: "Course",
        value: "COURSE"
    }, 
    {
        label: "Downloadable",
        value: "DOWNLOADABLE"
    },
    {
        label: "Pack",
        value: "PACK"
    }
])

const pages = inject('pages')

const fetchPages = async(link) => {
    isLoader.value = true
    try {
        let body = { type: type.value, language: language.value }
        if(type.value === 'FILTER' || type.value === 'PRODUCT'){
            body.class = type_class.value
        }
        const response = await axios.post(link ? link : url+urlPages, body, {headers: headers.value})
        console.log(response)
        pages.value = response.data?.data
        isLoader.value = false
    } catch (error) {
        console.log(error)
        isLoader.value = false
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}

const products = inject('products')

const prod_class = ref(1)
const prod_classes = inject('prod_classes')

const fetchClasses = inject('fetchClasses')

const fetchProductsClass = async (class_id) => {
    isLoader.value = true
    try {
        const response = await axios.get(`${url}${urlProducts}?classes[]=${class_id}`, { headers: headers.value })
        console.log(response.data.data)
        pages.value = response.data.data
        console.log(pages.value)
        isLoader.value = false
    } catch (error) {
        console.log(error)
        isLoader.value = false
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}

const fetchProducts = async (link) => {
    isLoader.value = true
    try {
        const response = await axios.get(link ? link : `${url}${urlProducts}`, { headers: headers.value })
        console.log(response.data.data)
        pages.value = response.data.data
        console.log(pages.value)
        isLoader.value = false
    } catch (error) {
        console.log(error)
        isLoader.value = false
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}

const professionals = inject('professionals')

const fetchProfessionals = async (link) => {
    isLoader.value = true
    try {
        const response = await axios.get(link ? link : `${url}${urlProfessionals}`, { headers: {...headers.value, 'paginate': 'true'} })
        console.log(response)
        pages.value = response.data.data
        console.log(pages.value)
        isLoader.value = false
    } catch (error) {
        console.log(error)
        isLoader.value = false
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        });
    }
}

const searchPage = async(query) => {
    isLoader.value = true
    try {
        const response = await axios.get(url+urlSearchPages+'?value='+query, {headers: headers.value})
        console.log(response)
        pages.value = response.data
        isLoader.value = false
    } catch (error) {
        console.log(error)
        isLoader.value = false
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        })
    }
}

const searchProduct = async(query) => {
    isLoader.value = true
    try {
        const response = await axios.post(url+urlSearchProducts, {name: query}, {headers: headers.value})
        console.log(response)
        pages.value = response.data
        isLoader.value = false
    } catch (error) {
        console.log(error)
        isLoader.value = false
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        })
    }
}

const searchProfessional = async(query) => {
    isLoader.value = true
    try {
        const response = await axios.get(url+urlSearchProfessionals+'?name='+query, {headers: headers.value})
        console.log(response)
        pages.value = response.data
        isLoader.value = false
    } catch (error) {
        console.log(error)
        isLoader.value = false
        Swal.fire({
            toast: true,
            position: 'top-end',
            icon: 'error',
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000
        })
    }
}

</script>

<style lang="scss" scoped>
#app, .container-fluid{
    background-color: #FAFAFA;
    min-height: 100vh;
    @media (min-width: 992px) {
        background-color: #fff;
    }
}
.contenedor{
    animation: fade-in 0.2s ease-in forwards;
    @media (min-width: 992px) {
        max-width: 95vw;
        padding-top: 80px;
    }
    .tablas{  
        background-color: transparent;
        min-height: 56px;
        margin-bottom: 30px;
        border-bottom: 1px solid #70707023;
        box-shadow: 0px 3px 6px #00000016;
        border-radius: 10px;
        ul{
            background-color: transparent;
            padding: 8px 0;
            min-width: 100%;
        }
        .nav-item{
            padding: 0;
        }
        .nav-link{
            width: 100%;
            position: relative;
            padding: 10px 0;
            border: 0;
            background-color: transparent;
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            color: #B8C1DB;
            h3{
                font-weight: 500;
                font-size: 16px;
                color: #B8C1DB;
            }
            @media (min-width: 992px) {
                color: #0E1133;
                h3{
                    color: #0E1133;
                }
            }
        }
        .nav-link.active{
            font-weight: 700;
            border-color: transparent;
            color: var(--colorPrimary);
            background-color: transparent;
            border-radius: 0;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 5px;
                bottom: -10px;
                left: 0;
                background-color: var(--colorPrimary);
                border-radius: 10px;
            }
        }
        .scroll{
            width: 100%;
            height: 100%;
        }
        @media (max-width: 991px) {
            border-radius: 0px;
            margin-bottom: 10px;
        }
        
    }
    .filtrosMobile{
        h5{
            color: var(--colorSecondary);
            font-weight: 700;
            font-size: 22px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        h6{
            color: #697891;
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            padding-top: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .contenedor{
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ECECEC;
            background-color: var(--bgPrincipal);
            border-radius: 40px;
            min-height: 50px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 15px;
            @media (min-width: 992px) {
                border-radius: 40px;
            }
            @media (max-width: 991px) {
                width: 100%;
            }
            .img{
                padding: 0 20px;
            }
            input{
                border: 0;
                color: #B8C1DB;
                font-weight: 400;
                font-size: 14px;
                width: 90%;
                margin-left: 15px;
                &::placeholder{
                    color: #B8C1DB;
                }
                &:focus{
                    outline: 0;
                }
                @media (min-width: 992px) {
                    color: #697891;
                    font-size: 16px;
                    width: 93%;
                }
            }
            button{
                position: absolute;
                right: 7px;
                border: 0;
                min-height: 45px;
                width: 45px;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 50%;
                background-color: var(--colorPrimary);
                justify-content: center;
                align-items: center;
                img{
                    padding: 0;
                    max-height: 22px;
                }
                @media (min-width: 992px) {
                    right: 10px;
                }
            }
            .border-left{
                border-left: 1px solid rgba(184, 193, 219, 0.4);
            }
        }
    }
    .div{
        background-color: #fff;
        box-shadow: 0px 3px 10px #00000029;
        border-radius: 20px;
        padding:30px;
        min-height: auto;
        input{
            border: 0;
            color: #B8C1DB;
            font-weight: 400;
            font-size: 14px;
            width: 100%;
            &::placeholder{
                color: #B8C1DB;
            }
            &:focus{
                outline: 0;
            }
            @media (min-width: 992px) {
                color: #697891;
                font-size: 18px;
                width: 80%;
            }
        }
    }
    .collapses{
        h3{
            color: var(--colorSecondary);
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            padding: 7px 0px;
        }
        h6{
            color:#B8BCC8;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            padding: 5px 0px;
        }
        .accordion{
            width: 100%;
            .accordion-item{
                border: 0;
                border-radius: 10px;
                box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
                border-radius: 10px;
                background-color: #F9F9F9;
                margin-bottom: 15px;
                input{
                    width: 94%;
                    border: 2px solid #E9E3E3;
                    border-radius: 10px;
                    background-color: var(--bgPrincipal);
                    height: 50px;
                    padding-left: 15px;
                    outline: 0;
                    color: #697891;
                    font-size: 15px;
                    font-weight: 400;
                    &:focus, &:hover{
                        border: 2px solid #1679FC;
                    }
                    &::placeholder{
                        color: #697891;
                    }
                    @media (max-width: 991px) {
                        width: 83%;
                    }
                }
                .button{
                    background-color: #fff;
                    border: 2px solid #E9E3E3;
                    outline: 0;
                    border-radius: 25px;
                    min-height: 40px;
                    min-width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        height: 15px;
                    }
                }
                h4{
                    color: #0E1133;
                    font-weight: 500;
                    font-size: 16px;
                    text-align: left;
                }
                h5{
                    color: var(--colorSecondary);
                    font-weight: 600;
                    font-size: 16px;
                    text-align: left;
                }
            }
            .accordion-item:last-of-type .accordion-button.collapsed{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }
            .accordion-header{
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                min-height: 70px;
                .accordion-button{
                    background-color: #F9F9F9;
                    padding: 10px;
                }
                .accordion-button:not(.collapsed){
                    background-color: inherit;
                    box-shadow: none;
                }
                .accordion-button::after{
                    position: absolute;
                    right: 2%;
                    background-image: url('../../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                    background-size: 50%;
                    background-position: center;
                    background-color: transparent;
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                }
                .accordion-body{
                    span{
                        border: 2px solid #E9E3E3;
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        h3{
                            text-align: center;
                        }
                    }
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        height: 40px;
                        width: 40px;
                        margin-left: 10px;
                    }
                }
                
            }
            .eliminar{
                background-color: transparent;
                border-radius: 50%;
                height: 40px;
                width: 40px;
                border: 1px solid #B8BCC8;
            }
        }
        .padding{
            padding: 0 11%;
        }
    }
    .academicas{
        background-color: #F5F5F5;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 15px;
        height: 110px;
        h4{
            color: var(--colorSecondary);
            font-weight: 600;
            font-size: 16px;
        }
        h5{
            color: #2E4765;
            font-weight: 300;
            font-size: 14px;
        }
        p{
            color: #697891;
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            padding-left: 10px;
        }
        .guardar{
            button{
                border: 2px solid var(--colorPrimary);
                background-color: var(--bgPrincipal);
                border-radius: 25px;
                height: 50px;
                font-weight: 600;
                font-size: 16px;
                color: var(--colorPrimary);
                width: 100%;
            }
        }
        input,select{
            width:100%;
            border: 2px solid #E9E3E3;
            border-radius: 10px;
            background-color: #FCFCFC;
            height: 50px;
            padding-left: 15px;
            outline: 0;
            color: #697891;
            font-size: 15px;
            font-weight: 400;
            -webkit-appearance: none;
            &:focus, &:hover{
                border: 2px solid #1679FC;
            }
            &::placeholder{
                color: #697891;
            }
        }
    }   
    .border-top{
        border-top: 2px solid #E4E4E4;
        padding-top: 20px;
    }
    .agregar-cursos-btn, .agregar-cursos-btn-3{
        box-shadow: 0 3px 6px #00000016;
        border-radius: 43px;
        height: 60px;
        margin: 20px 0;
        border: 0;
        background-color: var(--bgPrincipal);
        font-weight: 600;
        font-size: 16px;
        color: var(--colorSecondary);
        padding-bottom: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15px;
        width: 100%;
        max-width: 300px;
        span{
            border: 2px solid #E4E4E4;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
        }
    }
    .agregar-cursos-btn-4{
        max-width: 50px;
        height: 50px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            margin-right: 0;
        }
    }
    .faq{
        .collapses{
            h3{
                margin-bottom: 5px !important;
                font-weight: 700;
                font-size: 18px;
            }
            .question{
                font-size: 30px;
                margin-bottom: 0 !important;
            }
            h4{
                font-weight: 400;
                font-size: 15px;
                color: #B8BCC8;
                text-align: left;
            }
            .accordion{
                width: 100%;
                .accordion-item{
                    margin: 15px 0px;
                    border: 0;
                    border-radius: 10px;
                    
                    p{
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--colorSecondary);
                    }
                }
                .accordion-item:last-of-type .accordion-button.collapsed{
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
                .accordion-header{
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 10px;
                    background-color: #F9F9F9;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    position: relative;
                    .accordion-button{
                        border-radius: 10px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        background-color: #F9F9F9;
                        &:focus{
                            border-color: inherit;
                            box-shadow: none;
                        }
                        span{
                            border: 2px solid #E9E3E3;
                            min-height: 50px;
                            min-width: 50px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        @media (max-width: 991px) {
                            justify-content: flex-start;
                            align-items: flex-start;
                        }
                    }
                    .accordion-button:not(.collapsed){
                        background-color: inherit;
                        box-shadow: none;
                    }
                    .accordion-button::after{
                        position: absolute;
                        right: 5%;
                        background-image: url('../../../../img/subir-productos-icons/arrow-abajo-azul.svg');
                        background-size: 100%;
                        background-position: center;
                        @media (max-width: 991px) {
                            margin-top: 5px;
                        }
                    }
                }
            }
            @media (min-width: 992px) {
                margin: 20px 0 60px 0;
            }
            .collapses2{
                h3{
                    margin-bottom: 0px !important;
                    font-size: 16px;
                }
                .accordion{
                    width: 100%;
                    .accordion-item{
                        margin: 15px 0px;
                        border: 0;
                        border-radius: 10px;
                    }
                    .accordion-item:last-of-type .accordion-button.collapsed{
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }
                    .accordion-header{
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 10px;
                        background-color: var(--bgPrincipal);
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        position: relative;
                        .accordion-button{
                            border-radius: 10px;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            background-color: var(--bgPrincipal);
                            &:focus{
                                border-color: inherit;
                                box-shadow: none;
                            }
                            span{
                                border: 2px solid #E9E3E3;
                                min-height: 40px;
                                min-width: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                h3{
                                    text-align: center;
                                }
                            }
                        }
                        .accordion-button:not(.collapsed){
                            background-color: inherit;
                            box-shadow: none;
                        }
                        .accordion-button::after{
                            position: absolute;
                            right: 2.5%;
                            background-image: url('../../../../img/subir-productos-icons/arrow-gris-abajo.svg');
                            background-size: 50%;
                            background-position: center;
                            border: 2px solid #E9E3E3;
                            border-radius: 50%;
                            min-height: 40px;
                            min-width: 40px;
                        }
                        .accordion-body{
                            input,select{
                                width: 100%;
                                border: 2px solid #E9E3E3;
                                border-radius: 10px;
                                background-color: #FCFCFC;
                                height: 50px;
                                padding-left: 15px;
                                outline: 0;
                                color: #697891;
                                font-size: 15px;
                                font-weight: 400;
                                -webkit-appearance: none;
                                &:focus, &:hover{
                                    border: 2px solid #1679FC;
                                }
                                &::placeholder{
                                    color: #697891;
                                }
                            }
                            span{
                                border: 2px solid #E9E3E3;
                                height: 40px;
                                width: 40px;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                h3{
                                    text-align: center;
                                }
                            }
                            button{
                                background-color: transparent;
                                border: 2px solid #E4E4E4;
                                border-radius: 50%;
                                min-height: 40px;
                                min-width: 40px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
                
                @media (min-width: 992px) {
                    margin: 20px 0 60px 0;
                }
            }
            
            .max-width{
                width: 7%;
            }
            .position-relative{
                .position{
                    position: absolute;
                    right: 8%;
                    button{
                        background-color: transparent;
                        border: 2px solid #E4E4E4;
                        border-radius: 50%;
                        min-height: 40px;
                        min-width: 40px;
                        
                    }
                }
            }
        }
    }
    
}
.modality{
    h3{
        color: var(--colorSecondary);
        font-size: 16px;
        font-weight: 400;
        text-align: left;
        padding: 0 0px 10px 0;
    }
    input[type=text], textarea{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: transparent;
        height: 50px;
        padding-left: 15px;
        outline: 0;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
    textarea{
        padding-top: 15px;
        height: 150px;
    }
    input[type=file]{
        display: none;
    }
    input[type=file] + label{
        background-color: #fff;
        border: 2px dashed #B8BCC8;
        height: 50px;
        width: 100%;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        color: #B8BCC8;
        &:focus, &:hover{
            border: 2px dashed var(--colorPrimary);
            color: var(--colorPrimary);
        }
    }
    .btn-close{
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
}

.classSpecial{
    @media (max-width: 991px) {
        padding-bottom: 30vw;
    }
    @media (min-width: 992px) {
        width: 70vw !important;
    }
}

.professions{
    input, select{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: var(--bgPrincipal);
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
    .button{
        background-color: #fff;
        border: 2px solid #E9E3E3;
        outline: 0;
        border-radius: 25px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            height: 15px;
        }
    }
    h4{
        color: #0E1133;
        font-weight: 500;
        font-size: 16px;
        text-align: left;
    }
    h5{
        color: var(--colorSecondary);
        font-weight: 600;
        font-size: 16px;
        text-align: left;
    }
    .title{
        font-size: 20px;
    }
    .agregar-cursos-btn-4{
        max-width: none;
        @media (max-width: 992px) {
            max-width: 300px;
        }
        img{
            margin-right: 15px;
        }
    }
    .border-box{
            background-color: #f0f2f8;
            border-radius: 20px;
            min-height: 100px;
            .agregar-cursos-btn, .agregar-cursos-btn-3{
            box-shadow: 0 3px 6px #00000016;
            border-radius: 30px;
            height: 60px;
            margin: 20px 0;
            border: 0;
            background-color: var(--bgPrincipal);
            font-weight: 600;
            font-size: 16px;
            color: var(--colorSecondary);
            padding-bottom: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 15px;
            width: 100%;
            max-width: max-content;
            display: flex;
            justify-content: center;
            align-items: center;
            h6{
                text-align: left;
            }
        }
        .button-edit{
            background-color: #1679FC;
            width: max-content;
            padding: 15px;
            height: 30px;
            border-radius: 15px;
            color: #ECECEC;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        button{
            border: #00000016 1px solid;
            height: 40px;
            width: 40px;
            border-radius: 50%;   
            margin-left: 10px;
        }
        input[type="checkbox"]{
            height: 20px;
            width: 20px;
        }
    }
    .button_test{
        height: 50px !important;
        width: max-content !important;
        background-color: #1679FC;
        color: #ffffff;
        font-size: 16px;
        border-radius: 15px !important;
        padding: 15px;
        margin: 15px;
    }
    h4.mb-4{
        color: #0E1133;
        font-weight: 600;
        font-size: 20px;
        text-align: left;
    }
    h5.mb-2{
        color: #2E4765;
        font-weight: 600;
        font-size: 16px;
        text-align: left;
    }
    h6.mt-2, h6.mb-2, h6.mb-3{
        text-align: left;
    }
    p{
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

    }
}

.reviews{
    h5{
        color: #0E1133;
        font-weight: 400;
        font-size: 18px;
        text-align: left;
    }
    h6{
        color: #2E4765;
        font-weight: 600;
        font-size: 16px;
        text-align: left;
    }
    p, .btn-none {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-top: 5px;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .btn-none{
        border: 0 !important;
        outline: 0;
        background-color: transparent;
        width: max-content;
        padding: 0;
    }
    .clamped {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 44px; 
    }
    input, select{
        width: 100%;
        border: 2px solid #E9E3E3;
        border-radius: 10px;
        background-color: var(--bgPrincipal);
        height: 50px;
        padding-left: 15px;
        outline: 0;
        color: #697891;
        font-size: 15px;
        font-weight: 400;
        &:focus, &:hover{
            border: 2px solid #1679FC;
        }
        &::placeholder{
            color: #697891;
        }
    }
    .button{
        background-color: #fff;
        border: 2px solid #E9E3E3;
        outline: 0;
        border-radius: 25px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            height: 15px;
        }
    }
    
    .title{
        font-size: 20px;
        font-weight: 600;
        color: var(--colorSecondary);
    }
    .agregar-cursos-btn-4{
        max-width: none;
        @media (max-width: 992px) {
            max-width: 300px;
        }
        img{
            margin-right: 15px;
        }
    }
    .border-box{
        background-color: #f0f2f8;
        border-radius: 20px;
        min-height: auto;
        padding: 20px;
        .agregar-cursos-btn, .agregar-cursos-btn-3{
            box-shadow: 0 3px 6px #00000016;
            border-radius: 30px;
            height: 60px;
            margin: 20px 0;
            border: 0;
            background-color: var(--bgPrincipal);
            font-weight: 600;
            font-size: 16px;
            color: var(--colorSecondary);
            padding-bottom: 5px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 15px;
            width: 100%;
            max-width: max-content;
            display: flex;
            justify-content: center;
            align-items: center;
            h6{
                text-align: left;
            }
        }
        .button-edit{
            background-color: #1679FC;
            width: max-content;
            padding: 15px;
            height: 30px;
            border-radius: 15px;
            color: #ECECEC;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        button:not(.btn-none){
            border: #00000016 1px solid;
            height: 40px;
            width: 40px;
            border-radius: 50%;   
            margin-left: 10px;
        }
        input[type="checkbox"]{
            height: 20px;
            width: 20px;
        }
    }
    .button_test{
        height: 50px !important;
        width: max-content !important;
        background-color: #1679FC;
        color: #ffffff;
        font-size: 16px;
        border-radius: 15px !important;
        padding: 15px;
        margin: 15px;
    }
    h4.mb-4{
        color: #0E1133;
        font-weight: 600;
        font-size: 20px;
        text-align: left;
    }
    h5.mb-2{
        color: #2E4765;
        font-weight: 600;
        font-size: 16px;
        text-align: left;
    }
    h6.mt-2, h6.mb-2, h6.mb-3{
        text-align: left;
    }
    
}


@keyframes fade-in {
    0% { opacity: 0; }
   90% { opacity: 0.9; }
  100% { opacity: 1; }
}

.pagination{
    .page-item{
        border: 0;
        border-radius: 25px;
        margin: 0 2px;
        .page-link{
            border-radius: 25px;
            border: 0;
            font-weight: 500;
            color: var(--colorSecondary);
            font-family: 'Poppins';
            a{
                &:hover{
                    background-color: var(--colorPrimary);
                    color: var(--colorSecondary);
                }
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
    .buttons{
        border-radius: 15px;
        .page-link{
            border-radius: 15px;
        }
    }
    
    .active{
        background-color: var(--colorPrimary);
        .page-link{
            color: #fff !important;
        }
        a{
            color: #fff !important;
        }
    }

}

button{
    border: 2px solid var(--colorPrimary);
    border-radius: 30px;
    background-color: var(--colorPrimary);
    width: 90px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 5px;
    h3{
        color: #FAFAFA;
        font-size: 18px;
        font-weight: 600;
        display: flex;
    }
}

.search-input{
    border: 1px solid #ECECEC;
    background-color: var(--bgPrincipal);
    border-radius: 40px;
    min-height: 0px;
    height: 60px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px;
    
    @media (min-width: 992px) {
        border-radius: 40px;
    }
    @media (max-width: 991px) {
        margin: 10px 0;
    }
    input{
        border: 0;
        font-weight: 400;
        font-size: 14px;
        width: 100%;
        padding-left: 15px;
        &::placeholder{
            color: #B8C1DB;
        }
        &:focus{
            outline: 0;
        }
        
        @media (min-width: 992px) {
            font-size: 16px;
            width: 93%;
        }
    }
    button{
        position: absolute;
        right: 7px;
        border: 0;
        min-height: 45px;
        width: 45px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 50%;
        background-color: var(--colorPrimary);
        justify-content: center;
        align-items: center;
        img{
            padding: 0;
            max-height: 22px;
        }
        @media (min-width: 992px) {
            right: 10px;
        }
    }
}
</style>