<template>
    <div class="row justify-content-center my-2 row__width">
        <div class="col-12 d-flex flex-column justify-content-start align-items-center slide px-0">
            <router-link :to="{name: 'Descargable', params: {slug: slug}}" class="col-12 d-flex justify-content-center align-items-center px-3 my-2 position-relative">
                <img :src="rImage" alt="downloadbles-presentation" class="ejemplo">
            </router-link>
            <router-link :to="{name: 'Profesional', params: {slug: ownerSlug}}" class="row justify-content-center descripcion row__width" v-if="ownerSlug">
                <div class="col-12 d-flex justify-content-start align-items-center creador">
                    <img :src="avatar" alt="avatar" class="ejemplo">
                    <div class="d-flex flex-column justify-content-center align-items-start ps-2">
                        <p class="creator-label">{{$t('message.creator')}}</p>
                        <p class="creator-name" :title="owner">{{ owner }}</p>
                    </div>
                </div>
            </router-link>
            <router-link :to="{name: 'Descargable', params: {slug: slug}}" class="row justify-content-center row__width">
                <div class="col-12 d-flex flex-column justify-content-center align-items-start descripcion">
                    <h3 :title="name">{{ name }}</h3>
                </div>
                <div class="row justify-content-start row__width px-2" v-if=" collabs.length >= 1 || sponsors.length >= 1" >
                    <div class="col-12 d-flex justify-content-start align-items-center colaboradores my-3">
                        <button type="button" class="btn_none d-flex justify-content-center align-items-center" v-if=" collabs && collabs.length >= 1" @click="openModal(id)">
                            <img :src="collabs[0].avatar" alt="avatar-collab" class="img_ejemplo">
                            <div class="colaboran"><p class="collabs">+{{ collabs.length }}</p></div>
                            <p class="collaborate p_mobile">{{$t('message.collaborate')}}</p>
                        </button>
                        <button type="button" class="btn_none d-flex justify-content-start align-items-center w-100" v-if=" sponsors && sponsors.length >= 1" @click="openModal2(id)">
                            <img :src="sponsors[0].avatar" alt="avatar-collab" class="img_ejemplo">
                            <div class="colaboran"><p class="collabs">+{{ sponsors.length }}</p></div>
                            <p class="collaborate p_mobile">Patrocinan</p>
                        </button>
                    </div>
                </div>
                <span class="border-bottom w-100 mb-2"  v-if=" sponsors && sponsors.length >= 1 || collabs && collabs.length >= 1"></span>
            </router-link>
            <router-link :to="{name: 'Descargable', params: {slug: slug}}" class="row justify-content-center row__width">
                <div class="col d-flex flex-column justify-content-center align-items-start padding pe-0 comprar my-3" v-if="price">
                    <p class="price-main">{{ price.coin_type[0].abbr }} ${{ price.finalPrice }}</p>
                    <p class="price-old">{{ price.coin_type[0].abbr }} ${{ price.totalPrice }}</p>
                </div>
                <div class="col-12 d-flex justify-content-center align-items-center mt-2 comprar">
                    <router-link :to="{name: 'Curso', params: {slug: slug}}" class="btn__primario btn-width">
                        {{$t('message.buy')}}
                    </router-link>
                </div>
            </router-link>
        </div>
    </div>

</template>

<script setup>
import BTNPrimario from "../Botones/PrimarioRutas.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
import { useGeneralStore } from "../../stores/general"
import { storeToRefs } from "pinia";
import { ref, watch } from 'vue';
import { useI18n } from "vue-i18n";
import axios from 'axios';

const general = useGeneralStore();
const {textos, filtrosDesplegados, mapaDesplegado, reSearch} = storeToRefs(general);
const {} = general;

const {locale} = useI18n({ useScope: 'global' })
const lang = ref()
const X = ref('')

const props = defineProps({
        rName: '',
        rSlug: '',
        rOwner: '',
        rOwnerAvatar: '',
        rImage: '',
        rModality: '',
        rDetail: '',
        rPrice: '',
        rVerified: '',
        rProductVerified: '',
        rCreatedAt: '',
        rCollabs: '',
        rReviews: '',
        rMarketplace: '',
        rMembership: '',
        rPurchases: '',
        id: '',
        rOwnerSlug: '',
        rSponsors:''
    })

    //Setea las variables con los resultados para su uso
    let id = props.id
    let name = props.rName
    let slug = props.rSlug
    let owner = props.rOwner
    let ownerSlug = props.rOwnerSlug
    let avatar = props.rOwnerAvatar
    let image = props.rImage
    let modality = props.rModality
    let details = props.rDetail
    let price = props.rPrice
    let verified = props.rVerified
    let productVerified = props.rProductVerified
    let createdAt = props.rCreatedAt
    let collabs = props.rCollabs
    let reviews = props.rReviews
    let marketplace = props.rMarketplace
    let membership = props.rMembership
    let purchases_count = props.rPurchases
    let sponsors = props.rSponsors

    const openModal = (id) => {
        console.log(id)
        let element = document.getElementById('collabModal'+id)
        console.log(element)
        console.log(element.classList)
        element.classList.remove("d-none")
    }

    const openModal2 = (id) => {
        console.log(id)
        let element = document.getElementById('sponsorModal'+id)
        console.log(element)
        console.log(element.classList)
        element.classList.remove("d-none")
    }
</script>

<style lang="scss" scoped>
.mySwiper{
    padding: 20px 0px;
    margin: 0px 0;
}

.slide {
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;
    height: 435px;
    max-height: 435px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ejemplo {
        width: 100%;
        border-radius: 15px;
        object-fit: cover;
        height: 150px;
    }

    .position {
        position: absolute;
        top: 0;
        min-height: 100%;
        width: 100%;
        z-index: 3;

        .highlight-title {
            font-family: 'Proxima Nova';
            font-weight: 500;
            font-size: 17px;
            color: #FEB230; // Reemplazo de h6
        }

        .highlight-text {
            font-family: 'Proxima Nova';
            font-weight: 500;
            font-size: 17px;
            color: var(--bgPrincipal); // Reemplazo de p
            padding-left: 3px;
        }

        .row__slide {
            position: relative;
            min-height: 100% !important;
            width: 100%;

            .arriba {
                position: absolute;
                top: 10%;
            }

            .abajo {
                position: absolute;
                bottom: 10%;
            }
        }
    }

    .filtro {
        &::before {
            content: '';
            position: absolute;
            bottom: 10px;
            width: 96%;
            height: 40%;
            border-radius: 0px 0px 15px 15px;
            background: linear-gradient(to top, #081026, #252E4800);
            filter: opacity(1);
            z-index: 2;
        }
    }

    .descripcion {
        h3 {
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-align: left;
            min-height: 53px;
        }

        .product-description {
            font-weight: 400;
            font-size: 15px;
            color: #B8C1DB;
            padding-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.2;
            text-align: left;
        }

        .creator-label {
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
        }

        .creator-name {
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .creador {
            border-right: 1px solid rgba(184, 193, 219, 0.3);
            padding-bottom: 10px;

            .ejemplo {
                height: 50px;
                width: 50px;
                object-fit: cover;
                border-radius: 50%;
            }
        }

        .border-bottom {
            width: 90%;
            height: 1px;
            background-color: rgba(184, 193, 219, 0.3);
        }
    }

    .comprar {
        margin-bottom: 5px;
        margin-top: 10px;

        .price-main {
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;

            @media (max-width: 992px) {
                font-size: 16px;
            }
        }

        .price-old {
            font-size: 14px;
            font-weight: 400;
            color: #B8C1DB;
            text-decoration: line-through;
            text-align: left;
            text-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
        }

        .padding {
            padding-left: 10px;
        }
    }
    .colaboradores{
        position: relative;
        p{
            color: #B8C1DB;
            font-weight: 500;
            font-size: 15px;
        }
        .img_ejemplo{
            object-fit: cover;
            border-radius: 50%;
            min-width: 25px;
            min-height: 25px;
            max-width: 25px;
            max-height: 25px; 
            margin-right: 5px;
            position: relative;
            left: 0;
            
        }
        .colaboran{
            background-color: #697891;
            box-shadow: 0px 3px 6px #00000029;
            border: 2px solid #FFFFFF;
            border-radius: 50%;
            min-width: 25px;
            min-height: 25px;
            max-width: 25px;
            max-height: 25px;                
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            right: 15px;
            z-index: 1;
            p{
                font-size: 10px
            }
        }
        .collabs{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .colaboran + .collaborate{
            position: relative;
            right: 10px;
        }
        @media (min-width: 1200px) {
            .img_ejemplo, .colaboran{
                min-width: 35px;
                min-height: 35px;
                max-width: 35px;
                max-height: 35px; 
                // position: absolute;
                // left: 2%;
            }
            .img-ejemplo{
                left: 15px;
            }
            // .colaboran + h3{
            //     position: relative;
            //     right: 0;
            // }
            .colaboran {
                p{
                    font-size: 14px;
                }
            }
        }
    }

}

</style>