<template>
    <div class="tab-pane fade my-3 row justify-content-center active show" id="home" role="tabpanel" aria-labelledby="home-tab">
        <TituloHomeCursosH1 :tituloCurso="product.name" class="d-lg-none"/>
        
        <div class="d-lg-none"><profesorHomeCursos class=""/></div>
        <rateProfesorCurso class="d-lg-none"/>
        <!-- DESCRIPCION -->
        <TituloHomeCursos :tituloCurso="$t('message.courseDescription')" :tituloMediano="true" id="tab-1" v-if="product.description"/>
        <DescripcionCursoTexto v-if="product.description"/>
        <!-- QUE INCLUYE -->
        <TituloHomeCursos :tituloCurso="$t('message.whatIncludesCourse')" :tituloMediano="true" v-if="product.values && Object.keys(product.values).length > 0"/>
        <QueIncluyeCursos v-if="product.values && Object.keys(product.values).length > 0"/>
        <!-- FECHA -->
        <TituloHomeCursos :tituloCurso="$t('message.datesCourses')" :tituloMediano="true" id="tab-2" v-if="product.details.schedules && Object.keys(product.details.schedules).length > 0"/>
        <Fechas v-if="product.details.schedules && Object.keys(product.details.schedules).length > 0"/>
        <!-- UBICACION -->
        <TituloHomeCursos :tituloCurso="$t('message.location')" :tituloMediano="true" id="tab-3" v-if="product.details.location.address && product.type?.id == 4"/>
        <Ubicacion v-if="product.details.location.address && product.type?.id == 4"/>
        <!-- PAQUETES -->
        <TituloHomeCursos :tituloCurso="'Paquetes'" :tituloMediano="true" v-if="product.packages"/>
        <Paquetes v-if="product.packages"/>
        <!-- CONTENIDO -->
        <TituloHomeCursos :tituloCurso="$t('message.contentCourse')" :tituloMediano="true" class="mb-2 mb-lg-0" id="tab-4" v-if="product.units.length > 0 || product.downloadables"/>
        <contenidoHomeCursos v-if="product.units.length > 0 || product.downloadables"/>
        <ModulosHomeCursos v-if="product.units.length > 0 || product.downloadables"/>
        <!-- CONTACTO -->
        <!-- <ContactarHomeCursos class="d-none d-lg-flex"/> -->
        <!-- PROFESIONAL -->
        <!-- <TituloHomeCursos :tituloCurso="$t('message.teacherCourse')" :tituloMediano="true" class="d-lg-none" id=""/> -->
        <TituloHomeCursos :tituloCurso="$t('message.teacherCourse')" :tituloMediano="true" class="d-none d-lg-flex" id="tab-5" v-if="professional"/>
        <InfoProfesorHomeCursos class="d-lg-none"/>
        <PerfilProfesorHomeCursos class="d-lg-none"/>
        <InfoProfesorHomeCursosV3 class="d-none d-lg-flex"/>
        <!-- BENEFICIOS -->
        <TituloHomeCursos :tituloCurso="$t('message.benefitsCourse')" :tituloMediano="true" id="tab-6"  v-if="product.benefits && Object.keys(product.benefits).length > 0 && product.class.id == 1"/>
        <BeneficiosHomeCursos v-if="product.benefits && Object.keys(product.benefits).length > 0 && product.class.id == 1"/>
        <!-- PORTFOLIO ESTUDIANTES -->
        <!-- <TituloHomeCursos :tituloCurso="$t('message.studentPortfolio')" :tituloMediano="true" class="d-none"/>
        <sliderPortafolioEstudiantesHomeCursos class="d-none"/> -->
        <!-- RATING -->
        <TituloHomeCursos :tituloCurso="$t('message.ratings')" :tituloMediano="true"/>
        <ValoracionesCursos/>
        <!-- OPINIONES -->
        <TituloHomeCursos :tituloCurso="$t('message.opinionsCourse')" class="mt-4"/>
        <Opiniones/>
        <!-- FAQS -->
        <TituloHomeCursos :tituloCurso="$t('message.FAQ')" v-if="product.faqs && Object.keys(product.faqs).length > 0"/>
        <PreguntasFrecuentesHomeCursos v-if="product.faqs && Object.keys(product.faqs).length > 0"/>
    </div>
</template>

<script setup>
import BeneficiosHomeCursos from "./Beneficios.vue";
import contenidoHomeCursos from "./Contenido.vue";
import DescripcionCursoTexto from "./DescripcionCursoTexto.vue";
import InfoProfesorHomeCursos from "./InfoProfesor.vue";
import InfoProfesorHomeCursosV3 from "./InfoProfesorV3.vue";
import ModulosHomeCursos from "./Modulos.vue";
import PerfilProfesorHomeCursos from "./PerfilProfesor.vue";
import PreguntasFrecuentesHomeCursos from "./PreguntasFrecuentes.vue";
import profesorHomeCursos from "./Profesor.vue";
import QueIncluyeCursos from "./QueIncluye.vue";
import rateProfesorCurso from "./RateProfesorCurso.vue";
import TituloHomeCursos from "../Titulo/TituloHomeCursos.vue";

import BTNMostrarMas from "../Botones/MostrarMas.vue";
import { ref, inject,onMounted, provide } from 'vue';
import Fechas from "./Fechas.vue";
import Ubicacion from "./Ubicacion.vue";
import Paquetes from "./Paquetes.vue";
import Opiniones from "./Opiniones.vue";
import Valoraciones from "./Valoraciones.vue";
import ValoracionesCursos from "./ValoracionesCursos.vue";
import TituloHomeCursosH1 from "../Titulo/TituloHomeCursosH1.vue";

const product = inject('product')
let professional = inject('professional')

const description = ref(product.value.description)


const containsHTML = (description) => {
    const div = document.createElement('div');
    div.innerHTML = description;
    return div.innerHTML !== description;  
}

const seeDescription = () =>{
    if (containsHTML(product.description)) {
        
    } else {
        console.log("La descripción no contiene HTML.");
    }
}
seeDescription();


</script>


<style lang="scss" scoped>

</style>