<template>
    <div class="col-12 col-lg-8 d-flex flex-column justify-content-center align-items-center">
        <div class="row justify-content-center row__width">
            <SobreV2    
                :userName="userName"
                :userAbout="userAbout"
                :courses="courses"
                :avatar="avatar"
                :gallery="gallery"
                :courses_uploaded="courses_uploaded"
                :images_uploaded="images_uploaded" 
                id="1"
            />
            <div class="col-12 px-0" id="2" v-if="professional.gallery?.specializations.length > 0">
                <div class="row justify-content-center justify-content-lg-between div__slider cambio__Desk">
                    <div class="col-12 col-lg-5 d-flex justify-content-center align-items-start   d-none">
                        <TipoCurso/>
                    </div>
                    <div class="col-12 col-lg-8 d-flex justify-content-start align-items-center px-0">
                        <h2>Galerías</h2>
                    </div>
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas2 px-0 my-4">
                        <div class="scroll2">
                            <ul class="nav nav-tabs">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" 
                                    :class="actual_gallery === null || actual_gallery === false || !actual_gallery ? 'active' : '' "
                                     id="todos-tab" data-bs-toggle="tab" data-bs-target="#todos" type="button" role="tab" aria-controls="todos" aria-selected="true" @click="actual_gallery = null">
                                        {{ $t('message.all') }}
                                    </button>
                                </li>
                                <li class="nav-item" role="presentation" v-for="(gallery, index) in galeries">
                                    <button class="nav-link" :class="actual_gallery == gallery ? 'active' : '' " :id="gallery.translation.name + 'tab'" data-bs-toggle="tab" data-bs-target="#todos" type="button" role="tab" aria-controls="todos" aria-selected="true"
                                    @click="actual_gallery = null, actual_gallery = gallery" >
                                        {{ gallery.translation.name  }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="todos-tab" role="tabpanel" aria-labelledby="todos-tab">
                            <PerfilGaleria :gallery="professional.gallery"/>
                        </div>
                        <div class="tab-pane fade" :id="gallery.translation.name + 'tab'" role="tabpanel" aria-labelledby="home-tab" v-for="(gallery, index) in galeries">
                            <PerfilGaleria :gallery="professional.gallery"/>
                        </div>
                    </div>
                    
                    <!-- <div class="col-12 d-flex justify-content-center justify-content-lg-start align-items-center px-0 botones mt-4">
                        <button id="prevSliderMarca"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                        <div><h4>01/</h4><h5>10</h5></div>
                        <button id="nextSliderMarca"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
                    </div> -->
                </div>
            </div>
            <div class="col-12" v-if="professional.products.courses" id="4">
                <div class="row justify-content-center">
                    <!-- <ServiciosV2/>
                    <div class="col-12 d-flex justify-content-center align-items-start div__slider cambio__Desk mt-lg-4">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                <div class="row justify-content-center justify-content-lg-between row__width">
                                    <div class="col-12  d-flex flex-column justify-content-center  align-items-start mb-4 m-lg-0 px-0">
                                        <div class="row justify-content-center row__width">
                                            <div class="col d-flex flex-column justify-content-center align-items-start">
                                                <h2>Asesorias</h2>
                                                <h3 class="d-none d-lg-flex">Desde 50USD</h3>
                                            </div>
                                            <div class="col d-flex justify-content-end align-items-center botones px-0">
                                                <div class="d-none d-lg-flex"><h4>01/</h4><h5>10</h5></div>
                                                <button id="prevSliderMarca"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                                                <button id="nextSliderMarca"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
                                            </div>
                                        </div>
                                        <h3 class="d-lg-none">Desde 50USD</h3>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <AsesoriasServicios/>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-12 d-flex justify-content-center align-items-start div__slider cambio__Desk mt-4">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                <TituloProfesor class="" :titulo="$t('message.coursesPhotography')" idNextV2="nextSliderCursosboton" idBackV2="prevSliderCursosboton" pagination="pagCursos"/>
                            </div>
                            <!-- <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas2 px-0 my-4">
                                <div class="scroll2 d-flex justify-content-start align-items-center">
                                    <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link active" id="todos-tab" data-bs-toggle="tab" data-bs-target="#todos" type="button" role="tab" aria-controls="todos" aria-selected="true">{{ $t('message.all') }}</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="online-tab" data-bs-toggle="tab" data-bs-target="#online" type="button" role="tab" aria-controls="online" aria-selected="false">Online</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="presencial-tab" data-bs-toggle="tab" data-bs-target="#presencial" type="button" role="tab" aria-controls="presencial" aria-selected="false">{{ $t('message.inPerson') }}</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="vivo-tab" data-bs-toggle="tab" data-bs-target="#vivo" type="button" role="tab" aria-controls="vivo" aria-selected="false">Vivo</button>
                                        </li>
                                    </ul>
                                </div>
                            </div> -->
                            <div class="col-12  d-flex justify-content-center align-items-center px-0">
                                <div class="row justify-content-center row__width" id="myTabContent3">
                                    <SeccionesHomeV4/>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12 d-flex justify-content-center align-items-start div__slider cambio__Desk ">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                <div class="row justify-content-center justify-content-lg-between row__width">
                                    <div class="col-12 col-lg-7 d-flex flex-column justify-content-center  align-items-start mb-4 m-lg-0 px-0">
                                        <div class="row justify-content-center row__width">
                                            <div class="col d-flex flex-column justify-content-center align-items-start">
                                                <h2>Sesión de fotos</h2>
                                                <h3 class="d-none d-lg-flex">Desde 50USD</h3>
                                            </div>
                                            <div class="col d-flex justify-content-end align-items-center botones px-0">
                                                <div class="d-none d-lg-flex"><h4>01/</h4><h5>10</h5></div>
                                                <button id="prevSliderMarca"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                                                <button id="nextSliderMarca"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
                                            </div>
                                        </div>
                                        <h3 class="d-lg-none">Desde 50USD</h3>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center align-items-center">
                                <AsesoriasServicios/>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-12" id="3">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-center align-items-start div__slider cambio__Desk">
                        <div class="row justify-content-start row__width">
                            <div class="col-12 col-lg-10 d-flex justify-content-center align-items-start">
                                <div class="row justify-content-start row__width">
                                    <TituloHomeCursos :tituloCurso="$t('message.opinionsProfessional')" class="px-0"/>
                                    <Valoraciones/>
                                    <Opinion/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 px-0" id="5">
                <div class="row justify-content-center justify-content-lg-between div__slider cambio__Desk d-none">
                    <div class="col-12 d-flex justify-content-center align-items-start div__slider cambio__Desk ">
                        <div class="row justify-content-center row__width">
                            <div class="col-12 d-flex justify-content-center align-items-center px-0">
                                <div class="row justify-content-center justify-content-lg-between row__width">
                                    <div class="col-12 col-lg-7 d-flex flex-column justify-content-center  align-items-start mb-4 m-lg-0 px-0">
                                        <h2>Cursos</h2>
                                        <h3>Tiene 200 {{ $t('message.productsV2') }}</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex flex-column justify-content-center align-items-center tablas2 px-0 my-4">
                                <div class="scroll2 d-flex justify-content-start align-items-center">
                                    <ul class="nav nav-tabs row justify-content-start" id="myTab" role="tablist">
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link active" id="todos-tab" data-bs-toggle="tab" data-bs-target="#todos" type="button" role="tab" aria-controls="todos" aria-selected="true">{{ $t('message.all') }}</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="online-tab" data-bs-toggle="tab" data-bs-target="#online" type="button" role="tab" aria-controls="online" aria-selected="false">Online</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="presencial-tab" data-bs-toggle="tab" data-bs-target="#presencial" type="button" role="tab" aria-controls="presencial" aria-selected="false">{{ $t('message.inPerson') }}</button>
                                        </li>
                                        <li class="nav-item col-2 d-flex justify-content-center align-items-center" role="presentation">
                                            <button class="nav-link" id="vivo-tab" data-bs-toggle="tab" data-bs-target="#vivo" type="button" role="tab" aria-controls="vivo" aria-selected="false">Vivo</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-12  d-flex justify-content-center align-items-center">
                                <div class="row justify-content-center row__width" id="myTabContent3">
                                    <div class="col-12 tab-pane fade show active" id="todos" role="tabpanel" aria-labelledby="todos-tab">
                                        <Beneficios/>
                                    </div>
                                    <div class="col-12 tab-pane fade" id="online" role="tabpanel" aria-labelledby="online-tab">
                                        <Beneficios/>
                                    </div>
                                    <div class="col-12 tab-pane fade" id="presencial" role="tabpanel" aria-labelledby="presencial-tab">
                                        <Beneficios/>
                                    </div>
                                    <div class="col-12 tab-pane fade" id="vivo" role="tabpanel" aria-labelledby="vivo-tab">
                                        <Beneficios/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center justify-content-lg-start align-items-center px-0 botones mt-4">
                                <button id="prevSliderBeneficios"><img src="../../img/home-icons/arrow-left-negra.svg" alt=""></button>
                                <div><h4>01/</h4><h5>10</h5></div>
                                <button id="nextSliderBeneficios"><img src="../../img/home-icons/arrow-rigth-negro.svg" alt=""></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ServiciosV2 :courses_uploaded="courses_uploaded" :images_uploaded="images_uploaded"/>
        </div>
    </div>
</template>

<script setup>
import FiltrosYBuscadorCursosV2 from "../Filtros/FiltrosYBuscadorCursosV2.vue";
import productosMarcasV3 from "../Sliders/ProductosMarcasV3.vue";
import AsesoriasServicios from "../Sliders/AsesoriasServicios.vue";
import SobreV2 from "./Marca/SobreV2.vue";
import TipoCurso from "../Tipos/TipoCurso.vue";
import PerfilGaleria from "../Sliders/PerfilGaleria.vue";
import Valoraciones from "../Cursos/Valoraciones.vue";
import TituloHomeCursos from "../Titulo/TituloHomeCursos.vue";
import Opinion from "./Opiniones.vue";
import ServiciosV2 from "./Marca/Secciones/ServiciosV2.vue";
import Beneficios from "../Panel/Productos/Beneficios.vue";
import Cursos from "../../components/Sliders/CursosOfrecidos.vue";
import CursosFotografia from "../Sliders/CursosFotografia.vue";

import { useGeneralStore } from "../../stores/general"
import { useUsersStore } from "../../stores/user";
import { storeToRefs } from "pinia";
import { ref, onMounted, watch, provide, onBeforeMount, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from "vue-i18n";
import axios from 'axios';
import TituloYOpciones from "../Titulo/TituloYOpciones.vue";
import SeccionesHomeV4 from "../Sliders/SeccionesHomeV4.vue";
import TituloProfesor from "../Titulo/TituloProfesor.vue";
import SliderBotones from "../Botones/SliderBotones.vue";



const users = useUsersStore();
const {cambioHeader} = storeToRefs(users);
const { url } = users

const router = useRouter();
const route = useRoute();

const general = useGeneralStore();
const {textos} = storeToRefs(general);
const {} = general;

const props = defineProps({
    userName: '',
    userAbout: '',
    courses_uploaded: '',
    images_uploaded: '',
    courses: {
        type: Object
    },
    avatar: '',
    gallery:{
        type: Object
    },
    cv: ''
})



const actual_tab = inject('actual_tab')
const professional = inject('professional')
const products_list = inject('products_list')
const actual_gallery = inject('actual_gallery')
const galeries = inject('galeries')

// Definir las variables reactivas
const progresos = ref([
    {progreso: 0, titulo: '0%'},
    {progreso: 0, titulo: '0%'},
    {progreso: 0, titulo: '0%'},
    {progreso: 0, titulo: '0%'},
    {progreso: 0, titulo: '0%'}
])

const rates = ref(null)
const noRate = ref([{}, {}, {}, {}, {}])
const totalScore = ref(0)


provide('progresos', progresos)
provide('totalScore', totalScore)
provide('noRate', noRate)
provide('rates', rates)


const fetchStatistics = async () => {
    const body = {
        reviewable_type: "PROFESSIONAL",
        reviewable_slug: professional.value.slug
    }

    try {
        const result = await axios.post(url + '/api/public/professionals/profile/reviews/statistics', body);
        const { stars, quantity, total_score } = result.data.data;
        //console.log(result)
        totalScore.value = total_score
        
        console.log(total_score.value, total_score)

        const totalRatings = stars.five + stars.four + stars.three + stars.two + stars.one;
        const averageRating = totalRatings > 0 ? (
        (5 * stars.five + 4 * stars.four + 3 * stars.three + 2 * stars.two + stars.one) / totalRatings
        ) : 0;

        rates.value = totalRatings > 0 ? Array.from({ length: Math.round(averageRating) }, () => ({})) : null;

        if (quantity > 0) {
            progresos.value = [
                { progreso: stars.five * 100 / quantity, titulo: `${stars.five}` },
                { progreso: stars.four * 100 / quantity, titulo: `${stars.four}` },
                { progreso: stars.three * 100 / quantity, titulo: `${stars.three}` },
                { progreso: stars.two * 100 / quantity, titulo: `${stars.two}` },
                { progreso: stars.one * 100 / quantity, titulo: `${stars.one}` }
            ];
        } else {
            progresos.value = [
                { progreso: 0, titulo: '0' },
                { progreso: 0, titulo: '0' },
                { progreso: 0, titulo: '0' },
                { progreso: 0, titulo: '0' },
                { progreso: 0, titulo: '0' }
            ];
        }
    } catch (error) {
            console.error('Error fetching the reviews statistics:', error);
    }
}
provide('fetchStatistics', fetchStatistics)
// Llamar a fetchStatistics cuando el componente se monte
onMounted(() => {
    fetchStatistics()
})
</script>

<style lang="scss" scoped>
:root {
    --color-dark: #1F2937;
    --color-light-green: #F0FDF4;
    --color-green: #22C55E;
    --color-blue: #1779FC;
    --color-white: #FFFFFF;
    --color-gray: #374151;
    --color-indigo: #4F46E5;
    --color-light-gray: #E5E7EB;
    
    --gallery-border-radius: 0.75rem;
    --gallery-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.08);
}

.div__slider {
    margin-bottom: 2rem;
    width: 100%;
    
    h2 {
        font-size: 1.25rem;
        font-weight: 600;
        color: var(--color-dark);
        margin-bottom: 1.25rem;
    }
}

// Gallery tabs
.tablas2 {
    background-color: var(--color-white);
    border-radius: 0.5rem;
    overflow: hidden;
    width: 100%;
    
    .scroll2 {
        width: 100%;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
        display: flex;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
    
    .nav-tabs {
        display: inline-flex;
        border-bottom: 1px solid #E5E7EB;
        padding: 0;
        width: max-content;
        margin: 0;
        flex-wrap: nowrap;
        
        @media (min-width: 1024px) {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            flex-wrap: nowrap;
        }
        
        .nav-item {
            display: inline-block;
            white-space: nowrap;
            flex: 0 0 auto;
            border-right: 1px solid #E5E7EB;
            
            @media (min-width: 1024px) {
                flex: 0 0 auto;
                margin-right: 15px;
            }
            
            &:last-child {
                border-right: none;
            }
            
            .nav-link {
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--color-gray);
                border: none;
                border-radius: 0;
                font-size: 0.875rem;
                font-weight: 500;
                transition: all 0.2s ease;
                white-space: nowrap;
                padding: 10px 20px;
                min-width: 120px;
                
                @media (min-width: 1024px) {
                    min-width: auto;
                    padding: 10px 15px;
                }
                
                &.active {
                    color: var(--color-indigo);
                    border-bottom: 3px solid var(--color-indigo);
                }
                
                &:hover:not(.active) {
                    background-color: var(--color-light-green);
                }
            }
        }
    }
}

// Gallery items
.gallery-item {
    border-radius: var(--gallery-border-radius);
    overflow: hidden;
    box-shadow: var(--gallery-box-shadow);
    margin-bottom: 1.25rem;
    position: relative;
    
    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        transition: transform 0.3s ease;
    }
    
    .gallery-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
        padding: 1rem;
        color: var(--color-white);
        
        h3 {
            font-size: 1rem;
            font-weight: 600;
            margin: 0;
        }
        
        .gallery-info {
            display: flex;
            align-items: center;
            font-size: 0.875rem;
            
            .elements {
                display: flex;
                align-items: center;
                
                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                }
            }
        }
    }
    
    &:hover {
        img {
            transform: scale(1.05);
        }
    }
}

// Course items
.course-item {
    border-radius: var(--gallery-border-radius);
    overflow: hidden;
    box-shadow: var(--gallery-box-shadow);
    margin-bottom: 1.25rem;
    background-color: var(--color-white);
    
    .course-image {
        position: relative;
        
        img {
            width: 100%;
            height: 180px;
            object-fit: cover;
        }
        
        .course-badge {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: var(--color-green);
            color: var(--color-white);
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: 600;
        }
        
        .price-badge {
            position: absolute;
            bottom: 10px;
            left: 10px;
            background-color: var(--color-blue);
            color: var(--color-white);
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 600;
        }
    }
    
    .course-info {
        padding: 1rem;
        
        h3 {
            font-size: 1rem;
            font-weight: 600;
            color: var(--color-dark);
            margin-bottom: 0.75rem;
            line-height: 1.4;
        }
        
        .course-meta {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .course-rating {
                display: flex;
                align-items: center;
                
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 2px;
                }
                
                span {
                    font-size: 0.875rem;
                    color: var(--color-gray);
                }
            }
            
            .course-duration {
                display: flex;
                align-items: center;
                font-size: 0.875rem;
                color: var(--color-gray);
                
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                }
            }
        }
    }
}

// Reviews section
.review-section {
    background-color: var(--color-white);
    border-radius: var(--gallery-border-radius);
    box-shadow: var(--gallery-box-shadow);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    
    .review-header {
        margin-bottom: 1.25rem;
        
        h3 {
            font-size: 1.25rem;
            font-weight: 600;
            color: var(--color-dark);
            margin: 0;
        }
    }
    
    .review-item {
        background-color: var(--color-light-green);
        border-radius: 0.5rem;
        padding: 1rem;
        margin-bottom: 1rem;
        
        .review-author {
            display: flex;
            align-items: center;
            margin-bottom: 0.75rem;
            
            img {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-right: 0.75rem;
                object-fit: cover;
            }
            
            .author-info {
                h4 {
                    font-size: 0.95rem;
                    font-weight: 600;
                    color: var(--color-dark);
                    margin: 0 0 0.25rem 0;
                }
                
                .review-date {
                    font-size: 0.8rem;
                    color: var(--color-gray);
                }
            }
            
            .review-rating {
                margin-left: auto;
                display: flex;
                
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 2px;
                    border-radius: 0;
                }
            }
        }
        
        .review-content {
            font-size: 0.9rem;
            line-height: 1.5;
            color: var(--color-gray);
        }
    }
}

// Responsive styles
@media (max-width: 768px) {
    .div__slider {
        h2 {
            font-size: 1.125rem;
        }
    }
    
    .gallery-item {
        img {
            height: 160px;
        }
    }
    
    .course-item {
        .course-image {
            img {
                height: 140px;
            }
        }
        
        .course-info {
            padding: 0.75rem;
            
            h3 {
                font-size: 0.95rem;
            }
        }
    }
}
</style>