<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center modulos2" v-if="information_filter.faqs">
        <div class="accordion d-flex justify-content-center align-items-center " id="accordionExample">
            <div class=" row__width" id="filtro7" >
                <div class="accordion-item row row__width justify-content-center" v-for="(faq, index) in information_filter.faqs">
                    <div class="accordion-header px-0" :id="`heading`+index">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse`+'fix'+index" aria-expanded="false" :aria-controls="`collapse`+'fix'+index" data-bs-parent="#accordionExample">
                            <p class="title">{{faq.question}}</p>
                        </button>
                        <div :id="`collapse`+'fix'+index" class="accordion-collapse collapse" :aria-labelledby="`heading`+index">
                            <div class="accordion-body">
                                <p class="info">
                                    {{faq.response}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import BTNMostrarMas from '../Botones/MostrarMas.vue';
    import { ref, inject } from 'vue';

    let information_filter = inject('information_filter')
    console.log(information_filter.value)

    const iconoImg = ref(false)

    const mostrarTexto7 = () => {
        document.querySelector('div.desaparecer').classList.toggle('d-none')
        document.getElementById('filtro7').classList.toggle('before')
        if(iconoImg.value == false){
            iconoImg.value =true
        }else{
            iconoImg.value =false
        }
    }
</script>

<style lang="scss" scoped>
.modulos2{
    .accordion{
        width: 100%;
    }
    .accordion-item{
        margin: 0 0 3% 0;
        border: 0;
        border-radius: 10px;
    }
    .accordion-item:last-of-type .accordion-button.collapsed{
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .accordion-header{
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        background-color: rgba(247, 249, 250, 0.57);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        
    }
    .accordion-button{
        padding-left: 15%;
        border-radius: 10px;
        &:focus{
            border-color: inherit;
            box-shadow: none;
        }
        @media (min-width: 992px) {
            padding-left: 50px;
        }
    }
    .accordion-button:not(.collapsed){
        background-color: inherit;
        box-shadow: none;
    }
    .accordion-button::after{
        position: absolute;
        left: 5%;
        background-image: url('../../img/home-icons/cursos-icons/arrow-azul-abajo.svg');
        background-size: 70%;
        background-position: center;
        @media (min-width: 992px) {
            left: 20px;
        }
    }
    .title{
        padding: 5px 0;
        font-weight: 600;
        font-size: 18px;
        color: var(--colorSecondary);
    }
    h4{
        font-weight: 400;
        font-size: 16px;
        color: #374557;
    }
    .accordion-body{
        padding: 0px 20px 30px 20px;
        h5{
            padding: 5px 0px;
            font-weight: 400;
            font-size: 16px;
            color: var(--colorSecondary);
        }
        .active{
            color: var(--colorPrimary);
        }
        .info{
            color: #374557;
            font-weight: 400;
            font-size: 16px;
            padding: 0;
            text-align: left;
        }
        .img{
            max-width: 40px;
            padding: 0;
        }
    }
    .info{
        font-weight: 400;
        font-size: 16px;
        color: #374557;
    }
    @media (min-width: 992px) {
        margin: 20px 0 60px 0;
    }
}
.before{
    @media (min-width: 992px) {
        &::before{
            position: relative;
           background: transparent;
        }
    }
}
</style>