<template>
    <div class="row row__width justify-content-center align-items-start profile-card">
        <div class="col-12 d-flex justify-content-center align-items-center profile-header px-0">
            <div class="row row__width">
                <div class="col-auto col-lg-12 profile-avatar d-flex justify-content-center align-items-center">
                    <img :src="userPic" alt="Profile Picture" class="avatar-img">
                </div>
                <div class="col col-lg-12 profile-info d-flex flex-column mt-3 justify-content-center align-items-center">
                    <div class="row row__width justify-content-center align-items-center">
                        <h1 class="profile-name">{{ userName }}</h1>
                    </div>
                    <div class="row row__width mt-3">
                        <!-- Profession -->
                        <div class="col-12 d-flex profile-details" >
                            <img class="img" src="../../img/perfil-icons/professions.svg" alt="prof_icon">
                            <p> 
                                <template v-if="profession && profession.translation && profession.translation.name">
                                    {{ profession.translation.name + ', ' }} 
                                </template> 
                                <template v-if="profession && profession.specializations">
                                    <template  v-for="(specialization, id) in profession.specializations.slice(0, 2)" :key="id">
                                        {{ specialization.translation.name + ', ' }}
                                    </template>
                                </template>
                            </p>
                        </div>
                        <!-- Location -->
                        <div class="col-12 d-flex profile-details">
                            <template v-if="country?.translation?.name">
                                {{ countryCodeToFlagEmoji(country.code) }}
                            </template>
                            <p class="ms-3 ps-1 ps-lg-0 ms-lg-3">
                                <template v-if="country?.translation?.name">
                                    {{ country.translation.name }}, 
                                </template>
                                <template v-if="state?.translation?.name">
                                    {{ state.translation.name }}, 
                                </template>
                                <template v-if="city?.translation?.name">
                                    {{ city.translation.name }}
                                </template>
                            </p>
                        </div>
                    </div>
                    <!-- Reviews -->
                    <div class="row row__width justify-content-center align-items-center profile-details ranking">
                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                            <div class="row row__width justify-content-start align-items-center">
                                <p class="d-flex px-0" v-if="average != 0">
                                    <img  src="../../img/perfil-icons/star.svg" alt="star-icon" class="me-2"> {{average}}
                                </p>
                                <template v-else>
                                    <div class="stars">
                                        <img src="../../img/perfil-icons/star.svg" alt="star-icon">
                                    </div>
                                    <div class="rating-text">
                                        <span>{{ $t('message.new') }}</span>
                                    </div>
                                </template>
                                <p class="d-flex align-items-center justify-content-start ">
                                    {{ `${total}` }} <p class="underline ms-2 ms-lg-1">Recomendaciones</p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-12 profile-info d-flex flex-column mt-3 justify-content-center align-items-center">
                    <!-- Ranking and speaker -->
                    <div class="row row__width justify-content-center align-items-center profile-details mb-2 mb-lg-3">
                        <div class="col-12 d-flex justify-content-center align-items-center px-0">
                            <div class="row row__width justify-content-center justify-content-lg-start align-items-center">
                                <span class="d-flex" v-if="ranking">
                                    <img src="../../img/perfil-icons/top-white.svg" alt="specialization"> TOP {{ ranking.position }}
                                </span>
                                <span class="d-flex" v-if="role">
                                    <img src="../../img/perfil-icons/speaker-white.svg" alt="specialization"> Speaker oficial
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- Contact -->
                    <div class="row row__width justify-content-center align-items-center mt-3">
                        <button type="button" class="col-12 btn__primario">
                            <img src="../../img/perfil-icons/mail.svg" alt="mail">
                            Contactar
                        </button>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    
    <!-- <div class="row profile-rating">
        <div class="col-12">
            <template v-if="average != 0">
                <div class="stars">
                    <img src="../../img/home-icons/cursos-icons/rate.svg" alt="star" v-for="rate in Math.floor(average)" :key="`rate-${rate}`">
                    <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="star-empty" v-for="rateGris in 5 - Math.floor(average)" :key="`rate-gris-${rateGris}`">
                </div>
                <div class="rating-text">
                    <span class="average">{{average}}</span>
                    <span class="reviews-count">{{ `(${total})` }}</span>
                </div>
            </template>
            <template v-else>
                <div class="stars">
                    <img src="../../img/home-icons/cursos-icons/rate.svg" alt="star">
                </div>
                <div class="rating-text">
                    <span>{{ $t('message.new') }}</span>
                </div>
            </template>
        </div>
    </div> -->
    
    <!-- <div class="row profile-social" v-if="social_network != null">
        <div class="col-12">
            <template v-if="contact.phone.code || contact.phone.number">
                <a :href="`https://wa.me/${contact.phone.code}${contact.phone.number}`" target="_blank" class="social-icon">
                    <img src="../../img/perfil-icons/wpp.svg" alt="WhatsApp">
                </a>    
            </template>
                
            <template v-for="(url, name) in social_network" :key="name">
                <a v-if="name.toLowerCase() == 'facebook'" :href="ensureHttps(url)" target="_blank" class="social-icon">
                    <img src="../../img/perfil-icons/facebook.svg" :alt="name">
                </a>
                <a v-if="name.toLowerCase() == 'instagram'" :href="ensureHttps(url)" target="_blank" class="social-icon">
                    <img src="../../img/perfil-icons/instagram.svg" :alt="name">
                </a>
                <a v-if="name.toLowerCase() == 'twitter'" :href="ensureHttps(url)" target="_blank" class="social-icon">
                    <img src="../../img/perfil-icons/twitter.svg" :alt="name">
                </a>
                <a v-if="name.toLowerCase() == 'linkedin'" :href="ensureHttps(url)" target="_blank" class="social-icon">
                    <img src="../../img/perfil-icons/linkedin.svg" :alt="name">
                </a>
                <a v-if="name.toLowerCase() == 'pinterest'" :href="ensureHttps(url)" target="_blank" class="social-icon">
                    <img src="../../img/perfil-icons/pinterest.svg" :alt="name">
                </a>
                <a v-if="name.toLowerCase() == 'web'" :href="ensureHttps(url)" target="_blank" class="social-icon">
                    <img src="../../img/perfil-icons/download.svg" :alt="name">
                </a>
            </template>
        </div>
    </div> -->

</template>

<script setup>
    import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";
    import { onMounted } from "vue";

    const props = defineProps({
        userName: '',
        userPic: '',
        userBanner: '',
        country: '',
        state: '',
        city: '',
        userWeb: '',
        ranking: '',
        verified: '',
        average: '',
        total: '',
        social_network: '',
        profession: '',
        role: '',
        contact: {}
    })

    function countryCodeToFlagEmoji(countryCode) {
        return countryCode
            .toUpperCase()
            .replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt()));
    }

    function ensureHttps(url) {
        if (!url.startsWith('https://') && !url.startsWith('http://')) {
            return 'https://' + url;
        }
        return url;
    }

    onMounted(() => {
        polyfillCountryFlagEmojis();
    });
</script>

<style lang="scss" scoped>
.profile-card {
    height: 100%;
    @media screen and (min-width: 1024px) {
        padding: 0 8.5px;
    }
    .profile-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.5rem;
        @media (min-width: 768px) {
            flex-direction: row;
            align-items: flex-start;
        }
        .profile-avatar {
            position: relative;
            margin-bottom: 1rem;
            @media (min-width: 768px) {
                margin-right: 1.5rem;
                margin-bottom: 0;
            }
            .avatar-img {
                width: 98px;
                height: 98px;
                border-radius: 50%;
                object-fit: cover;
                border: 3px solid var(#FFFFFF);
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
                @media (min-width: 1024px) {
                    width: 176px;
                    height: 176px;
                }
            }
        }
        .profile-info {
            .profile-name {
                font-size: 24px;  
                font-weight: 600;
                margin-bottom: 12px;
                color: #1F2937;
                text-align: left;
                padding: 0;
                @media screen and (min-width: 1024px) {
                    text-align: center;
                }
            }
            
            .profile-details {
                display: flex;
                margin-bottom: 20px;
                justify-content: flex-start;
                align-items: center;
                padding: 0;
                @media screen and (min-width: 1024px) {
                    margin-bottom: 15px;
                }
                .img{
                    height: 20px;
                    min-width: 20px;
                }
                p{
                    font-size: 14px;  
                    font-weight: 400;
                    margin-bottom: 12px;
                    color: #1F2937;
                    text-align: left;
                    margin-left: 10px;
                }
                span{
                    background-color: #22C55E;
                    color: #FFFFFF;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 3px 8px;
                    border-radius: 15px;
                    max-width: max-content;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    img{
                        height: 10px;
                        width: 10px;
                        margin-right: 4px;
                    }
                }
                
            }
            .ranking{
                margin-bottom: 15px;
                p{
                    max-width: max-content;
                    margin-left: 0;
                }
                .underline{
                    text-decoration: underline;
                }
            }
            .btn__primario{
                background-color: #1779FC;
                color: #FFFFFF;
                height: 40px;
                img{
                    margin-right: 10px;
                }
                &:hover, &:focus, &:active{
                    background-color: #fff;
                    color: #1779FC;
                }
                @media screen and (min-width: 1024px) {
                    height: 48px;
                }
            }
            .profile-profession {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #22C55E;
                color: #FFFFFF;
                padding: 6px 12px;
                border-radius: 6px;
                height: 20px;
                max-width: max-content;
                img{
                    height: 10px;
                    width: 10px;
                    margin-right: 4px;
                }
            }
        }
    }
}
</style>