<template>
    <div class="col-12 d-flex justify-content-center align-items-center date" v-if="product.units.length > 0 && product.class.id == 1">
        <div class="row justify-content-start row__width border">
            <div class="col-12 col-lg-6 d-flex justify-content-start align-items-center" v-if="product.totalTimeVideo">
                <img src="../../img/home-icons/cursos-icons/modulos.svg" alt="">
                <p>
                    <template v-if="product.units.length == 1">
                        {{ product.units.length }}
                        Módulo ({{ product.totalTimeVideo[0] < 1 ? '~1' : product.totalTimeVideo[0] }}h en total)
                    </template>
                    <template v-else>
                        {{ product.units.length }} Módulos ({{ product.totalTimeVideo[0] < 1 ? '~1' : product.totalTimeVideo[0] }}h en total)
                    </template>
                </p>
            </div>
            <div class="col-12 col-lg-6 d-flex justify-content-start align-items-center">
                <img src="../../img/home-icons/cursos-icons/clases.svg" alt="">
                <p>{{ totalLessons }} Clases</p>
            </div>
            <div v-if="product.totalResources > 0" class="col-12 col-lg-6 d-flex justify-content-start align-items-center">
                <img src="../../img/home-icons/cursos-icons/paper.svg" alt="">
                <p>{{ product.totalResources }} recursos adicionales</p>
            </div>
        </div>
    </div>
    <div class="col-12 d-flex justify-content-start align-items-center date h-100 mt-3" v-if=" product.class.id == 2">
        <p class="quantity">Cantidad de descargables del curso: <strong>{{ product.downloadables.quantity }}</strong></p>
    </div>
</template>

<script setup>
import { inject, ref } from 'vue';

const totalLessons = ref(0)

let product = inject('product')

// Consultar por cada lección 
const fetchLessonIds = async() => {
    if(product.value.units.length > 0){
        for (let i = 0; i < product.value.units.length; i++) {
                const unit = product.value.units[i];
            if(unit[0].lessons){
                for (let j = 0; j < unit[0].lessons.length; j++) {
                    const lesson = unit[0].lessons[j];
                    totalLessons.value++
                    console.log(totalLessons.value)
                }
            }
        }
        //console.log(content.value);
    }
}
fetchLessonIds()
</script>

<style lang="scss" scoped>
.date{
    .col-12{
        padding: 0px 0px 15px 0px;
        @media (min-width: 992px) {
            padding: 10px 0;
        }
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: var(--colorSecondary);
        text-align: left;
    }
    .revision{
        color: var(--colorPrimary);
    }
    img{
        padding-right: 10px;
    }
    .border{
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 10px;
        padding: 15px 20px;
        .col-12{
            &:last-child{
                @media (max-width: 991px) {
                    padding-bottom: 0;
                }
            }
        }
    }
    .quantity{
        font-weight: 400;
        font-size: 18px;
        color: var(--colorSecondary);
        text-align: left;
    }
}
</style>