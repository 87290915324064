<template>
    <div class="col-12 col-md d-flex justify-content-start align-items-center position-relative px-0 mt-3">
        <img :src="product.owner.image" alt="" class="perfil">
        <div class="d-flex flex-column justify-content-center align-items-start w-100">
            <p class="autor">{{ product.owner.name }}</p>
            <p v-if="product.owner_type.type == 'professional'">{{ $t('message.professionalUppercase') }}</p>
            <p v-else-if="product.owner_type.type == 'teacher'">{{ $t('message.teacherUppercase') }}</p>
        </div>
        
        <!-- <img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="verificado d-none">
        <span></span>
        <div class="row justify-content-center row__width ps-3 d-none" v-if="product.collabs.length > 0">
            <div class="col d-flex justify-content-start align-items-center position-relative imgs">
                <img :src="user.image" alt="" class="" style="border-radius: 50%;" v-for="(user, index) in product.collabs">
                <div class="div" v-if="product.collabs.length >=3">+{{ product.collabs.length }}</div>
                <p>{{ $t('message.collaborate') }}</p>
            </div>
        </div> -->
    </div>
    <div class="col-12 col-md-6 d-flex justify-content-start justify-content-md-between align-items-center py-3 px-0 descripcion" v-if="product.collabs && product.collabs.length > 0 || product.sponsors && product.sponsors.length > 0">
        <div class="d-flex justify-content-start align-items-center colaboradores" v-if="product.collabs && product.collabs.length > 0" @click="openModal(product.id)">
            <img :src="product.collabs[0].image" alt="" style="border-radius: 50px; width: 40px; height: 40px; object-fit: cover;">
            <div class="colaboran" v-if="product.collabs.length >=3"><p>+{{ product.collabs.length }}</p></div>
            <p class="colaboran_h3">{{$t('message.collaborate')}}</p>
        </div>
        <div class="d-flex justify-content-start align-items-center colaboradores me-0" v-if="product.sponsors && product.sponsors.length > 0" @click="openModal2(product.id)">
            <img :src="product.sponsors[0].image" alt="" style="border-radius: 50px; width: 40px; height: 40px; object-fit: cover;">
            <div class="colaboran" v-if="product.sponsors.length >=3"><p>+{{ product.sponsors.length }}</p></div>
            <p class="colaboran_h3">Patrocinan</p>
        </div>
    </div>
</template>

<script setup>
    import { inject } from 'vue';

    let product = inject('product')
    
    const openModal = (id) => {
        console.log(id)
        let element = document.getElementById('collabModal'+id)
        console.log(element)
        console.log(element.classList)
        element.classList.remove("d-none")
    }

    const openModal2 = (id) => {
        console.log(id)
        let element = document.getElementById('sponsorModal'+id)
        console.log(element)
        console.log(element.classList)
        element.classList.remove("d-none")
    }
</script>

<style lang="scss" scoped>
.verificado{
    padding-left: 5px;
}
.perfil{
    min-height: 50px;
    min-width: 50px;
    max-height: 50px;
    max-width: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.autor{
    font-weight: 500;
    font-size: 18px;
    color: var(--colorSecondary);
    padding-left: 10px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    text-wrap: nowrap;
    @media (min-width: 992px) {
        min-width: 130px;
    }
}
span{
    background-color: #4144612a;
    height: 100%;
    width: 1px;
    position: absolute;
    right: 0;
    @media (min-width: 992px) {
        display: none;
    }
}
p{
    font-weight: 700;
    font-size: 16px;
    color: var(--colorSecondary);
    padding: 0px 5px;
}
.imgs{
    img{
        height: 32px;
    }
    .img2{
        position: absolute;
        left: 7%;
    }
    .div{
        border: 2px solid var(--bgPrincipal);
        background-color: var(--colorSecondary);
        border: 2px solid var(--bgPrincipal);
        border-radius: 50px;
        width: 32px;
        height: 32px;
        color: var(--bgPrincipal);
        font-weight: 500;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 12%;
    }
    p{
        font-size: 16px;
        font-weight: 400;
        color: #9E989A;
        padding-left: 12%;
    }
}

.descripcion{
        h1{
            font-weight: 700;
            font-size: 22px;
            color: var(--colorSecondary);
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 1.2;
            text-align: left;
        }
        .colaboran_h3{
            color: #B8C1DB;
            font-weight: 400;
            font-size: 15px;
            padding-left: 10px;
            text-align: left;
        }
        h4{
            color: var(--colorSecondary);
            font-weight: 500;
            font-size: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: left;
            img{
                margin-left: 5px;
            }
        }
        p{
            color: var(--bgPrincipal);
            font-weight: 500;
            font-size: 15px;
        }
        .colaboradores{
            position: relative;
            min-width: 120px;
            margin-right: 20px;
            .colaboran{
                background-color: #697891;
                box-shadow: 0px 3px 6px #00000029;
                border: 2px solid #FFFFFF;
                border-radius: 50%;
                min-width: 42px;
                min-height: 42px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: -15px;
            }
            .azul{
                background-color: var(--colorSecondary);
            }
        }
        .creador{
            border-right: 1px solid rgba(184, 193, 219, 0.3);
        }
        .border-bottom{
            width: 90%;
            height: 1px;
            background-color: rgba(184, 193, 219, 0.3);
        }
    }

</style>