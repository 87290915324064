<template>
    <div class="container-fluid">
        <Navbar/>
        <div class="row justify-content-center contenido">
            <div class="col-10 col-lg-5 d-flex flex-column justiify-content-center align-items-center">
                <TitulosSplash :tituloSplash="$t('message.titleSplash')" :subtituloSplash="$t('message.subtitleSplash')"/>
                <BTNPrimario :textoBTNPrimario="$t('message.startSearch')" class="a"/>
            </div>
            <div class="col-12 d-flex justiify-content-center align-items-center px-0">
                <img src="../../img/splash-icons/img-deco.svg" alt="">
            </div>
            <!---<h1>{{ $t('message.hello') }}</h1>--->
        </div>
        
    </div>
</template>

<script setup>
import Navbar from "../../components/Navs/Navbar.vue";
import BTNPrimario from "../../components/Botones/Primario.vue";
import TitulosSplash from "../../components/Titulo/TitulosSplash.vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted } from "vue";
import axios from "axios";

const route = useRoute()
const router = useRouter()

let insertedNodes = []

const useHeadTags = async (settings = null) => {
    const url = process.env.VUE_APP_BASE_URL

    try {
        let response = await axios.post(`${url}/api/public/seo/pages`, { slug: settings.slug });

        const tagsString = await response.data.data

        const parser = new DOMParser()
        const doc = parser.parseFromString(
        `<html><head>${tagsString}</head><body></body></html>`,
        'text/html'
        )

        const headNodes = Array.from(doc.head.childNodes)

        headNodes.forEach(node => {
        const nodeName = node.nodeName.toLowerCase()

        switch (nodeName) {
            case 'title':
            document.title = node.textContent ?? ''
            break

            case 'meta': {
            const metaName = node.getAttribute('name')
            if (metaName) {
                const existingMeta = document.head.querySelector(`meta[name="${metaName}"]`)
                if (existingMeta) {
                document.head.removeChild(existingMeta)
                }
            }
            const meta = document.createElement('meta')
            Array.from(node.attributes).forEach(attr => {
                meta.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(meta)
            insertedNodes.push(meta)
            break
            }

            case 'script': {
            const script = document.createElement('script')
            Array.from(node.attributes).forEach(attr => {
                script.setAttribute(attr.name, attr.value)
            })
            script.textContent = node.textContent
            document.head.appendChild(script)
            insertedNodes.push(script)
            break
            }

            case 'link': {
            const link = document.createElement('link')
            Array.from(node.attributes).forEach(attr => {
                link.setAttribute(attr.name, attr.value)
            })
            document.head.appendChild(link)
            insertedNodes.push(link)
            break
            }

            default:
            console.warn(`Etiqueta <${nodeName}> no manejada.`)
        }
        })
        console.warn('TAGS CARGADOS')
    } catch (error) {
        console.error('Error procesando las etiquetas:', error)
    }
}

router.beforeEach(() => {
    insertedNodes.forEach(node => {
    if (document.head.contains(node)) {
        document.head.removeChild(node)
    }
    })
    insertedNodes = []
})

onMounted(() => {
    useHeadTags({ slug: route.fullPath.slice(1) })
})

</script>

<style lang="scss" scoped>
.contenido{
    margin-top:2%;
    .a{
        margin: 30px 0px;
    }
    img{
        position: relative;
        width: 100%;
        bottom: 0;
        height: 50vh;
    }
    @media (min-width: 992px) {
        margin-top: 9%;
        img{
            max-height: 50vh;
        }
    }
}
</style>