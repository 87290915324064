<template>
    <div class="col-12 d-flex flex-column justify-content-center align-items-center profesor" v-if="professional">
        <!-- <div class="row justify-content-center row__width pt-3 imagenes d-none d-lg-flex">
            <div class="col d-flex justify-content-center align-items-center ps-0 position-relative">
                <img src="../../img/home-icons/cursos-icons/1.png" alt="">
            </div>
            <div class="col d-flex justify-content-center align-items-center px-0 position-relative">
                <img src="../../img/home-icons/cursos-icons/1.png" alt="">
            </div>
            <div class="col-3 col-lg-2 d-flex justify-content-center align-items-center arreglo">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-center align-items-center padding">
                        <img src="../../img/home-icons/cursos-icons/2.png" alt="">
                    </div> 
                    <div class="col-12 d-flex justify-content-center align-items-center padding">
                        <img src="../../img/home-icons/cursos-icons/3.png" alt="">
                    </div> 
                </div>
            </div>
        </div> -->
        <!-- <div class="row justify-content-center row__width pt-3 imagenes d-none d-lg-flex">
            <div class="col d-flex justify-content-center align-items-center ps-0 position-relative">
                <img :src="professional.data.banner" alt="" v-if="professional">
            </div>
        </div> -->
        <div class="row justify-content-center row__width" v-if="professional">
            <div class="col-3 col-lg d-flex justify-content-center align-items-center px-2 max-width">
                <img :src="professional.user.avatar" alt="" class="perfil">
            </div>
            <div class="col d-flex justify-content-center align-items-center">
                <div class="row justify-content-center">
                    <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <p class="name">{{professional.data.full_name}}</p><img src="../../img/home-icons/cursos-icons/verficacion-perfil.svg" alt="" class="d-none ps-4" >
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <img src="../../img/home-icons/cursos-icons/ubi-negro.svg" alt="" class="pe-2"><p class="city">{{professional.data.city}}</p>
                    </div>
                    <div class="col-12 d-flex justify-content-start align-items-center py-1">
                        <template v-if="product.reviews.average">
                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="image-fix" v-for="rate in Math.floor(product.reviews.average)" :key="`rate-${rate}`">
                            <img src="../../img/home-icons/cursos-icons/rate-gris.svg" alt="" class="image-fix" v-for="rateGris in 5 - Math.floor(product.reviews.average)" :key="`rate-gris-${rateGris}`">
                        </template>
                        <template v-else>
                            <img src="../../img/home-icons/cursos-icons/rate.svg" alt="" class="image-fix">
                        </template>
                        <p class="average">{{product.reviews.average}}</p>
                        <p class="count">{{ product.reviews.count ? `(${product.reviews.count})` : $t('message.new') }}</p>
                    </div>
                    
                </div>
            </div>
            <div class="col-6 d-flex justify-content-center align-items-center px-2">
                <div class="row justify-content-center row__width">
                    <div class="col-12 d-flex justify-content-end align-items-center py-1" v-if="product.professions.length > 0">
                        <p class="professions">{{product.professions[0].name}}</p>
                    </div>
                    
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center">
                <div class="row row__width">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                        <a :href="'/profesionales/'+professional.slug" class="ver_perfil py-2">
                            {{ $t('message.viewProfile') }}
                        </a>
                    </div>
                    <div class="col-12 d-flex justify-content-center align-items-center">
                        <div 
                            class="ql-editor w-100 description-box"
                            :class="{ 'collapsed': !isExpanded }"
                            v-html="userAboutCorrected"
                            v-if="description_is_html"
                            ref="descriptionRef">
                        </div>
                        <p 
                            class="description-box"
                            :class="{ 'collapsed': !isExpanded }"
                            v-else
                            ref="descriptionRef">
                            {{ userAboutCorrected }}
                        </p>
                    </div>
                    <div v-if="isLongDescription" class="col-12 col-lg-6 d-flex justify-content-center">
                        <MostrarMas :funcionTexto="toggleExpand" :img="isExpanded"/>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, onMounted, ref, watch, nextTick } from 'vue';
import MostrarMas from "../Botones/MostrarMas.vue";

let product = inject('product')
let professional = inject('professional')

const isExpanded = ref(false);
const description_is_html = ref(false);
const userAboutCorrected = ref('');
const isLongDescription = ref(false);
const descriptionRef = ref(null);

const isHtml = (text) => {
  return /<\/?[a-z][\s\S]*>/i.test(text);
};

const fixLinks = (description) => {
  if (!description) return '';

  const div = document.createElement('div');
  div.innerHTML = description;

  div.querySelectorAll('a').forEach(link => {
    const href = link.getAttribute('href');
    if (href && !/^(https?:\/\/|mailto:|tel:|#)/.test(href)) {
      link.setAttribute('href', 'https://' + href);
    }
  });

  return div.innerHTML;
};

const checkLongDescription = () => {
  if (descriptionRef.value) {
    isLongDescription.value = descriptionRef.value.scrollHeight > descriptionRef.value.clientHeight;
  }
};

const seeDescription = () => {
  if (professional.value) {
    const aboutText = professional.value.user?.personal_data?.about || "";
    description_is_html.value = isHtml(aboutText);
    userAboutCorrected.value = description_is_html.value ? fixLinks(aboutText) : aboutText;

    // Esperar a que el DOM se actualice antes de calcular la altura
    nextTick(checkLongDescription);
  }
};

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

watch(professional, (newValue) => {
  if (newValue) {
    seeDescription();
  }
});

onMounted(() => {
  seeDescription();
});
</script>

<style lang="scss" scoped>
 .profesor{ 
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 15px;
    padding: 20px 5px 20px 15px;
    margin: 20px 0px;
    .name{
        font-weight: 700;
        font-size: 18px;
        color: var(--colorSecondary);
    }
    .city{
        font-weight: 400;
        font-size: 14px;
        color: var(--colorSecondary);
        padding: 0;
    }
    .professions{
        font-weight: 400;
        font-size: 14px;
        color: #B8C1DB;
        padding: 0;
    }
    p.average{
        font-family: 'Proxima Nova';
        font-weight: 500;
        font-size: 17px;
        color: #FEB230;
        padding: 0px 5px;
    }
    .count{
        font-family: 'Proxima Nova';
        font-weight: 500;
        font-size: 17px;
        color: #697891;
    }
    .perfil{
        object-fit: cover;
        height: 102px;
        width: 102px;
        border-radius: 50%;
        @media (min-width: 992px) {
            height: 72px;
            width: 72px;
        }
    }
    .max-width{
        max-width: 80px;
    }
    .texto_mas{
        p{  
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 16px;
            color: #697891;
            text-align: left;
            padding: 20px 0 10px 0;
        }
    }
    .grandes{
        max-width: 100%;
        width: 100%;
        height: 100%;
        height: 170px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;
        object-fit: cover;
    }
    .chiquitas{
        max-width: 100%;
        width: 100%;
        height: 80px;
        max-width: 92px;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 15px;
        object-fit: cover;
    }
}
.imagenes{
    margin-bottom: 10px;
    .padding{
        padding: 0px 10px 15px 10px;
        &:last-child{
            padding-bottom: 0;
        }
    }
    img{
        max-width: 100%;
        height: 100%;
        border-radius: 10px;
        width: 100%;
        box-shadow: 0px 3px 6px #00000029;
        max-height: 170px;
        object-fit: cover;
        @media (min-width: 1800px) {
            max-height: 250px;
        }
    }
    .arreglo{
        max-width: 33%;
        img{
            max-height: 78px;
            @media (min-width: 1800px) {
                max-height: 115px;
            }
        }
    }
    span{
        background-color: #fff;
        box-shadow: 0px 3px 6px #00000029;
        height: 47px;
        width: 47px;
        border-radius: 50%;
        color: var(--colorPrimary);
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 20px;
        top: 6%;
    }
}

.ver_perfil{
    background-color: var(--bgPrincipal);
    border: 2px solid var(--colorPrimary);
    border-radius: 26px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    color: var(--colorPrimary);
    margin: 10px 0px;
}

.image-fix{
    height: 10px;
    margin-bottom: 3px;
}

.description-box {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Limita a 4 líneas */
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.description-box:not(.collapsed) {
  -webkit-line-clamp: unset;
  overflow: visible;
}

</style>