<template>
     <div class="tabs-wrapper">
        <div class="row">
            <div class="col-12 p-0">
                <div class="scroll">
                    <ul class="nav nav-tabs">
                        <!-- Gallery -->
                        <li class="nav-item" v-if="professional.gallery?.specializations.length > 0">
                            <button class="nav-link active"  type="button" @click="scroll(2), actual_gallery = null">
                                <i class="tab-icon galerias"></i>
                                {{ $t('message.galleries') }}
                            </button>
                        </li>
                        <!-- Profile -->
                        <li class="nav-item" v-if="professional.about">
                            <button class="nav-link"  type="button" @click="scroll(1)">
                                <i class="tab-icon perfil"></i>
                                {{ $t('message.profile') }}
                            </button>
                        </li>
                        <!-- Courses -->
                        <li class="nav-item" v-if="professional.products && professional.products.courses">
                            <button class="nav-link"  type="button" @click="scroll(4)">
                                <i class="tab-icon courses"></i>
                                {{ $t('message.courses') }}
                            </button>
                        </li>
                        <!-- Reviews -->
                        <li class="nav-item">
                            <button class="nav-link"  type="button" @click="scroll(3)">
                                <i class="tab-icon reviews"></i>
                                {{ $t('message.reviews') }}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { inject, ref, onMounted, watch } from 'vue';

const actual_tab = inject('actual_tab')
const actual_gallery = inject('actual_gallery')
const professional = inject('professional')
const activatedTab = ref(1)

// Track active tab based on scroll position
onMounted(() => {
    window.addEventListener('scroll', checkScrollPosition)
})

// Watch for changes in the actual_tab value
watch(actual_tab, (newVal) => {
    activatedTab.value = newVal
})

const checkScrollPosition = () => {
    // Get positions of each section
    const sections = [
        document.getElementById('1'),
        document.getElementById('2'),
        document.getElementById('3'),
        document.getElementById('4')
    ].filter(el => el !== null)
    
    // Get current scroll position
    const scrollPosition = window.scrollY + 100
    
    // Find the active section
    for (let i = 0; i < sections.length; i++) {
        const section = sections[i]
        const sectionTop = section.offsetTop
        const sectionBottom = sectionTop + section.offsetHeight
        
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            activatedTab.value = parseInt(section.id)
            break
        }
    }
}

const scroll = (id) => {
    let element = document.getElementById(id);
    if (!element) {
        return;
    }
    
    // Update active tab
    activatedTab.value = id
    actual_tab.value = id

    // Get the absolute position of the element relative to the start of the page
    let elementTop = element.getBoundingClientRect().top + window.scrollY;

    // Adjust the offset based on window width
    let offset = window.innerWidth >= 992 ? 80 : 80;

    // Perform the scroll
    window.scroll({
        top: elementTop - offset,
        left: 0,
        behavior: 'smooth'
    });
};
    
</script>

<style lang="scss" scoped>
:root {
    --color-dark: #1F2937;
    --color-light-green: #F0FDF4;
    --color-green: #22C55E;
    --color-blue: #1779FC;
    --color-white: #FFFFFF;
    --color-gray: #374151;
    --color-indigo: #4F46E5;
    --color-light-gray: #E5E7EB;
}

.tabs-wrapper {  
    width: 100%;
    background-color: var(--color-white);
    margin-bottom: 1.5rem;
    overflow: hidden;
    
    .scroll {
        width: 100%;
        overflow-x: auto;
        scrollbar-width: none; 
        -ms-overflow-style: none; 
        -webkit-overflow-scrolling: touch;
        display: flex;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
    
    .nav-tabs {
        display: inline-flex;
        border-bottom: 1px solid #E5E7EB;
        padding: 0;
        width: max-content;
        margin: 0;
        flex-wrap: nowrap;
        
        @media (min-width: 1024px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
        }
        
        .nav-item {
            display: inline-block;
            white-space: nowrap;
            flex: 0 0 auto;
            border-right: 1px solid #E5E7EB;
            
            @media (min-width: 1024px) {
                flex: 1;
            }
            
            &:last-child {
                border-right: none;
            }
            
            .nav-link {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #374151;
                border: none;
                border-radius: 0;
                transition: all 0.2s ease;
                white-space: nowrap;
                padding: 10px 20px;
                min-width: max-content;
                height: 36px;
                font-size: 14px;
                font-weight: 500;
                
                @media (min-width: 1024px) {
                    min-width: 100%;
                    padding: 10px;
                }
                
                .tab-icon {
                    height: 20px;
                    width: 20px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    margin-right: 8px;
                    flex-shrink: 0;
                }
                
                &.active, &:hover, &:focus {
                    background-color: #EEF2FF;
                    color: #4F46E5;
                    font-weight: 500;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.4);
                    border: 1px solid #E5E7EB;
                }
            }
        }
    }
    
    // Tab icons
    .galerias {
        background-image: url('../../img/perfil-icons/projects-active.svg');
    }
    
    .perfil {
        background-image: url('../../img/perfil-icons/profile.svg');
    } 

    .reviews {
        background-image: url('../../img/perfil-icons/star-reviews.svg');
    }
    
    .courses {
        background-image: url('../../img/perfil-icons/courses-tab.svg');
    }
}
</style>